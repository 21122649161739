import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import util from "../../../../utils/util";
import 'moment/locale/nl-be';
import {Clock} from "react-feather";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign} from "@fortawesome/free-solid-svg-icons";
import DurationBadge from "../../../components/DurationBadge";
import {Service} from "../../../../services/Service";
import {Prestaties} from "../../../../services/TimeApi";
import {PrestatieStatsYearly} from "../../../../../libs/api/time/api-model";
import ErrorHandlingTS from "../../../components/ErrorHandlingTS";

type YearlyStatsProps = {
  year: number,
  employeeNr: number
}

const YearlyStats: React.FC<YearlyStatsProps> = ({ year, employeeNr }) => {

  const [stats, setStats] = useState<Service<PrestatieStatsYearly>>({ status: 'init' });
  const loadEntries = () => Prestaties.yearlyStats(employeeNr, year)(setStats);
  useEffect(loadEntries, [ employeeNr, year ]);

  return (
    <Row>
      <Col md="6" xl>
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start pb-4 border-bottom">
              <div className="flex-grow-1">
                <h4 className="mb-2">Totaal Prestaties</h4>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <Clock className="align-middle text-success" />
                </div>
              </div>
            </div>
            <div className="mt-4 border-bottom">
              <ErrorHandlingTS service={stats} onLoaded={(data) =>
                <>
                  <div className="float-end">{util.isDefined(data.tp) && util.duration(data.tp)}</div>
                  <h4 className="mb-2">TP</h4>
                  <div className="float-end">{util.isDefined(data.gp) && util.duration(data.gp)}</div>
                  <h4 className="mb-2">GP</h4>
                  <div className="float-end">{util.isDefined(data.ab) && util.duration(data.ab)}</div>
                  <h4 className="mb-2">AB</h4>
                </>
              } />
            </div>
            <div className="mt-3">
              <ErrorHandlingTS service={stats} onLoaded={(data) =>
                <>
                  <div className="float-end">
                    <DurationBadge className='pt-1 align-top' value={data.diff} />
                  </div>
                  <h4 className="mb-0">Diff</h4>
                </>
              } />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start pb-4 border-bottom">
              <div className="flex-grow-1">
                <h4 className="mb-2">Totaal Premies</h4>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <FontAwesomeIcon fixedWidth icon={faEuroSign} className="align-middle text-success" />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <ErrorHandlingTS service={stats} onLoaded={(data) =>
                <>
                  <div className="float-end">{util.isDefined(data.premie) && data.premie}</div>
                  <h4 className="mb-2">Premie</h4>
                  <div className="float-end">{util.isDefined(data.ovu) && data.ovu}</div>
                  <h4 className="mb-2">OVU</h4>
                  <div className="float-end">{util.isDefined(data.arab) && data.arab}</div>
                  <h4 className="mb-0">ARAB</h4>
                </>
              } />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default YearlyStats;
