import React from "react";
import * as Yup from "yup";
import {
  Formik,
  FormikHelpers,
  Form as FormikForm,
} from "formik";
import {
    Button,
    Col,
    Form,
    Modal,
    Row,
} from "react-bootstrap";
import {AbsenceType} from "../../../libs/api/time/api-model";
import FormSubmitButton from "../components/form/FormSubmitButton";
import HorizontalColumnField from "../components/form/HorizontalColumnField";
import BooleanFormikField from "../components/form/BooleanFormikField";

export interface AbsenceTypeControlProps {
  title: string,
  absenceType: AbsenceType,
  onSave: (values: AbsenceType, formikHelpers: FormikHelpers<AbsenceType>) => void | Promise<any>;
  modalOpen: boolean,
  onModalHide: () => void
}

const schema = Yup.object().shape({
  code: Yup.string().required(),
  name: Yup.string().required(),
});

const normalizeValues = (values: AbsenceType) => values;
const deNormalizeValues = (values: AbsenceType) => values;

const AbsenceTypeControl: React.FC<AbsenceTypeControlProps> = ({
    title, absenceType, onSave, modalOpen, onModalHide
}) => (
  <Modal
    scrollable={true}
    size="sm"
    show={modalOpen}
    onHide={onModalHide}>
      <Formik
        validationSchema={schema}
        initialValues={normalizeValues(absenceType)}
        onSubmit={(values, formikHelpers) => onSave(deNormalizeValues(values), formikHelpers)}
      >
        {({
            handleSubmit,
          }) => (
          <FormikForm noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>{title}</Modal.Header>
            <Modal.Body>
              <HorizontalColumnField label="Code" name='code' required />
              <HorizontalColumnField label="Naam" name='name' required />
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}></Col>
                <Col sm={8}>
                  <BooleanFormikField label='Betaald' name='paid' />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant={"secondary"} onClick={onModalHide}>Annuleren</Button>
              {' '}
              <FormSubmitButton />
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
  </Modal>
)

export default AbsenceTypeControl;
