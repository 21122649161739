import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {DateRange, equalDates, parseRange, toShortFormat, toUriQuery} from "../../model/Common";
import {Service} from "../../services/Service";
import {MobilityPremiumEmployeeEntry, MobilityPremiumReport} from "../../../libs/api/time/api-model";
import {Reports} from "../../services/TimeApi";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Row, Table} from "react-bootstrap";
import DateRangePicker from "../components/DateRangePicker";
import ErrorHandlingTS, {MapServiceElement} from "../../pages/components/ErrorHandlingTS";
import PdfDownload from "../components/PdfDownload";
import util from "../../utils/util";
import EmptyResult from "../components/EmptyResult";
import MobilityPremiumsDocument from "./MobilityPremiumsDocument";
import {columns} from "./columns";
import {EmployeeFunctions} from "../../libs/api/time/api-helpers";

const EmployeeTablePart: React.FC<{
  entry: MobilityPremiumEmployeeEntry
}> = ({ entry: {employee, totalKm, totalPremium, entries} }) =>
  <>
    <tr className='table-active table-footer-divider  border-top border-bottom-0 border-start-0 border-end-0 border-2'>
      <td colSpan={4} className='fw-bolder'>{ EmployeeFunctions.fullname(employee) }</td>
    </tr>
    {entries.map(({
                    date,
                    km,
                    premium,
                    driver,
                  }, i) =>
      <tr key={`${employee.id}-${i}`}>
        <td className="text-end">{date}</td>
        <td className="text-end">{util.formatInt(km)}</td>
        <td className="text-end">{util.formatCurrency(premium)}</td>
        <td className="text-center">{driver ? 'Chauffeur' : 'Passagier'}</td>
      </tr>
    )}
    <tr className='table-footer-total-row border-top border-bottom-0 border-start-0 border-end-0 border-2'>
      <td className='fw-bolder'>Totaal</td>
      <td className="text-end fw-bolder">{util.formatInt(totalKm)}</td>
      <td className="text-end fw-bolder">{util.formatCurrency(totalPremium)}</td>
      <td>&nbsp;</td>
    </tr>
  </>

const MobilityPremiumsReport: React.FC = () => {

  const navigate = useNavigate();
  const {from, to} = useParams();
  const [period, setPeriod] = useState<DateRange>(parseRange(from, to));
  const [mobilityPremiums, setMobilityPremiums] = useState<Service<MobilityPremiumReport>>({
    status: 'loading'
  });

  const loadReport = () => Reports.mobilityPremiums(period)(setMobilityPremiums);

  useEffect(() => navigate(`/reports/mobility-premiums/${toUriQuery(period)}`), [ navigate, period ]);
  useEffect(loadReport, [ period ]);

  const onDateSet: (_: DateRange) => void = (range: DateRange) => {
    if (!equalDates(period, range)) {
      setPeriod(range)
    }
  }

  return (
    <>
      <Helmet title="Mobiliteit Premies"/>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Mobiliteit Premies</h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <DateRangePicker onSuccess={onDateSet} initialRange={period} />
              </Col>
              <Col md={8} className='text-end'>
                <MapServiceElement service={mobilityPremiums} onLoaded={(data: MobilityPremiumReport) =>
                  <PdfDownload dataLoading={mobilityPremiums.status === 'loading'}
                               document={<MobilityPremiumsDocument data={data} columns={columns} period={period} />}
                               filename={`MobiliteitPremies-${toShortFormat(period)}.pdf`}
                               label={`MobiliteitPremies.pdf`}
                />
                }
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ErrorHandlingTS service={mobilityPremiums} onLoaded={(data: MobilityPremiumReport) =>
          <>
            {util.isDefined(data) && data.entries && data.entries.length === 0 && <EmptyResult />}
            <Table hover bordered size="sm" className="my-0">
              <thead>
              <tr>
                <th className="text-end">Datum</th>
                <th className="text-end">Kilometers</th>
                <th className="text-end">Bedrag</th>
                <th className="text-center">Chauffeur</th>
              </tr>
              </thead>
              <tbody>
              <>
                {util.isDefined(data) && util.isDefined(data.entries) && Array.isArray(data.entries) &&
                  data.entries.map((entry) => <EmployeeTablePart key={entry.employee.id} entry={entry} />)
                }
              </>
              </tbody>
            </Table>
          </>
        }
        />
      </Container>
    </>
  );
}
export default MobilityPremiumsReport;
