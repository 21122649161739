import React from "react";
import {
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import {Frown} from "react-feather";

const ConfirmationDialog = ({title, open, loading, error, onCancel, onConfirm, children}) => (
  <Modal centered
    size="sm"
    show={open}
    onHide={onCancel}>
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body><>{children}</></Modal.Body>
      <Modal.Footer>
        <Button variant={"secondary"} onClick={onCancel}>Annuleren</Button>
        {' '}
        <Button variant="primary" disabled={loading} onClick={onConfirm}>Bevestigen</Button>
        {loading && !error && <Spinner animation="grow" color="dark" size="sm" className="mx-2"/> }
        {error && <span className={"text-danger mx-2"}><Frown className="me-1" /> {error}</span>}
      </Modal.Footer>
  </Modal>
);

export default ConfirmationDialog;