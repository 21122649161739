import {Form} from "react-bootstrap";
import React from "react";

function SearchColumnFilter({column: { filterValue, preFilteredRows, setFilter }}) {
  return (
    <Form.Control
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder='Zoeken...'
      //placeholder={`Zoeken in ${count} lijnen...`}
      className="mt-2 mh-38"
    />
  );
}

export default SearchColumnFilter;