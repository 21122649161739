import React, {useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";
import classNames from "classnames";
import {Service, toOption} from "../../../services/Service";
import {CalendarName} from "../../../../libs/api/time/api-model";
import {Calendars} from "../../../services/TimeApi";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import {numberOrUndefined} from "../../../utils/util";
import {Form} from "react-bootstrap";

export interface SelectCalendarProps {
  value: number | undefined
  required?: boolean
  useOptionValue?: boolean // geef de value van de option door bij selectie, of de hele option
  onChange: (c: number | CalendarName | undefined) => void
}

type CalendarOption = {
  label: string,
  value: string
}

const getId = (calendar: CalendarName) => calendar.id.toString();

const toCalendarOption = (calendar: CalendarName) => (
  { value: getId(calendar), label: calendar.name } as CalendarOption
);
const emptyOption = () => ({value: "", label: "Maak een keuze"} as CalendarOption);

export const SelectCalendar: React.FC<SelectCalendarProps> = (
  { value, required, useOptionValue = false, onChange, ...props }
) => {

  const [calendars, setCalendars] = useState<Service<CalendarName[]>>({ status: 'init' });
  const loadEntries = () => Calendars.list(setCalendars);
  useEffect(loadEntries, []);

  const calendarOptions: CalendarOption[] =
    pipe(calendars,
      s => toOption(s),
      option.map(array.map(toCalendarOption)),
      option.getOrElse(() => [] as CalendarOption[]),
    );

  const isInvalid = calendars.status === "error";

  const findOption = pipe(calendarOptions,
    array.findFirst(o => o.value === value?.toString()),
    option.getOrElse(() => emptyOption())
  );

  const findCalendarName = (selected: SingleValue<CalendarOption>) =>
    pipe(calendars,
      s => toOption(s),
      option.chain(list =>
        array.findFirst((calendarName: CalendarName) => {
          const nonEmptyValue = pipe(option.fromNullable(selected), option.map(o => o.value), option.getOrElse(() => ''));
          return getId(calendarName) === nonEmptyValue;
        })(list)
      )
    );

  const onValueSelected = (selected: SingleValue<CalendarOption>) =>
    pipe(findCalendarName(selected),
      option.fold(
        () => onChange(undefined),
        (v) => onChange(useOptionValue ? numberOrUndefined(v.id) : v)
      )
    );

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={calendarOptions}
        isLoading={calendars.status === "loading"}
        id={`input_calendar_name_select`}
        onChange={onValueSelected}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      {
        isInvalid && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van de kalenders: ${calendars.error ? calendars.error.message : '?'}`}</Form.Control.Feedback>
      }
    </>
  );
};

export default SelectCalendar;
