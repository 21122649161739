import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import TableWithFiltering from "../components/TableWithFiltering";
import {employeeColumns} from "./columns";
import {PlusCircle} from "react-feather";
import {useEffect, useState} from "react";
import {Service} from "../../services/Service";
import {Employees as EmployeesApi} from "../../services/TimeApi";
import {Employee} from "../../../libs/api/time/api-model";

const Header = () => {
  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>Personeel</h3>
      </Col>
      <Col xs="auto" className="ms-auto text-end mt-n1">
        <Button variant="success" className="shadow-sm me-1" href="/pages/time/employees/new">
          <PlusCircle className="feather me-1" />Toevoegen
        </Button>
      </Col>
    </Row>
  );
};

const Employees: React.FC = () => {
  const [employees, setEmployees] = useState<Service<Employee[]>>({ status: 'init' });
  const loadData = () => EmployeesApi.list(setEmployees);
  useEffect(loadData, []);

  return (
    <>
      <Helmet title="Personeel"/>
      <Container fluid className="p-0">
        <Header />
        <Row>
          <Col>
            <ErrorHandlingTS service={employees} onLoaded={(data) =>
              <Card>
                <TableWithFiltering striped bordered
                                    data={data}
                                    columns={employeeColumns}
                                    initialFilters={[
                                      { 'id': 'indienst', 'value': true },
                                    ]}
                />
              </Card>
            } />
          </Col>
        </Row>
      </Container>
    </>
    );
}

export default Employees;