import * as React from "react";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {ButtonProps} from "react-bootstrap/Button";

export interface LinkButtonProps {
  to: string;
  buttonProps: ButtonProps
  children: React.ReactNode
}

export const LinkButton: React.FC<LinkButtonProps> =
  ({
     to,
     buttonProps,
     children
  }) =>
  <Link to={to}>
    <Button {...buttonProps}>
      {children}
    </Button>
  </Link>