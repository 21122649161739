import React from "react";
import {Link} from "react-router-dom";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Trash} from "react-feather";
import {Education, EducationAttendance} from "../../../../libs/api/time/api-model";

type EntityActionHandler<T> = (entity: T) => void;

type ActionProps<T> = {
  value: T
  handleDelete: EntityActionHandler<T>
}

const EducationLink: React.FC<{value: Education}> = ({ value }) =>
  <Link to={`/pages/time/education/${ value.id }`}>
    { value.title }
  </Link>

const DeleteAttendance: React.FC<ActionProps<EducationAttendance>> = ({ value, handleDelete }) =>
  <Button variant="link" size="sm" className="text-danger" onClick={_ => handleDelete(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const AttendanceActions: React.FC<ActionProps<EducationAttendance>> = ({ value, handleDelete }) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <DeleteAttendance value={value} handleDelete={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const educationColumns = (handleDelete: EntityActionHandler<EducationAttendance>) => [
  {
    Header: "Datum",
    accessor: "date",
    width: "15%",
  },
  {
    Header: "Opleiding",
    accessor: "education.id",
    width: "20%",
  },
  {
    Header: "",
    id: "title",
    accessor: "education",
    Cell: EducationLink
  },
  {
    Header: "Docent",
    accessor: "education.docent",
    width: "15%",
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    width: "10%",
    Cell: (data: any) => <AttendanceActions value={data.row.original} handleDelete={handleDelete} />
  }
];

export { educationColumns };
