import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
} from "react-bootstrap";

import EducationControl from "./EducationControl";
import {useNavigate} from "react-router-dom";
import {Education as EducationModel} from "../../../libs/api/time/api-model";
import {FormikHelpers} from "formik/dist/types";
import {Educations as EducationsApi} from "../../services/TimeApi";
import formikHandler from "../../services/FormikApiHandler";

const labelColWidth = 2;
const valueColWidth = 5;

export const CreateEducation: React.FC = () => {

  const navigate = useNavigate();

  const saveEducation = (entity: EducationModel, helpers: FormikHelpers<EducationModel>) =>
    EducationsApi.upsert(entity)(response => {
      formikHandler(helpers)(response);
      if (response.status === 'loaded') {
        navigate("/pages/time/educations");
      }
    });

  return (
    <>
      <Helmet title="Nieuwe Opleiding" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Nieuwe Opleiding</h1>
        <EducationControl education={{
                            id: '',
                            title: '',
                            docent: '',
                            description: '',
                          }}
                          onSave={saveEducation}
                          labelColWidth={labelColWidth}
                          valueColWidth={valueColWidth}
                          flavor='new'
        />
      </Container>
    </>
  );
}
export default CreateEducation;
