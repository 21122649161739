import React from "react";
import {
  Button,
  Modal,
  Form, Row, Col
} from "react-bootstrap";
import {Formik, Form as FormikForm, FormikHelpers, useFormikContext} from 'formik';
import * as Yup from "yup";
import "moment-duration-format";
import util from "../../../../utils/util";
import InputMask from "react-input-mask";
import {Props as ReactInputMaskProps} from "react-input-mask";
import FormSubmitButton from "../../../components/form/FormSubmitButton";
import {PrestatiePatch} from "../../../../../libs/api/time/api-model";
import SelectActivity from "../../../components/form/SelectActivity";

export interface PresenceControlProps {
  modalTitle: string,
  isOpen: boolean,
  onHide: () => void,
  initialValues: PrestatiePatch,
  onSave: (values: PrestatiePatch, formikHelpers: FormikHelpers<PrestatiePatch>) => void | Promise<any>;
}

const PresenceSchema = Yup.object().shape({
  // activityId: Yup.number().required(),
  registratieCode: Yup.string().required(),
});

const normalizeValues: (_: PrestatiePatch) => PrestatiePatch = (values) => {
  const gp = util.isDefined(values) && util.isDefined(values.gp) ? util.duration(values.gp) : undefined;
  const ab = util.isDefined(values) && util.isDefined(values.ab) ? util.duration(values.ab) : undefined;
  return { ...values, gp: gp, ab: ab };
}

const asDuration = (value: string | undefined) =>
  util.isDefined(value) ? util.hoursToDuration(value) : undefined;

const asNumber = (value: string | undefined | number) =>
  util.isDefined(value) ? Number(value) : undefined;

const deNormalizeValues: (_: PrestatiePatch) => PrestatiePatch = (values) => {
  return util.isDefined(values) ?
    {...values,
      gp: asDuration(values?.gp),
      ab: asDuration(values?.ab),
      ovu: asNumber(values?.ovu),
      premie: asNumber(values?.premie),
      arab: asNumber(values?.arab),
    } :
    values
};

export interface FormFieldProps {
  label: string
  name: string
  required?: boolean
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
}

const FormField: React.FC<FormFieldProps> = (
  { label, name, required, ...props }
) => {
  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormikContext<PrestatiePatch>();
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor={`input${name}`}>
        {required ? <strong>{label} *</strong> : label}
      </Form.Label>
      <Form.Control
        {...props}
        name={name}
        id={`input${name}`}
        value={ util.byString(values, name) }
        onChange={(e) => {
          const parsed = e.currentTarget.value.replaceAll(',', '.');
          setFieldValue(name, parsed, true);
        }}
        onBlur={handleBlur}
        isInvalid={!!util.byString(errors, name)}
        className="mh-38"
      />
      <Form.Control.Feedback type="invalid">{util.byString(errors, name)}</Form.Control.Feedback>
    </Form.Group>
  );
}

const PresenceControl: React.FC<PresenceControlProps> = (
  { initialValues, onSave, isOpen, onHide, modalTitle }
) => (
  <Modal
    scrollable={true}
    size="lg"
    show={isOpen}
    onHide={onHide}>
    <Formik
      initialValues={normalizeValues(initialValues)}
      onSubmit={(values, formikHelpers) => onSave(deNormalizeValues(values), formikHelpers)}
      validationSchema={ PresenceSchema }
    >
      {({
          values,
          errors,
          handleChange,
          handleBlur,
          setFieldValue
        }) => (
        <FormikForm>
          <Modal.Header closeButton>
            <h4>{modalTitle}</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-sm-right" htmlFor="inputActivityId">Activiteit</Form.Label>
                  {/*
                  <SelectActivityTime name={"activityId"} useOptionValue={true} required={true} />
                  */}
                  <SelectActivity name="registratieCode"
                                  value={values.registratieCode}
                                  isInvalid={!!errors['registratieCode']}
                                  onChange={(fieldname: string, v: any) => {
                                    setFieldValue('registratieCode', v.id)
                                  }} />
                  <Form.Control.Feedback type="invalid">{errors["registratieCode"]}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-sm-right" htmlFor="inputGP">Gepresteerd</Form.Label>
                  <InputMask mask="99:99"
                             name='gp'
                             id={`inputgp`}
                             value={values['gp']}
                             onChange={handleChange}
                             onBlur={handleBlur}
                             isInvalid={util.isDefined(errors) && !!errors['gp']}
                             className="mh-38">
                    { (props: ReactInputMaskProps) => <Form.Control {...props} type="text"/> }
                  </InputMask>
                  <Form.Control.Feedback type="invalid">errors['gp']</Form.Control.Feedback>
                  {/*
                <FormField label="Gepresteerd" name="gp" values={values} errors={errors} handleChange={handleChange} />
                  <Form.Control.Feedback type="invalid">{errors["tp"]}</Form.Control.Feedback>
                  */}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-sm-right" htmlFor="inputAB">Afboeken</Form.Label>
                  <InputMask mask="99:99"
                             name='ab'
                             id={`inputab`}
                             value={values['ab']}
                             onChange={handleChange}
                             onBlur={handleBlur}
                             isInvalid={util.isDefined(errors) && !!errors['ab']}
                             className="mh-38">
                    { (props: ReactInputMaskProps) => <Form.Control {...props} type="text"/> }
                  </InputMask>
                  <Form.Control.Feedback type="invalid">errors['ab']</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormField label="Memo" name="memo" />
              </Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row>
              <Col md={4}>
                <FormField label="Premie" name="premie" type="number" inputMode="numeric" />
              </Col>
              <Col md={4}>
                <FormField label="ARAB" name="arab" type="number" inputMode="numeric" />
              </Col>
              <Col md={4}>
                <FormField label="OVU" name="ovu" type="number" inputMode="numeric" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onHide}>Annuleren</Button>
            {' '}
            <FormSubmitButton />
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  </Modal>
);

export default PresenceControl;
