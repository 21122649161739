import {dalConfig} from "../../../config";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {useRef, useState} from "react";
import DalService from "../../../services/DalService";
import ErrorHandling from "../../components/ErrorHandling";

const emptyImage = <svg className='mt-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={ { "height": "128px", "width": "128px" } }>
  <circle fill="lightgrey" cx="50" cy="50" r="50" />
</svg>

export const UploadEmployeeImage = ({employeeId, disabled}) => {
  const [file, setFile] = useState();
  const [uploading, setUploading] = useState();
  const [error, setError] = useState();
  const inputRef = useRef(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    setFile(e.target.files[0]);

    setUploading(true);
    setTimeout(() =>
      DalService.uploadEmployeeImage(employeeId, e.target.files[0],
        (response) => setUploading(false), // todo after upload, changes are not shown in ui
        (error) => {
          setUploading(false);
          setError(error);
        }
      ),
      2000
    );
  };

  return (
    <div className="text-center">
      <pre>{file && `${file.name} - ${file.type}`}</pre>
      <ErrorHandling error={error} loading={uploading}>
        {disabled ? emptyImage :
          <img
            alt={employeeId}
            src={ disabled ? null : `${dalConfig.baseUrl}/cdn/images/employees/${employeeId}/image` }
            className="rounded-circle img-responsive mt-2"
            width="128"
            height="128"
          />
        }
      </ErrorHandling>
      <div className="mt-2">
        <Button variant="primary" disabled={disabled || uploading} onClick={handleUploadClick}>
          <FontAwesomeIcon icon={faUpload} /> Upload
        </Button>
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>
      <small>
        Gebruik een afbeelding in .jpg formaat van minstens <strong>128px</strong> op <strong>128px</strong> voor de beste resultaten.
      </small>
    </div>
  );
}