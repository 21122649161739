import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FormikValues} from "formik/dist/types";
import Select, {SingleValue} from "react-select";
import classNames from "classnames";
import {Service, toOption} from "../../../services/Service";
import {Activity} from "../../../../libs/api/time/api-model";
import {Activities} from "../../../services/TimeApi";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import util, {numberOrNull} from "../../../utils/util";

export interface SelectActivityTimeProps {
  name: string
  required?: boolean
  useOptionValue?: boolean // geef de value van de option door bij selectie, of de hele option
}

type ActivityOption = {
  label: string,
  value: string
}

const toActivityOption = (activity: Activity) => ({value: activity.id.toString(), label: activity.name} as ActivityOption);
const emptyOption = () => ({ value: "", label: "Maak een keuze" } as ActivityOption);

export const SelectActivityTime: React.FC<SelectActivityTimeProps> = ({
    name, required, useOptionValue = false, ...props
}) => {

  const [activities, setActivities] = useState<Service<Activity[]>>({ status: 'init' });
  const loadEntries = () => Activities.list(setActivities);
  useEffect(loadEntries, []);

  const activityOptions: ActivityOption[] =
    pipe(activities,
      s => toOption(s),
      option.map(us => pipe(us, array.map(toActivityOption))),
      option.getOrElse(() => [] as ActivityOption[]),
    );

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const isInvalid = !!util.byString(errors, name);
  const hasDataError = activities.status === "error";

  const findActivity = (selected: SingleValue<ActivityOption>) =>
    pipe(activities,
      s => toOption(s),
      option.chain(
        array.findFirst(o =>
          pipe(selected,
            option.fromNullable,
            option.map(o => o.value),
            option.exists(v => v === o.id.toString())
          )
        )
      ),
      option.toUndefined
    );

  const findOption = pipe(activities,
    s => toOption(s),
    option.chain(
      array.findFirst(o =>
        pipe(util.byString(values, name),
          option.fromNullable,
          option.exists((t: any) =>
            useOptionValue ?
              String(t) === o.id.toString() :
              (t as Activity).id === o.id
          )
        )
      )
    ),
    option.map(toActivityOption),
    option.getOrElse(() => emptyOption())
  );

  // todo deleted activities op disabled zetten?

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid || hasDataError ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={activityOptions}
        isLoading={activities.status === "loading"}
        id={`input_${name}`}
        onChange={option => setFieldValue(name, useOptionValue ? numberOrNull(option?.value) : findActivity(option))}
        onBlur={handleBlur}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      {
        activities.status === "error" && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van de activiteiten: ${activities.error ? activities.error.message : '?'}`}</Form.Control.Feedback>
      }
      {isInvalid && <Form.Control.Feedback type="invalid">{util.byString(errors, name)}</Form.Control.Feedback>}
    </>
  );
};

export default SelectActivityTime;
