import {Employee} from "../../libs/api/time/api-model";
import {isMoment, now} from "moment";
import moment from "moment";
import {Vehicle as DALVehicle} from "../../libs/api/dal/api-model";
import {option} from "fp-ts";
import {pipe} from "fp-ts/function";

export const isInService: (_: Employee) => boolean = (employee: Employee) =>
  !employee.dateServiceOut || (isMoment((moment(employee.dateServiceOut)) && moment(employee.dateServiceOut).isBefore(now())))

export const isDeleted: (_: DALVehicle) => boolean = (vehicle: DALVehicle) =>
  pipe(vehicle.deleted, option.fromNullable, option.isSome)

export const isActive: (_: DALVehicle) => boolean = (vehicle: DALVehicle) => !isDeleted(vehicle)
