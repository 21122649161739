import React from "react";
import util from "../../utils/util";
import moment from "moment/moment";
import { Page, Text, View, Document } from '@react-pdf/renderer';
import TableRows from "../components/TableRows";
import styles from "../components/ReportStyles";
import TableHeader from "../components/TableHeader";
import {DateRange, toLongFormat} from "../../model/Common";
import {ColumnDefinition} from "../components/PdfTypes";
import {FleetCostEntry, Site, SiteFleetCost} from "../../../libs/api/time/api-model";
import {Style} from "@react-pdf/types";

type SiteFleetReportDocumentProps = {
  columns: ColumnDefinition<FleetCostEntry>[]
  data: SiteFleetCost[]
  period: DateRange
}

const SiteFleetReportDocument: React.FC<SiteFleetReportDocumentProps> = (
  { columns, data, period }
) => (
  <Document>
    <Page size="A4" style={styles.page} orientation={"landscape"} wrap>
      {/*<Image style={styles.image} src={Logo} fixed />*/}
      {/*<Image style={styles.image} src='/logo-ve-small.jpg' fixed />*/}
      <ReportHeader period={period} />
      {util.isDefined(data) && data.map((entry, i) =>
        <TableSection key={entry.site.id} index={i} columns={columns} fleetCost={entry} />
      )}
      <Text style={styles.date} fixed>{ moment().format('YYYY-MM-DD HH:mm') }</Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

const ReportHeader: React.FC<{ period: DateRange }> = ({ period }) =>
  <View style={styles.header} fixed>
    <Text style={styles.title}>Fleet Kost per Firma</Text>
    <Text style={styles.subtitle}>{ toLongFormat(period) }</Text>
  </View>

type SiteFleetCostProps = {
  columns: ColumnDefinition<FleetCostEntry>[]
  index: number
  fleetCost: SiteFleetCost
}

const TableSection: React.FC<SiteFleetCostProps> = (
  {
    index,
    columns,
    fleetCost: { entries, site, totalKmCost, totalFuelCost, totalPurchaseCost, totalPeriodCost },
  }
) =>
  <View style={styles.section} break={index !== 0}>
    <Text style={styles.tabletitle} fixed>{ site.name }</Text>
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} style={styles.columnHeader} />
      <TableRows columns={columns} data={entries} style={styles.tableRow} />
      <TableFooter columns={columns}
                   site={site}
                   totalKmCost={util.formatCurrency(totalKmCost)}
                   totalFuelCost={util.formatCurrency(totalFuelCost)}
                   totalPurchaseCost={util.formatCurrency(totalPurchaseCost)}
                   totalPeriodCost={util.formatCurrency(totalPeriodCost)}
      />
    </View>
  </View>

type SiteFleetCostFooterProps = {
  "columns": ColumnDefinition<FleetCostEntry>[]
  "site": Site
  "totalFuelCost": string,
  "totalPeriodCost": string,
  "totalPurchaseCost": string,
  "totalKmCost": string,
}

const TableFooter: React.FC<SiteFleetCostFooterProps> = (
  { columns, site, totalKmCost, totalFuelCost, totalPurchaseCost, totalPeriodCost}
) => {
  const colStyle = (i: number): Style[] => {
    const colStyleAtIndex = columns[columns.length - i].styles;
    return (colStyleAtIndex ? [colStyleAtIndex] : []);
  };
  return (
    <View style={styles.tableFooter}>
      <Text style={ [{ width: '72%', textAlign: 'left', alignSelf: 'center'}, styles.columnFooter] }>Totaal voor {site.name}</Text>
      <Text style={ colStyle(3).concat([styles.columnFooter]) }>{ totalKmCost }</Text>
      <Text style={ colStyle(3).concat([styles.columnFooter]) }>{ totalFuelCost }</Text>
      <Text style={ colStyle(2).concat([styles.columnFooter]) }>{ totalPurchaseCost }</Text>
      <Text style={ colStyle(1).concat([styles.columnFooter, { borderRightWidth: 0 }]) }>{ totalPeriodCost }</Text>
    </View>
  );
};

export default SiteFleetReportDocument;
