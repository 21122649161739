import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import React from "react";
import {FormikValues} from "formik/dist/types";

export interface BooleanFormikFieldProps {
  label: string
  name: string
}

export const BooleanFormikField: React.FC<BooleanFormikFieldProps> =
  ({
     label, name, ...props
  }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormikContext<FormikValues>();
  return (
      <Form.Check
          inline={true}
          type="switch"
          id={`input${name}`}
          name={name}
          label={label}
          checked={values[name]}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={!!touched[name] && !!errors[name]}
          className='mh-38'
          {...props}
      />
  );
}

export default BooleanFormikField;
