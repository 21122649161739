import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {DateRange, equalDates, parseRange, toShortFormat, toUriQuery} from "../../model/Common";
import {Service} from "../../services/Service";
import {Reports} from "../../services/TimeApi";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Row} from "react-bootstrap";
import DateRangePicker from "../components/DateRangePicker";
import PdfDownload from "../components/PdfDownload";
import SiteFleetReportDocument from "./SiteFleetReportDocument";
import {fleetCostColums} from "./columns";
import {fleetCostPDFColums} from "./pdf-columns";
import util from "../../utils/util";
import SimpleTable from "../../pages/components/SimpleTable";
import ErrorHandlingTS, {MapServiceElement} from "../../pages/components/ErrorHandlingTS";
import {SiteFleetCostReport} from "../../../libs/api/time/api-model";
import EmptyResult from "../components/EmptyResult";

const SiteFleetReport: React.FC = () => {

  const navigate = useNavigate();
  const {from, to} = useParams();
  const [period, setPeriod] = useState<DateRange>(parseRange(from, to));
  const [fleetCost, setFleetCost] = useState<Service<SiteFleetCostReport>>({
    status: 'loading'
  });

  const loadReport = () => Reports.fleetSite(period)(setFleetCost);

  useEffect(() => navigate(`/reports/fleet/sites/${toUriQuery(period)}`, ), [ navigate, period ]);
  useEffect(loadReport, [ period ]);

  const onDateSet: (_: DateRange) => void = (range: DateRange) => {
    if (!equalDates(period, range)) {
      setPeriod(range)
    }
  }

  return (
    <>
      <Helmet title="Fleet Kost per Firma" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Fleet Kost per Firma</h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <DateRangePicker onSuccess={onDateSet} initialRange={period} />
              </Col>
              <Col md={8} className='text-end'>
                <MapServiceElement service={fleetCost} onLoaded={(data: SiteFleetCostReport) =>
                  <PdfDownload dataLoading={fleetCost.status === 'loading'}
                               document={<SiteFleetReportDocument data={data.entries} columns={fleetCostPDFColums} period={period} />}
                               filename={`FleetKost-${toShortFormat(period)}.pdf`}
                               label={`FleetKost.pdf`}
                  />
                }
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ErrorHandlingTS service={fleetCost} onLoaded={(data: SiteFleetCostReport) =>
          <>
            {util.isDefined(data) && util.isDefined(data.entries) && data.entries.length === 0 && <EmptyResult />}
            { util.isDefined(data) && util.isDefined(data.entries) && data.entries.map(({site, totalKmCost, totalFuelCost, totalPurchaseCost, totalPeriodCost, entries}) =>
              <Card key={site.id}>
                <Card.Header>
                  <Card.Title className="mb-0">
                    <h4>{site.name}</h4>
                  </Card.Title>
                </Card.Header>
                <SimpleTable bordered data={entries} columns={fleetCostColums} renderFooter={(rows: any, visibleColumnsLength: number) =>
                  <tr>
                    <th scope="row" colSpan={visibleColumnsLength - 4}>Totaal</th>
                    <td className="text-end fw-bolder">{util.formatCurrency(totalKmCost)}</td>
                    <td className="text-end fw-bolder">{util.formatCurrency(totalFuelCost)}</td>
                    <td className="text-end fw-bolder">{util.formatCurrency(totalPurchaseCost)}</td>
                    <td className="text-end fw-bolder">{util.formatCurrency(totalPeriodCost)}</td>
                  </tr>
                } />
              </Card>
            )}
          </>
          }
        />
      </Container>
    </>
  );
}

export default SiteFleetReport;
