import React from "react";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";

import { Table } from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort, faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import SearchColumnFilter from "./tables/filters/SearchColumnFilter";

const defaultPropGetter = (row) => ({})

const TableWithFiltering = ({ columns, data, getRowProps = defaultPropGetter, initialSort = [], initialFilters = [], ...props }) => {
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: SearchColumnFilter,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: {
          sortBy: initialSort,
          filters: initialFilters
        }
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy
    );

  return (
    <Table className="my-0" {...props} {...getTableProps()}>
      <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps(column.getSortByToggleProps([{
              className: column.className,
              style: { minWidth: column.minWidth, width: column.width, ...column.style },
            }]))}>
              {column.render("Header")}
              {column.canSort && <span>
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <FontAwesomeIcon icon={faSortUp} className="ms-2"/>
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} className="ms-2"/>
                  )
                ) : (
                  <FontAwesomeIcon icon={faSort} className="ms-2"/>
                )}
              </span>}
              <div>
                {column.canFilter ? column.render("Filter") : null}
              </div>
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps(getRowProps(row))}>
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps([{
                  className: cell.column.className,
                  style: { minWidth: cell.column.minWidth, width: cell.column.width, ...cell.column.style },
                }])}>{cell.render("Cell")}</td>
              );
            })}
          </tr>
        );
      })}
      {rows && rows.length === 0 &&
        <tr role='row'>
          <td role='cell' colSpan={headerGroups[0].headers.length}>
            <p className='fst-italic my-1'>Geen resultaten gevonden</p>
          </td>
        </tr>
      }
      </tbody>
    </Table>
  );
};

export default TableWithFiltering;
