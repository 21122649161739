import React, {ReactElement, useEffect, useState} from "react";
import { Helmet } from "react-helmet-async";
import {Card, Container, Col, Row} from "react-bootstrap";
import moment, {Moment} from "moment/moment";
import 'moment/locale/nl-be';
import MonthlyStats from "./components/MonthlyStats";
import PresenceTable from "./components/PresenceTable";
import YearlyStats from "./components/YearlyStats";
import {useNavigate, useParams} from "react-router-dom";
import * as A from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import * as N from "fp-ts/number";
import {
  EmployeeSummary,
  MonthlyPrestatie,
  PrestatiePatch
} from "../../../../libs/api/time/api-model";
import SelectEmployee from "../../components/form/SelectEmployee";
import {pipe} from "fp-ts/function";
import DatePickerWithNavigation from "../../components/DatePickerWithNavigation";
import PresenceControl from "./components/PresenceControl";
import {FormikHelpers} from "formik";
import {Prestaties} from "../../../services/TimeApi";
import formikHandler from "../../../services/FormikApiHandler";

const parseYearMonth = (yyyyMM: string | undefined) =>
  pipe(moment(yyyyMM), (result) => moment.isMoment(result) ? result : moment());

const parseEmployeeNr = (strValue: string | undefined) =>
  pipe(strValue, O.fromNullable, O.map(parseInt));

const MonthlyPresence: React.FC = () => {

  const { yearMonth: yearUrlParam, employee: employeeUrlParam } = useParams();
  const navigate = useNavigate();

  const [date, setDate] = useState<Moment>(parseYearMonth(yearUrlParam));
  const [employeeNr, setEmployeeNr] = useState<O.Option<number>>(parseEmployeeNr(employeeUrlParam));

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editInstance, setEditInstance] = useState<O.Option<MonthlyPrestatie>>(O.none);

  const mapAsReact: <T>(onV: (nr: T) => ReactElement) => (o: O.Option<T>) => ReactElement = (generateElement) => (o) =>
    pipe(o,
      O.map(optionValue => generateElement(optionValue)),
      O.getOrElse(() => <></>)
    );

  const yearSelected = (value: Moment | string) => {
    if (typeof value !== 'string' && value.year() !== date.year()) {
      setDate(date.clone().year(value.year()));
    }
  };

  const monthSelected = (month: number) => {
    if (month !== date.month()) {
      setDate(date.clone().month(month));
    }
  };

  const employeeSelected = (value: EmployeeSummary | undefined) =>
    pipe(value,
      O.fromNullable,
      O.map(e => e.employeeNr),
      O.filter(nr => !O.elem(N.Eq)(nr)(employeeNr)),
      O.map((nr) => setEmployeeNr(O.some(nr)))
    );

  const generatedUrl =
    pipe(employeeNr,
      O.map(nr => `/pages/registrations/presence/monthly/${date.format('yyyy-MM')}/employee/${nr}`),
      O.getOrElse(() => `/pages/registrations/presence/monthly/${date.format('yyyy-MM')}`)
    );

  const redirectPage = () => navigate(generatedUrl);

  useEffect(redirectPage, [ generatedUrl, employeeNr, date, navigate ]);

  const editEntity = (prestatie: MonthlyPrestatie) => {
    setShowEditModal(true);
    setEditInstance(O.some(prestatie));
  };

  const hideEditModal = () => {
    setShowEditModal(false);
    setEditInstance(O.none);
  };

  const toPatch: (_: MonthlyPrestatie) => PrestatiePatch = (prestatie: MonthlyPrestatie) =>
    (
      {
        registratieCode: prestatie.value?.code,
        gp: prestatie.gp,
        memo: prestatie.memo,
        premie: prestatie.premie,
        arab: prestatie.arab,
        ovu: prestatie.ovu,
        ab: prestatie.ab
      }
    );

  const saveEntity = (patch: PrestatiePatch, formikHelpers: FormikHelpers<PrestatiePatch>) => {
    pipe(
      A.sequenceS(O.Apply)({ nr: employeeNr, prestatie: editInstance }),
      O.map(({ nr, prestatie }) =>
        Prestaties.update(nr, moment(prestatie.date))(patch)(response => {
          formikHandler(formikHelpers)(response);
          if (response.status === 'loaded') {
            hideEditModal();
            navigate(0);
          }
        })
      )
    );
  }

  // @ts-ignore
  return (
    <React.Fragment>
      <Helmet title="Aanwezigheid" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Maand overzicht</h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={5}>
                <SelectEmployee
                  idType='code'
                  onChange={employeeSelected}
                  value={pipe(employeeNr, O.map(e => e.toString()), O.toUndefined)}
                  usePaging={true}
                  useActiveToggle={true} />
              </Col>
              <Col md={1}></Col>
              <Col md={2}>
                <DatePickerWithNavigation
                  onScroll={(amount: number) => (value: Moment) => value.subtract(amount, "years")}
                  input={true}
                  value={ date }
                  initialValue={ moment() }
                  dateFormat="YYYY"
                  timeFormat={false}
                  inputProps={{className: "form-control mh-38 text-center"}}
                  onChange={ yearSelected }
                  closeOnSelect={true}
                  locale="nl-be"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col lg="5" className="d-flex col-xxl-4">
            <div className="w-100">
              <Row>
                <Col sm="12" lg="12" xxl={12} className="d-flex col-xxl-6">
                  { pipe(employeeNr,
                    mapAsReact((nr) =>
                      <MonthlyStats employeeNr={nr}
                                    year={ date.year() }
                                    activeMonth={ date.month() }
                                    onSelectMonth={(year, month) => monthSelected(month)} />
                    ))
                  }
                </Col>
                <Col sm="12" lg="12" xxl={12} className="d-flex">
                  { pipe(employeeNr,
                    mapAsReact((nr) =>
                      <YearlyStats employeeNr={nr} year={ date.year() } />
                    ))
                  }
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg="7" className="d-flex col-xxl-8">
            { pipe(employeeNr,
              mapAsReact((nr) =>
                <PresenceTable employeeNr={nr} year={ date.year() } month={ date.month() } onEdit={editEntity} />
              ))
            }
          </Col>
        </Row>
      </Container>
      { pipe(editInstance,
        mapAsReact((prestatie) => {
          const e = pipe(employeeNr, O.map(n => n.toString()), O.getOrElse(()=> ''));
          return (
            <PresenceControl modalTitle={`Aanwezigheid van werknemer '${e}' op ${prestatie.date}`}
                             isOpen={showEditModal}
                             onHide={hideEditModal}
                             onSave={saveEntity}
                             initialValues={toPatch(prestatie)}
             />
          );
        }
        ))
      }
    </React.Fragment>
  );
}

export default MonthlyPresence;
