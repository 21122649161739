import {
  Award,
  Box,
  Sliders,
  Users,
  Truck, Clock, Droplet, PieChart, Calendar, Printer
} from "react-feather";
import moment from "moment/moment";

const clockingSection = [
  {
    icon: Clock,
    title: "Dag Overzicht",
    href: "/pages/clockings/daily"
  }
]

const planningSection = [
  {
    icon: Calendar,
    title: "Per werknemer",
    href: `/pages/planning/daily/${moment().format('yyyy-MM-DD')}/employee`
  },
  {
    icon: Printer,
    title: "Per activiteit",
    href: `/pages/planning/daily/${moment().format('yyyy-MM-DD')}/activity`
  }
]

const registrationSection = [
  {
    icon: Box,
    title: "Project",
    subject: "registration_project",
    href: "/pages/registrations/postcalc/daily"
  },
  {
    icon: Clock,
    title: "Prestaties",
    subject: "registration_presence",
    children: [
      {
        href: "/pages/registrations/presence/daily",
        icon: Clock,
        title: "Dag",
      },
      {
        href: "/pages/registrations/presence/monthly",
        icon: Calendar,
        title: "Maand",
      }
    ]
  }
]

const peopleSection = [
  {
    href: "/pages/time/employees",
    icon: Users,
    title: "Personeel"
  },
  {
    href: "/pages/time/educations",
    icon: Award,
    title: "Opleidingen"
  },
  {
    icon: Sliders,
    title: "Instellingen",
    children: [
      {
        href: "/pages/time/settings/postcalculation",
        title: "Parameters",
      },
      {
        href: "/pages/time/settings/activities",
        title: "Activiteiten",
      },
      {
        href: "/pages/time/settings/absence-types",
        title: "Afwezigheden",
      },
      {
        href: "/pages/time/settings/calendars",
        title: "Kalenders",
      }
    ]
  }
]

const materialSection = [
  {
    href: "/pages/material/vehicles",
    icon: Truck,
    title: "Voertuigen"
  },
  {
    href: "/pages/material/fuel",
    icon: Droplet,
    title: "Brandstof"
  }
]

const reportSection = [
  {
    href: "/reports/fleet/sites",
    icon: PieChart,
    title: "Fleet per site"
  },
  {
    href: "/reports/fleet/analytic",
    icon: PieChart,
    title: "Fleet analytisch"
  },
  {
    href: "/reports/intercompany",
    icon: PieChart,
    title: "Intercompany"
  },
  {
    href: "/reports/mobility-premiums",
    icon: PieChart,
    title: "Mobiliteit Premies"
  },
  {
    href: "/reports/transport",
    icon: PieChart,
    title: "Transport Prestaties"
  }
]

const navItems = [
  {
    title: "Dagplanning",
    pages: planningSection,
    subject: "planning",
  },
  {
    title: "Tikkingen",
    pages: clockingSection,
    subject: "clockings",
  },
  {
    title: "Registratie",
    pages: registrationSection,
  },
  {
    title: "Personeel",
    pages: peopleSection,
    subject: "time",
  },
  {
    title: "Materiaal",
    pages: materialSection,
    subject: "infra",
  },
  {
    title: "Rapportage",
    pages: reportSection,
    subject: "reports"
  }
];

export default navItems;
