import * as React from "react";
import {useParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Service} from "../../../services/Service";
import {Helmet} from "react-helmet-async";
import {Card, Container, } from "react-bootstrap";
import Selectors from "./components/Selectors";
import TableWithFiltering from "../../components/TableWithFiltering";
import {initialPresenceSort, presenceColumns} from "./columns";
import ErrorHandlingTS from "../../components/ErrorHandlingTS";
import util from "../../../utils/util";
import moment from "moment";
import {Moment} from "moment/moment";
import PresenceControl from "./components/PresenceControl";
import {none, Option, map, getOrElse} from "fp-ts/Option";
import {array} from "fp-ts";
import {pipe} from "fp-ts/function";
import {Timesheets} from "../../../services/DalServiceTS";
import {PrestatieControle} from "../../../../libs/api/dal/api-model";
import {FormikHelpers, FormikValues} from "formik/dist/types";

export interface DailyPresenceProps {
}

const dateFormat = 'yyyy-MM-DD';

const parseDate = (dateAsString: string | undefined) => {
  const parsed = util.isDefined(dateAsString) && moment(dateAsString);
  return moment.isMoment(parsed) ? parsed : moment();
};

const rowClass = (row: any) =>
  //row.values.toPerform !== row.values.performed.performed ? 'table-warning' :
  util.isDefined(row.values.checkin) && util.isDefined(row.values.checkout) ? 'table-success' :
    util.isDefined(row.values.checkin) && !util.isDefined(row.values.checkout) ? 'table-primary' : '';

const DailyPresence: React.FC<DailyPresenceProps> = props => {
  const { date: dateUrlParam } = useParams();
  const navigate = useNavigate();
  const [entriesData, setEntriesData] = useState<Service<PrestatieControle[]>>({ status: 'init' });
  const [date, setDate] = useState<Moment>(parseDate(dateUrlParam));
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editEntity, setEditEntity] = useState<Option<PrestatieControle>>(none);

  const loadEntries = () => {
    setEntriesData({ status: 'loading' });
    Timesheets.get(date,
      (response: PrestatieControle[]) => setEntriesData({ status: 'loaded', payload: response }),
      (error: any) => setEntriesData({ status: 'error', error }),
    );
  };
  const patchEntity = (entity: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
    Timesheets.patch(parseDate(entity.date), entity.employee.id, entity,
      (_) => {
        formikHelpers.setFieldTouched("submit", true);
        loadEntries();
        setEditEntity(none);
        setEditModalOpen(false);
      },
      (error) => {
        formikHelpers.setFieldError("submit", error.toString());
      }
    );
  }

  const dateSelected = (value: Moment | string) => {
    if (typeof value === 'string') {
      setEntriesData({ status: 'error', error: new Error(`invalid date selection: ${value}`)});
    } else if (date.format(dateFormat) !== value.format(dateFormat)) {
      navigate(`/pages/registrations/presence/daily/${value.format(dateFormat)}`);
    }
  };

  const findEntity: (_: number) => Option<PrestatieControle> = (employeeId)  => {
    switch (entriesData.status) {
      case "loaded":
        return array.findFirst((e: PrestatieControle) => e.employee.id === employeeId)(entriesData.payload);
      default:
        return none;
    }
  };
  const showEditModal = (employeeId: number) => {
    console.debug(`showing edit modal for employee ${employeeId}`, employeeId);
    setEditModalOpen((previous) => !previous);
    setEditEntity(findEntity(employeeId));
  };
  const hideEditModal = () => {
    setEditEntity(none);
    setEditModalOpen((previous) => !previous);
  };

  useEffect(loadEntries, [ date ]);
  useEffect(() => {
    if (dateUrlParam !== date.format(dateFormat)) {
      setDate(parseDate(dateUrlParam));
    }
  }, [date, dateUrlParam]);

  const modalTitle = pipe(
    editEntity,
    map((entity) => entity.employee),
    map((employee) => `Aanwezigheid ${employee.name} op ${date.format(dateFormat)}`),
    getOrElse(() => 'Aanwezigheid')
  );

  return (
    <>
      <Helmet title="Aanwezigheid" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Aanwezigheid</h1>
        <Selectors value={date} onChange={dateSelected} />
        <ErrorHandlingTS service={entriesData} onLoaded={ (entries) =>
          <Card>
            <TableWithFiltering bordered hover data={entries} columns={presenceColumns(showEditModal)} getRowProps={row => ({
              className: rowClass(row),
            })} initialSort={initialPresenceSort} />
          </Card>
        } />
      </Container>
      {
        pipe(
          editEntity,
          map((entity) =>
            <PresenceControl onSave={patchEntity}
                             initialValues={ entity }
                             onHide={hideEditModal}
                             isOpen={isEditModalOpen}
                             modalTitle={modalTitle}
            />),
          getOrElse(() => <></>)
        )
      }
    </>
  );
}

export default DailyPresence;
