import * as React from "react";
import {
  ErrorMessage,
  Formik,
  FormikHelpers,
  FormikValues,
  Form as FormikForm,
  useFormikContext
} from 'formik';
import {Button, Col, Form, Modal, Row, } from "react-bootstrap";
import SelectActivity from "../../../components/form/SelectActivity";
import util from "../../../../utils/util";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import {Props as InputMaskProps} from "react-input-mask";
import FormSubmitButton from "../../../components/form/FormSubmitButton";
import UnderConstructionAlert from "../../../components/UnderConstructionAlert";

export interface PresenceControlProps {
  modalTitle: string,
  isOpen: boolean,
  onHide: () => void,
  initialValues: FormikValues,
  onSave: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void | Promise<any>;
}

const PresenceSchema = Yup.object().shape({
  activity: Yup.object().required(),
});

const normalizeValues: (_: FormikValues) => FormikValues = (values) => {
  const gp = util.isDefined(values) && util.isDefined(values.gp) ? util.duration(values.gp) : undefined;
  const toPerform = util.isDefined(values) && util.isDefined(values.toPerform) ? util.duration(values.toPerform) : undefined;
  const performed = util.isDefined(values) && util.isDefined(values.performed) ? util.duration(values.performed) : undefined;
  return { ...values, gp: gp, toPerform: toPerform, performed: performed };
};

const deNormalizeValues: (_: FormikValues) => FormikValues = (values) => {
  const gp = util.isDefined(values) && util.isDefined(values.gp) ? util.hoursToDuration(values.gp) : undefined;
  const toPerform = util.isDefined(values) && util.isDefined(values.toPerform) ? util.hoursToDuration(values.toPerform) : undefined;
  const performed = util.isDefined(values) && util.isDefined(values.performed) ? util.hoursToDuration(values.performed) : undefined;
  return util.isDefined(values) ?
    {...values, gp: gp, toPerform: toPerform, performed: performed} :
    values
};

export interface FormFieldProps {
  label: string
  name: string
  required?: boolean
}

export interface FormFieldWrapperProps {
  label: string
  name: string
  required?: boolean
  children: React.ReactNode
}

const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({label, name, required, children}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor={`input${name}`}>
        {required ? <strong>{label} *</strong> : label}
      </Form.Label>
      <Col>
        {children}
        <ErrorMessage name={name} component={Form.Control.Feedback} type='invalid'/>
      </Col>
    </Form.Group>
  );
};

const FormField: React.FC<FormFieldProps> = ({label, name, required}) => {
  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor={`input${name}`}>
        {required ? <strong>{label} *</strong> : label }
      </Form.Label>
      <Form.Control
        name={name}
        id={`input${name}`}
        value={values[name]}
        onChange={(e) => {
          const parsed = e.currentTarget.value.replaceAll(',', '.');
          setFieldValue(name, parsed, true);
        }}
        onBlur={handleBlur}
        isInvalid={!!errors[name]}
        className="mh-38"
      />
      <ErrorMessage name={name} component={Form.Control.Feedback} type='invalid' />
    </Form.Group>
  );
};

type TimeInputProps = InputMaskProps & {
  label: string
  name: string
  required?: boolean
}

const TimeInput: React.FC<TimeInputProps> =
  ({
    label,
    name,
    required,
    mask
  }) => {
  const {
    values,
    errors,
    handleBlur,
    handleChange
  } = useFormikContext<FormikValues>();
  return (
    <FormFieldWrapper label={label} name={name} required={required}>
      <InputMask mask={mask}
                 className="mh-38"
                 value={values[name]}
                 onChange={handleChange}
                 onBlur={handleBlur}
                 name={name}
                 id={`input${name}`}>
        {(inputProps: any) => <Form.Control {...inputProps} isInvalid={!!errors[name]} type="text" /> }
      </InputMask>
    </FormFieldWrapper>
  );
};

const PresenceControl: React.FC<PresenceControlProps> = ({initialValues, onSave, isOpen, onHide, modalTitle}) => (
  <Modal
    scrollable={true}
    size="lg"
    show={isOpen}
    onHide={onHide}>
    <Formik
      initialValues={normalizeValues(initialValues)}
      onSubmit={(values, formikHelpers) => onSave(deNormalizeValues(values), formikHelpers)}
      validationSchema={PresenceSchema}
    >
      {({
          isSubmitting,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched
        }) => (
        <FormikForm>
          <Modal.Header closeButton>
            <h4>{modalTitle}</h4>
          </Modal.Header>
          <Modal.Body>
            <Row><UnderConstructionAlert /></Row>
            {/*
            <Row><pre>{JSON.stringify(values)}</pre></Row>
            */}
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-sm-right" htmlFor="inputActivity">Activiteit</Form.Label>
                  <SelectActivity name="activity" value={values.activity} isInvalid={!!errors['activity']} onChange={setFieldValue} onBlur={setFieldTouched}/>
                  <ErrorMessage name='activity' component={Form.Control.Feedback} type='invalid' />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputtoPerform">Te Presteren</Form.Label>
                  <Form.Control
                    name={"toPerform"}
                    id={`inputtoPerform`}
                    value={values["toPerform"]}
                    isInvalid={!!errors["toPerform"]}
                    className="mh-38"
                    readOnly={true}
                  />
                  {/*
                    <DurationField
                      name='tp'
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className="mh-38"
                      readOnly={true}
                    />
                    <Form.Control.Feedback type="invalid">{errors["tp"]}</Form.Control.Feedback>
                    */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <TimeInput mask="99:99" label="Begin" name="checkin" required={false} />
              </Col>
              <Col md={2}>
                <TimeInput mask="99:99" label="Eind" name="checkout" required={false} />
              </Col>
              <Col md={2}>
                <TimeInput mask="99:99" label="Gepresteerd" name="performed" required={false} readOnly={true} />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <FormField label="Premie" name="premie" />
              </Col>
              <Col md={2}>
                <FormField label="ARAB" name="arab" />
              </Col>
              <Col md={2}>
                <FormField label="OVU" name="ovu" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormField label="Memo" name="memo" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onHide}>Annuleren</Button>
            {' '}
            <FormSubmitButton />
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  </Modal>
)

export default PresenceControl;
