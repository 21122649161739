import React from "react";
import { Navigate } from "react-router-dom";
import moment from "moment/moment";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
import LandingLayout from "./layouts/Landing";
import PassthroughLayout from "./layouts/PassthroughLayout";
import PrintLayout from "./layouts/Print";

// Guards
import AuthenticatedGuard from "./components/guards/AuthenticatedGuard";
import AuthorizedGuard from "./components/guards/AuthorizedGuard";

// Landing
import Landing from "./pages/Landing";

// Pages
import Blank from "./pages/Blank";
import Employees from "./pages/employees/Employees";
import CreateEmployee from "./pages/employee/CreateEmployee";
import Employee from "./pages/employee/Employee";
import Educations from "./pages/educations/Educations";
import CreateEducation from "./pages/education/CreateEducation";
import Education from "./pages/education/Education";
import PostCalcSettings from "./pages/parameters/PostCalcParameters";

import Vehicles from "./pages/vehicles/Vehicles";
import Vehicle from "./pages/vehicle/Vehicle";
import CreateVehicle from "./pages/vehicle/CreateVehicle";
import Fuel from "./pages/fuel/Fuel";
import PostCalculation from "./pages/postcalc/PostCalculation";
import DailyPresence from "./pages/presence/daily/DailyPresence";
import AbsenceTypes from "./pages/absence/AbsenceTypes";
import Activities from "./pages/activities/Activities";
import Calendars from "./pages/calendars/Calendars";
import MonthlyPresence from "./pages/presence/monthly/MonthlyPresence";
import PostCalculationByDay from "./pages/postcalc/daily/PostCalculationByDay";
import DailyPlanningByEmployee from "./pages/planning/daily/DailyPlanningByEmployee";
import DailyPlanningByActivity from "./pages/planning/daily/DailyPlanningByActivity";
import DailyPlanningByActivityPreview from "./pages/planning/daily/DailyPlanningByActivityPreview";

// Reports
import ReportLayout from "./reports/ReportLayout";
import SiteFleetReport from "./reports/fleet/SiteFleetReport";
import AnalyticFleetReport from "./reports/fleet/AnalyticFleetReport";
import InterCompanyReport from "./reports/intercompany/InterCompanyReport";
import MobilityPremiumsReport from "./reports/mobility/MobilityPremiumsReport";
import TransportTimeReport from "./reports/transport/TransportTimeReport";

// Auth
import Page500 from "./pages/auth/Page500";
import Page403 from "./pages/auth/Page403";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";

// Protected routes
import ProtectedPage from "./pages/ProtectedPage";
import {currentMonthRange, toUriQuery} from "./model/Common";

const routes = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <Navigate to='/dashboard' />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthenticatedGuard>
        <DashboardLayout />
      </AuthenticatedGuard>
    ),
    children: [
      {
        path: "",
        element: <Landing />
      }
    ],
  },
  {
    path: "pages",
    element: (
      <AuthenticatedGuard>
        <DashboardLayout />
      </AuthenticatedGuard>
    ),
    children: [
      {
        path: "blank",
        element: <Blank />,
      },
      {
        path: "time",
        element: (
          <AuthorizedGuard subject="time">
            <PassthroughLayout />
          </AuthorizedGuard>
        ),
        children: [
          {
            path: "employees",
            element: <Employees />,
          },
          {
            path: "employees/new",
            element: <CreateEmployee />,
          },
          {
            path: "employee/:employeeId/*",
            element: <Employee />,
          },
          {
            path: "educations",
            element: <Educations />,
          },
          {
            path: "educations/new",
            element: <CreateEducation />,
          },
          {
            path: "education/:educationId/*",
            element: <Education />,
          },
          {
            path: "settings/postcalculation",
            element: <PostCalcSettings/>
          },
          {
            path: "settings/absence-types",
            element: <AbsenceTypes/>
          },
          {
            path: "settings/activities",
            element: <Activities/>
          },
          {
            path: "settings/calendars",
            children: [
              {
                path: ":yearMonth",
                element: <Calendars />
              },
              {
                path: ":yearMonth/calendar/:calendar",
                element: <Calendars />
              },
              {
                index: true,
                element: <Navigate to={`${moment().format('yyyy-MM')}`} />
              }
            ]
          }
        ]
      },
      {
        path: "material",
        element: (
          <AuthorizedGuard subject="infra">
            <PassthroughLayout />
          </AuthorizedGuard>
        ),
        children: [
          {
            path: "vehicles",
            element: <Vehicles />,
          },
          {
            path: "vehicles/new",
            element: <CreateVehicle />,
          },
          {
            path: "vehicle/:vehicleId/*",
            element: <Vehicle />,
          },
          {
            path: "fuel",
            children: [
              {
                path: ":fueltype",
                element: <Fuel />
              },
              {
                index: true,
                element: <Navigate to={`/pages/material/fuel/0`} />
              }
            ],
          }
        ]
      },
      {
        path: "clockings",
        element: (
            <AuthorizedGuard subject="clockings">
              <PassthroughLayout/>
            </AuthorizedGuard>
        ),
        children: [
          {
            path: "daily",
            children: [
              {
                path: ":date",
                element: <PostCalculationByDay />
              },
              {
                index: true,
                element: <Navigate to={moment().format('yyyy-MM-DD')} />
              }
            ]
          }
        ]
      },
      {
        path: "planning",
        element: (
          <AuthorizedGuard subject="planning">
            <PassthroughLayout/>
          </AuthorizedGuard>
        ),
        children: [
          {
            path: "daily",
            children: [
              {
                path: ":date",
                children: [
                  {
                    path: "activity",
                    children: [
                      {
                        path: ":activity",
                        element: <DailyPlanningByActivity />
                      },
                      {
                        index: true,
                        element: <Navigate to='montage' />
                      }
                    ]
                  },
                  {
                    path: "employee/:employee?",
                    element: <DailyPlanningByEmployee />
                  },
                  {
                    index: true,
                    element: <Navigate to='employee' />
                  }
                ]
              },
              {
                index: true,
                element: <Navigate to={`${moment().format('yyyy-MM-DD')}`} />
              }
            ],
          }
        ]
      },
      {
        path: "registrations",
        children: [
          {
            path: "postcalc",
            element: (
              <AuthorizedGuard subject="registration_project">
                <PassthroughLayout/>
              </AuthorizedGuard>
            ),
            children: [
              {
                path: "daily",
                children: [
                  {
                    path: ":date",
                    children: [
                      {
                        path: "employee/:employee?",
                        element: <PostCalculation />
                      },
                      {
                        index: true,
                        element: <Navigate to='employee' />
                      }
                    ]
                  },
                  {
                    index: true,
                    element: <Navigate to={`${moment().format('yyyy-MM-DD')}`} />
                  }
                ],
              }
            ]
          },
          {
            path: "presence/daily",
            element: (
              <AuthorizedGuard subject="registration_presence">
                <PassthroughLayout />
              </AuthorizedGuard>
            ),
            children: [
              {
                path: ":date",
                element: <DailyPresence />
              },
              {
                index: true,
                element: <Navigate to={moment().format('yyyy-MM-DD')} />
              }
            ]
          },
          {
            path: "presence/monthly",
            children: [
              {
                path: ":yearMonth",
                element: <MonthlyPresence />
              },
              {
                path: ":yearMonth/employee/:employee",
                element: <MonthlyPresence />
              },
              {
                index: true,
                element: <Navigate to={`${moment().format('yyyy-MM')}`} />
              }
            ]
          }
        ]
      }
    ],
  },
  {
    path: "reports",
    element: (
      <AuthenticatedGuard>
        <AuthorizedGuard subject="reports">
          <DashboardLayout />
        </AuthorizedGuard>
      </AuthenticatedGuard>
    ),
    children: [
      {
        path: "fleet/sites",
        element: <ReportLayout />,
        children: [
          {
            path: "from/:from/to/:to",
            element: <SiteFleetReport/>
          },
          {
            index: true,
            element: <Navigate to={toUriQuery(currentMonthRange())} />
          }
        ]
      },
      {
        path: "fleet/analytic",
        element: <ReportLayout />,
        children: [
          {
            path: "from/:from/to/:to",
            element: <AnalyticFleetReport/>
          },
          {
            index: true,
            element: <Navigate to={toUriQuery(currentMonthRange())} />
          }
        ]
      },
      {
        path: "intercompany",
        element: <ReportLayout />,
        children: [
          {
            path: "from/:from/to/:to",
            element: <InterCompanyReport/>
          },
          {
            index: true,
            element: <Navigate to={toUriQuery(currentMonthRange())} />
          }
        ]
      },
      {
        path: "mobility-premiums",
        element: <ReportLayout />,
        children: [
          {
            path: "from/:from/to/:to",
            element: <MobilityPremiumsReport />
          },
          {
            index: true,
            element: <Navigate to={toUriQuery(currentMonthRange())} />
          }
        ]
      },
      {
        path: "transport",
        element: <ReportLayout />,
        children: [
          {
            path: "from/:from/to/:to",
            element: <TransportTimeReport />
          },
          {
            index: true,
            element: <Navigate to={toUriQuery(currentMonthRange())} />
          }
        ]
      }
    ]
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "unauthorized",
        element: <Page403 />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "docs",
    element: <DocLayout />,
    children: [
      {
        path: "blank",
        element: <Blank />,
      }
    ],
  },
  {
    path: "private",
    element: (
      <AuthenticatedGuard>
        <DashboardLayout />
      </AuthenticatedGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "preview",
    element: <PrintLayout />,
    children: [
      {
        path: "planning/daily/:date/activity/:activity",
        element: <DailyPlanningByActivityPreview />
      }
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
