import React, {ReactNode} from "react";
import { Outlet } from "react-router-dom";

const ReportLayout: React.FC<{ children?: ReactNode | undefined }> = ({ children }) => (
  <React.Fragment>
    { children }
    <Outlet />
  </React.Fragment>
);

export default ReportLayout;
