import React from "react";
import {Card, Table} from "react-bootstrap";
import util from "../../../utils/util";
import moment from "moment/moment";
import 'moment/locale/nl-be';
import {getDaysInAMonth} from "../calendarUtil";
import RegistratieBadge from "../../components/RegistratieBadge";

const CalendarMonth = ({ date, data, onEdit }) => {
  const daysInMonth = getDaysInAMonth(moment(date).year(), moment(date).month());
  const inCurrentMonth = m => m.isSame(moment(date), 'month');
  const findEntry = day =>
    util.isDefined(data) ? data.find(item => day.isSame(moment(item.date), 'day')) : null;

  return (
    <Card className="flex-fill w-100 h-100">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {util.isDefined(date) ? date.format('MMMM') : 'selecteer datum'}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table size="sm" className="mt-0 mb-4 table-cell-hover">
          <thead>
          <tr>
            <td style={{ width: '9%' }}>&nbsp;</td>
            {daysInMonth[0].map((day) =>
                <th key={`dayheader_${day.format('dd')}`}
                    style={{ width: '13%' }}
                    className="text-center">
                  {day.format('dd')}
                </th>
            )}
          </tr>
          </thead>
          <tbody>
          {
            daysInMonth.map((week) =>
              <tr key={`weekrow_${week[0].format('ww')}`}>
                <th key={`weekheader_${week[0].format('ww')}`} style={{ width: '9%' }}>{week[0].format('ww')}</th>
                {week.map((day) => {
                  const entry = findEntry(day);
                  return(
                    <td key={`daycolumn_${day.format('DD')}`}
                        style={{ width: '13%', height: '85px' }}
                        className={inCurrentMonth(day)? 'text-center' : 'text-center table-light text-muted'}
                        onClick={() => onEdit(day)}>
                      <small>{day.format('DD')}</small>
                      {util.isDefined(entry) &&
                        <><br/><h3><RegistratieBadge as={'span'} className='mt-2' registratie={ entry.registratie } /></h3></>
                      }
                    </td>
                  );
                  }
                )}
              </tr>
            )
          }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default CalendarMonth;
