import axios from "../utils/axios"
import {dalConfig} from "../config";
import {toLegacySiteAny} from "./DalServiceTS";

const get = (url, onSuccess, onFailure) => axios.get(url)
  .then(onSuccess)
  .catch(onFailure);

const put = (url, entity, onSuccess, onFailure) => axios.put(url, entity)
  .then(onSuccess)
  .catch(onFailure);

const post = (url, entity, onSuccess, onFailure) => axios.post(url, entity)
  .then(onSuccess)
  .catch(onFailure);

const doDelete = (url, onSuccess, onFailure) => axios.delete(url)
  .then(onSuccess)
  .catch(onFailure);

const getWorkingCalendars = (onSuccess, onFailure) =>
  get('/hr/calendars', onSuccess, onFailure)

const getActivities = (onSuccess, onFailure) =>
  get('/hr/activities', onSuccess, onFailure)

const saveActivity = (entity, onSuccess, onFailure) =>
  put('/hr/activities', entity, onSuccess, onFailure)

const deleteActivity = (id, onSuccess, onFailure) =>
  doDelete(`/hr/activities/${id}`, onSuccess, onFailure)

const updateVehicle = (vehicle, onSuccess, onFailure) => {
  const patched = {...vehicle, site: toLegacySiteAny(vehicle.site)};
  return put('/infra/vehicles', patched, onSuccess, onFailure);
}

const insertVehicle = (vehicle, onSuccess, onFailure) =>{
  const patched = {...vehicle, site: toLegacySiteAny(vehicle.site)};
  return post('/infra/vehicles', patched, onSuccess, onFailure);
}

const getVehicleTypes = (onSuccess, onFailure) =>
  get('/infra/vehicle/types', onSuccess, onFailure)

const getDrivers = (onSuccess, onFailure) =>
  get('/infra/vehicle/drivers', onSuccess, onFailure)

const getStationTypes = (onSuccess, onFailure) =>
  get('/infra/vehicle/stationTypes', onSuccess, onFailure)

const getMaintenanceTypes = (onSuccess, onFailure) =>
  get('/infra/vehicle/maintenanceTypes', onSuccess, onFailure)

const getGasTypes = (onSuccess, onFailure) =>
  get('/infra/vehicle/gasTypes', onSuccess, onFailure)

const getCounterTypes = (onSuccess, onFailure) =>
  get('/infra/vehicle/counterTypes', onSuccess, onFailure)

const getCostTypes = (onSuccess, onFailure) =>
  get('/infra/vehicle/costTypes', onSuccess, onFailure)

const getVehicleCosts = (vehicleId, onSuccess, onFailure) =>
  get(`/infra/vehicles/${vehicleId}/costs`, onSuccess, onFailure)

const newVehicleCost = (vehicleId, cost, onSuccess, onFailure) =>
  post(`/infra/vehicles/${vehicleId}/costs`, cost, onSuccess, onFailure)

const updateVehicleCost = (vehicleId, cost, onSuccess, onFailure) =>
  put(`/infra/vehicles/${vehicleId}/costs/${cost.id}`, cost, onSuccess, onFailure)

const deleteVehicleCost = (vehicleId, costId, onSuccess, onFailure) =>
  doDelete(`/infra/vehicles/${vehicleId}/costs/${costId}`, onSuccess, onFailure)

const uploadEmployeeImage = (employeeId, file, onSuccess, onFailure) => {
  const data = new FormData();
  data.set("image", file);
  return axios.post(`/cdn/images/employees/${employeeId}/image`, data,
    {
      baseURL: dalConfig.baseUrl,
      headers: {
        'content-type': 'multipart/form-data',
      }
    })
    .then(onSuccess)
    .catch(onFailure);
}

const DalService = {
  getActivities, saveActivity, deleteActivity,
  uploadEmployeeImage, getWorkingCalendars,
  updateVehicle, insertVehicle,
  getVehicleTypes, getDrivers, getStationTypes, getMaintenanceTypes, getGasTypes, getCounterTypes, getCostTypes,
  getVehicleCosts, newVehicleCost, updateVehicleCost, deleteVehicleCost,
};

export default DalService;
