import {Button, Spinner} from "react-bootstrap";
import * as Icon from "react-feather";
import React from "react";
import {useFormikContext} from "formik";
import {FormikValues} from "formik/dist/types";

export interface FormSubmitButtonProps {

}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = props => {
  const {
    errors,
    isSubmitting,
    touched
  } = useFormikContext<FormikValues>();

  return (
    <>
      <Button type="submit" disabled={isSubmitting}>Opslaan</Button>
      {isSubmitting && <Spinner animation="grow" color="dark" size="sm" className="mx-2"/>}
      {errors.submit &&
        <span className={"text-danger mx-2"}><Icon.Frown className="me-1"/> {errors.submit.toString()}</span>}
      {touched.submit && !errors.submit &&
        <span className={"text-success mx-2"}><Icon.CheckCircle size={18}/></span>}
    </>
  );
}

export default FormSubmitButton;