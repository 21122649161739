import * as React from "react";
import {Card, Form} from "react-bootstrap";
import {Formik, FormikHelpers} from "formik";
import HorizontalColumnField from "../components/form/HorizontalColumnField";
import * as Yup from "yup";
import FormSubmitButton from "../components/form/FormSubmitButton";
import {CostParameter} from "../../../libs/api/time/api-model";

const nummer = () => Yup.number().typeError('geef een geldig cijfer').required();

const schema = Yup.object().shape({
  kmRate: nummer(),
  nightlyFee: nummer(),
  arabFee: nummer(),
  ovuFee: nummer(),
});

export interface CostParametersProps {
  costParameter: CostParameter
  onSave: (values: CostParameter, formikHelpers: FormikHelpers<CostParameter>) => void | Promise<any>;
}

const CostParameters: React.FC<CostParametersProps> = ({
  costParameter,
  onSave
}) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Premies
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          onSubmit={onSave}
          initialValues={costParameter}
        >
          {({
              handleSubmit
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <HorizontalColumnField label="KM Prijs" name="kmRate" />
              <HorizontalColumnField label="Nachtvergoeding" name="nightlyFee" />
              <HorizontalColumnField label="ARAB" name="arabFee" />
              <HorizontalColumnField label="OVU" name="ovuFee" />
              <FormSubmitButton />
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default CostParameters;