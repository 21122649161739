import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FormikValues} from "formik/dist/types";
import Select from "react-select";
import classNames from "classnames";
import {Service, toOption} from "../../../services/Service";
import {GeoDynamicsUser} from "../../../../libs/api/time/api-model";
import {GeoDynamics} from "../../../services/TimeApi";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import util from "../../../utils/util";
import {InputActionMeta} from "react-select/src/types";

export interface SelectGeoDynamicsUserProps {
  name: string
  isClearable: boolean
  required?: boolean
}

type UserOption = {
  label: string,
  value: string
}

const toUserOption = (user: GeoDynamicsUser) => ({value: user.id, label: user.name} as UserOption);

export const SelectGeoDynamicsUser: React.FC<SelectGeoDynamicsUserProps> = ({name, required, ...props}) => {

  const [users, setUsers] = useState<Service<GeoDynamicsUser[]>>({ status: 'init' });
  const [lastSearch, setLastSearch] = useState<string>("");

  const loadUsers = () => GeoDynamics.users(lastSearch)(setUsers);

  useEffect(loadUsers, [ lastSearch ]);

  const userOptions: UserOption[] =
    pipe(users,
      s => toOption(s),
      option.map(us => pipe(us, array.map(toUserOption))),
      option.getOrElse(() => [] as UserOption[]),
    );

  const inputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (util.isDefined(newValue) && actionMeta.action === "input-change") {
      setLastSearch(newValue);
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const isInvalid= !!touched[name] && !!errors[name];
  const hasDataError = users.status === "error";

  const findOption = pipe(userOptions,
    array.findFirst(o => o.value === values[name]),
    option.getOrElse(() => ({value: values[name], label: values[name]} as UserOption))
  )

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid || hasDataError ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={userOptions}
        isLoading={users.status === "loading"}
        id={`input_${name}`}
        onChange={option => setFieldValue(name, option != null ? option.value : null)}
        onBlur={handleBlur}
        onInputChange={inputChange}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        {...props}
      />
      <small className="text-muted">Geef enkele karakters om te zoeken</small>
      {
        users.status === "error" && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van de gebruikers: ${users.error ? users.error.message : '?'}`}</Form.Control.Feedback>
      }
      { isInvalid && <Form.Control.Feedback type="invalid">{util.byString(errors, name)}</Form.Control.Feedback> }
    </>
  );
};

export default SelectGeoDynamicsUser;
