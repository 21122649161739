import React from "react";
import {
  Badge,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import util from "../../../utils/util";
import SelectColumnFilter from "../../components/tables/filters/SelectColumnFilter";
import moment from "moment";
import "moment-duration-format";
import ActivityBadge from "../../components/ActivityBadge";

const TPHeader = (_) =>
  <OverlayTrigger placement='top' overlay={<Tooltip id={`tooltip-header-tp`}>Te Presteren</Tooltip>}>
    <span>TP</span>
  </OverlayTrigger>

const GPHeader = (_) =>
  <OverlayTrigger placement='top' overlay={<Tooltip id={`tooltip-header-tp`}>Gepresteerd</Tooltip>}>
    <span>GP</span>
  </OverlayTrigger>

const Activity = ({value}) =>
  <ActivityBadge activity={value} />

const durationDiff = (one, two) => {
  const diff = moment.duration(one).subtract(moment.duration(two));
  return diff.format("hh:mm", {
    trim: false
  });
  //return moment.utc(diff.as('milliseconds')).toISOString();
  //return diff.toISOString();
  //return moment.utc(diff.asMilliseconds()).format('HH:mm');
}

// {moment.utc(moment.duration(performed).subtract(moment.duration(toPerform)).as('milliseconds')).format('HH:mm')}
const Performed = ({value: {performed, activity, rcActivity, rcDuration, toPerform}}) => {
  // row.values.toPerform !== row.values.performed.performed ? 'table-warning' :
  const performedDuration = util.duration(performed);
  if (typeof rcDuration !== 'undefined' && rcDuration) {
    return (
      <React.Fragment>
        {performedDuration}
        <Badge bg={moment.duration(performed).asMilliseconds() > moment.duration(toPerform).asMilliseconds() ? "success" : "warning"}
               className="ms-1">
          {durationDiff(performed, toPerform)}
        </Badge>
        {/*
        <span className={moment.duration(performed).asMilliseconds() > moment.duration(toPerform).asMilliseconds() ? "text-success" : "text-warning"}>{durationDiff(performed, toPerform)}</span>
        */}
      </React.Fragment>
    );
  } else if (typeof rcActivity !== 'undefined' && rcActivity && rcActivity === activity) {
    return <span className="fw-lighter">{performedDuration}</span>;
  }
  return <span>{performedDuration}</span>;
}

const presenceColumns = (handleEdit) => [
  /*
  {
    Header: "Datum",
    accessor: "date",
    className: "col md-1",
    width: '10%',
    Filter: false,
    disableSortBy: true
  },
   */
  {
    Header: "Werknemer",
    id: "employee",
    accessor: "employee.name",
    width: '20%',
    disableSortBy: true
  },
  {
    Header: "Kalender",
    id: "calendar",
    accessor: "calendar.name",
    width: '10%',
    Filter: SelectColumnFilter,
    filter: "calendar.name",
    disableSortBy: true,
  },
  {
    Header: "Site",
    id: "site",
    accessor: "site.name",
    width: '8%',
    Filter: SelectColumnFilter,
    filter: "site.name",
    disableSortBy: true,
  },
  {
    Header: "Activiteit",
    accessor: "activity",
    Cell: Activity,
    className: 'text-center align-middle',
    width: '7%',
    Filter: false,
    disableSortBy: true
  },
  {
    //Header: "Te Presteren",
    Header: TPHeader,
    accessor: "toPerform",
    Cell: ({value}) => util.duration(value),
    className: 'text-center',
    Filter: false,
    disableSortBy: true,
    width: '7%',
  },
  {
    Header: GPHeader,
    id: "performed",
    accessor: (row) => (row),
    //accessor: "performed",
    //Cell: util.duration,
    className: 'text-center',
    Cell: Performed,
    Filter: false,
    disableSortBy: true,
    width: '7%',
  },
  {
    Header: "Begin",
    id: "checkin",
    accessor: "checkin",
    className: 'text-center',
    Cell: util.localTimeToHours,
    width: '7%',
    Filter: false
  },
  {
    Header: "Eind",
    accessor: "checkout",
    className: 'text-center',
    Cell: util.localTimeToHours,
    width: '7%',
    Filter: false
  },
  {
    Header: "Memo",
    accessor: "memo",
    Filter: false,
    disableSortBy: true,
  }
];

const initialPresenceSort = [
  { id: "calendar", desc: false },
  { id: "site", desc: false },
  { id: "checkin", desc: true },
  { id: "employee", desc: false },
]

export { presenceColumns, initialPresenceSort };
