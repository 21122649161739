import React from "react";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import util from "../../utils/util";
import classNames, {Argument} from "classnames";
import {Activity} from "../../../libs/api/time/api-model";
import {AsProp} from "react-bootstrap/helpers";
import {bgColor, textColor} from "./ActivityBadge";

type ActivityBadgeNewProps = AsProp & {
  activity: Activity,
  className?: Argument
}

const ActivityBadgeNew: React.FC<ActivityBadgeNewProps> = ({activity, className, as}) => {
  return (util.isDefined(activity) &&
      <OverlayTrigger placement='top'
                      overlay={<Tooltip id={`tooltip-header-activity-${activity.code}`}>{activity.name}</Tooltip>}>
          <Badge as={util.isDefined(as) ? as : "sup"}
                 bg={bgColor(activity.code)}
                 text={textColor(activity.code)}
                 className={classNames("p-1", className)}>
            {activity.code}
          </Badge>
      </OverlayTrigger>
  )
}

export default ActivityBadgeNew;
