import React from "react";
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export interface InfoBoxProps {
  msg?: string
  children?: React.ReactNode
}

const InfoBox: React.FC<InfoBoxProps> = ({ msg, children }) =>
  <Alert
    variant='info'
    className="alert-outline-coloured"
    dismissible
  >
    <div className="alert-icon">
      <FontAwesomeIcon icon={faInfoCircle} fixedWidth/>
    </div>
    <div className="alert-message">
      { children !== undefined && children }
      { msg !== undefined && msg }
    </div>
  </Alert>


export default InfoBox;
