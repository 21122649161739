import React from "react";
import {Card} from "react-bootstrap";

const EmptyResult: React.FC = () =>
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">
        <h5 className="m-0">Geen resultaten voor deze periode</h5>
      </Card.Title>
    </Card.Header>
  </Card>

export default EmptyResult;