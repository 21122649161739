import React, {ReactElement} from "react";

import {Spinner} from "react-bootstrap";
import {Frown} from "react-feather";
import {Service} from "../../services/Service";

export type ErrorHandlingTableRowTSProps<T> = {
  colspan?: number
  service: Service<T>
  onLoaded: (t: T) => ReactElement<any, any> | null
}

type TypedErrorHandlingTableRowTSProps<P = any> = React.FC<ErrorHandlingTableRowTSProps<P>>;

const ErrorHandlingTableRow: TypedErrorHandlingTableRowTSProps = (
  { colspan, service, onLoaded }
) => {
  switch (service.status) {
    case 'init':
      return <></>;
    case 'loading':
      return <tr><td colSpan={colspan}><Spinner animation={"grow"} color="dark"/></td></tr>;
    case "error":
      const error = service.error;
      const feedback = typeof error.message !== 'undefined' && error.message !== '' ? ': ' + error.message : '';
      return (
        <tr>
          <td className="text-danger" colSpan={colspan}>
            <Frown /> Fout bij het ophalen van de
            data{feedback}
          </td>
        </tr>
      );
    case "loaded":
      return onLoaded(service.payload);
  }
}

export default ErrorHandlingTableRow;
