import React, {useState} from "react";
import {Button} from "react-bootstrap";
import "react-datetime/css/react-datetime.css";
import 'moment/locale/nl-be';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagic} from "@fortawesome/free-solid-svg-icons";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {Service} from "../../../services/Service";
import {Calendars} from "../../../services/TimeApi";

type YearGeneratorProps = {
  isEnabled: boolean
  year: number
  calendarId: number
  onGenerated: () => void
}

const YearGenerator: React.FC<YearGeneratorProps> = (
  { isEnabled, year, calendarId, onGenerated }
) => {

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [generatorState, setGeneratorState] = useState<Service<string>>({ status: "init" });

  const generateYear = () => Calendars.generateYear(calendarId, year)(response => {
    if (response.status === 'loaded') {
      setShowEditModal(false);
      onGenerated();
    } else if (response.status === 'error') {
      console.error(response.error);
      setGeneratorState({ status: 'error', error: new Error(`Fout bij het genereren van de kalender: ${JSON.stringify(response.error)}`) });
    }
  });

  return (
    <>
      <Button variant="outline-primary" disabled={!isEnabled} onClick={ () => setShowEditModal(true) }>
        <FontAwesomeIcon icon={faMagic} /> Jaar opvullen
      </Button>
      <ConfirmationDialog title={`Kalender ${year} opvullen`}
                          open={ showEditModal }
                          loading={ generatorState.status === "loading" }
                          error={ generatorState.status === "error" }
                          onCancel={ () => setShowEditModal(false) }
                          onConfirm={ generateYear }>
        <>
          <p>Ben je zeker dat je deze volledige kalender wil opvullen met standaard waardes (N voor weekdagen, W voor weekends)?</p>
          <p>Alle bestaande waardes van dit jaar en deze kalender zullen overschreven worden.</p>
        </>
      </ConfirmationDialog>
    </>
  );
}

export default YearGenerator;
