import React from 'react';
import {Text, View } from '@react-pdf/renderer';
import util from "../../utils/util";
import {ColumnDefinition} from "./PdfTypes";
import {Style} from "@react-pdf/types";
import styles from "./ReportStyles";

const columnValue: <T,>(column: ColumnDefinition<T>, data: T) => string = (column, data) => {
  const {
    header,
    accessorPath,
    accessorFn,
    formatter = (v) => v
  } = column;

  return accessorPath
    ? formatter(util.byString(data, accessorPath))
    : accessorFn ? formatter(accessorFn(data)) : `${header} :: undefined`;
};

type TableColumnProps<T> = {
  isFirst: boolean
  isLast: boolean
  column: ColumnDefinition<T>
  element: T
  style?: Style;
}

const TableColumn = <T,>({ isFirst = false, isLast = false, column, element, style = {} }: TableColumnProps<T>) => {
  const { styles: columnDefStyle = {}  } = column;
  const lastColumnStyle: Style = isLast ? { borderRightWidth: 0 } : {};
  const combinedStyles = [ columnDefStyle, styles.column, style, lastColumnStyle ];
  return (
    <Text style={combinedStyles}>{ columnValue(column, element) }</Text>
  );
};

type TableRowsProps<T> = {
  columns: ColumnDefinition<T>[]
  data: T[]
  style?: Style | Style[];
}

const TableRows = <T,>({ columns, data, style }: TableRowsProps<T>) => (
  <>
    {util.isDefined(data) && data.map((d, i) =>
      <View key={`row-${i}`} style={style} wrap={false}>
        {util.isDefined(columns) && columns.map((column, index) =>
          <TableColumn key={`row-${i}-column-${column.header.replaceAll(" ", "_").toLowerCase().trim()}`}
                       column={column}
                       style={i === data.length - 1 ? { borderBottomWidth: 0 } : {}}
                       element={d}
                       isFirst={index === 0}
                       isLast={index === columns.length - 1}
          ></TableColumn>
        )}
      </View>
    )}
  </>
);

export default TableRows;
