import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Card,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import {FormikHelpers} from "formik/dist/types";
import {Education} from "../../../libs/api/time/api-model";
import FormSubmitButton from "../components/form/FormSubmitButton";

const schema = Yup.object().shape({
  title: Yup.string().required(),
  docent: Yup.string().required(),
  description: Yup.string().required(),
});

const schemaWithId = schema.concat(Yup.object().shape({
  id: Yup.string().required(),
}));

type Flavor = 'edit' | 'new';

type EducationControlProps = {
  education: Education
  flavor: Flavor
  labelColWidth: number
  valueColWidth: number
  onSave: (values: Education, formikHelpers: FormikHelpers<Education>) => void | Promise<any>;
}

const EducationControl: React.FC<EducationControlProps> = (
  { flavor, education, labelColWidth, valueColWidth, onSave }
) => (
  <Card>
    <Card.Body>
      <Formik
        validationSchema={typeof flavor !== 'undefined' && flavor && flavor === 'new' ? schemaWithId : schema}
        onSubmit={onSave}
        initialValues={education}
      >
        {({
            handleSubmit,
            handleChange,
            values,
            errors,
          }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {typeof flavor !== 'undefined' && flavor && flavor === 'new' &&
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputId">ID</Form.Label>
                <Col sm={valueColWidth}>
                  <Form.Control
                    type="text"
                    name="id"
                    id="inputId"
                    value={values.id}
                    onChange={handleChange}
                    isInvalid={!!errors['id']}
                  />
                  <Form.Control.Feedback type="invalid">{errors['id']}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            }
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputTitle">Titel</Form.Label>
              <Col sm={valueColWidth}>
                <Form.Control
                  type="text"
                  name="title"
                  id="inputTitle"
                  value={values.title}
                  onChange={handleChange}
                  isInvalid={!!errors['title']}
                />
                <Form.Control.Feedback type="invalid">{errors['title']}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputDocent">Docent</Form.Label>
              <Col sm={valueColWidth}>
                <Form.Control
                  type="text"
                  name="docent"
                  id="inputDocent"
                  value={values.docent}
                  onChange={handleChange}
                  isInvalid={!!errors['docent']}
                />
                <Form.Control.Feedback type="invalid">{errors['docent']}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputDescription">Omschrijving</Form.Label>
              <Col sm={valueColWidth}>
                <Form.Control
                  as="textarea"
                  name="description"
                  id="inputDescription"
                  value={values.description}
                  onChange={handleChange}
                  style={{ height: "150px" }}
                  isInvalid={!!errors['description']}
                />
                <Form.Control.Feedback type="invalid">{errors['description']}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <FormSubmitButton />
          </Form>
        )}
      </Formik>
    </Card.Body>
  </Card>
);

export default EducationControl;
