import React from "react";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Edit, Trash} from "react-feather";
import ActivityBadge from "../components/ActivityBadge";

const Activity = ({value}) =>
  <ActivityBadge activity={value} />

const EditButton = ({value, handleEdit}) =>
  <Button variant="link" size={"sm"} className="p-0" onClick={_ => handleEdit(value)}>
    <Edit className="me-1" size={18} />
  </Button>

const DeleteActivity = ({value, handleDelete}) =>
  <Button variant="link" size="sm" className="text-danger" onClick={_ => handleDelete(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const ActivityActions = ({value, handleEdit, handleDelete}) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <EditButton value={value} handleEdit={handleEdit} />
      <DeleteActivity value={value} handleDelete={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const activityColumns = (editActivity, deleteActivity) =>[
  {
    Header: "#",
    id: "id",
    width: "20%",
    Cell: Activity,
    accessor: (row) => ({ id: row.id, name: row.name }),
    className: 'text-center align-middle',
  },
  {
    Header: "Naam",
    id: "name",
    accessor: "name"
  },
  {
    Header: "Prijs/uur",
    accessor: "hourRate",
    width: "15%",
  },
  {
    Header: "GP",
    accessor: "gp",
    width: "15%",
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    accessor: 'id',
    width: "10%",
    Cell: ({value}) => <ActivityActions value={value} handleEdit={editActivity} handleDelete={deleteActivity} />
  }
];

export { activityColumns };
