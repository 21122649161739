import React from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import {
  Card,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import FormikDateField from "../components/FormikDateField";
import HorizontalColumnField from "../components/form/HorizontalColumnField";
import FormSubmitButton from "../components/form/FormSubmitButton";

const schema = Yup.object().shape({
  licensePlate: Yup.string().required(),
});

const VehicleLicenseInfo = ({vehicle, onSave, labelColWidth, valueColWidth}) => (
  <Card className="mb-0">
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Licenties en Abonnementen
      </Card.Title>
    </Card.Header>
    <Card.Body>
      <Formik
        validationSchema={schema}
        onSubmit={onSave}
        initialValues={vehicle}
      >
        {({
            handleSubmit,
          }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <HorizontalColumnField label='Licentie Transport' name='licTrans' />
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="euroVignetDate">Datum Euro Vignet</Form.Label>
                  <Col sm={valueColWidth}>
                    <FormikDateField name="euroVignetDate" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="licSpecDate">Datum Spec Licentie</Form.Label>
                  <Col sm={valueColWidth}>
                    <FormikDateField name="licSpecDate" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="licVLDate">Datum VL Licentie</Form.Label>
                  <Col sm={valueColWidth}>
                    <FormikDateField name="licVLDate" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="licWLDate">Datum WL Licentie</Form.Label>
                  <Col sm={valueColWidth}>
                    <FormikDateField name="licWLDate" />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <FormSubmitButton />
          </Form>
        )}
      </Formik>
    </Card.Body>
  </Card>
);

export default VehicleLicenseInfo;