import * as React from "react";
import {useEffect, useReducer} from "react";
import { Service, toOption } from "../../services/Service";
import {
  AbsenceType,
} from "../../../libs/api/time/api-model";
import {AbsenceTypes as AbsenceApi} from "../../services/TimeApi";
import {none, Option} from "fp-ts/Option";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {PlusCircle} from "react-feather";
import SimpleTable from "../components/SimpleTable";
import {columns} from "./columns";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import AbsenceTypeControl from "./AbsenceTypeControl";
import ConfirmationDialog from "../components/ConfirmationDialog";
import {FormikHelpers} from "formik/dist/types";
import {array, option} from "fp-ts";
import {pipe} from "fp-ts/function";
import {Helmet} from "react-helmet-async";

type EntityActionBase = {
  type: string
}

type ToggleCreateModal = EntityActionBase & {
  type: 'ToggleCreateModal'
}
type ToggleEditModal = EntityActionBase & {
  type: 'ToggleEditModal'
  entity: Option<AbsenceType>
}
type ToggleDeleteModal = EntityActionBase & {
  type: 'ToggleDeleteModal'
  entity: Option<AbsenceType>
}

type DeleteEntity = EntityActionBase & {
  type: 'DeleteEntity'
  id: number
}
type EntityDeleted = EntityActionBase & {
  type: 'EntityDeleted'
  response: Service<string>
}

type LoadEntities = EntityActionBase & {
  type: 'LoadEntities'
}
type EntitiesLoaded = EntityActionBase & {
  type: 'EntitiesLoaded'
  response: Service<AbsenceType[]>
}

type EntityAction =
  | LoadEntities
  | EntitiesLoaded
  | DeleteEntity
  | EntityDeleted
  | ToggleCreateModal
  | ToggleEditModal
  | ToggleDeleteModal

type EntityPageState = {
  showCreate: boolean
  showEdit: boolean
  showDelete: boolean
  entities: Service<AbsenceType[]>
  entity: Option<AbsenceType>
  entityDeleteService: Service<string>
}

const createInitialState: EntityPageState = ({
  showCreate: false,
  showEdit: false,
  showDelete: false,
  entities: { status: 'init' },
  entity: none,
  entityDeleteService: { status: 'init' }
})

const AbsenceTypes: React.FC = () => {

  function absenceTypesReducer(state: EntityPageState, action: EntityAction): EntityPageState {
    switch (action.type) {
      case 'ToggleCreateModal': {
        return {...state,
          showCreate: !state.showCreate
        };
      }
      case 'ToggleEditModal': {
        return {...state,
          showEdit: !state.showEdit,
          entity: action.entity
        };
      }
      case 'ToggleDeleteModal': {
        return {...state,
          showDelete: !state.showDelete,
          entity: action.entity
        };
      }
      case 'DeleteEntity': {
        return {...state,
          entityDeleteService: { status: 'loading' }
        };
      }
      case 'EntityDeleted': {
        return {...state,
          entityDeleteService: action.response,
          showDelete: action.response.status !== 'loaded'
        };
      }
      case 'LoadEntities': {
        return {...state,
          entities: { status: 'loading' }
        };
      }
      case 'EntitiesLoaded': {
        return {...state,
          entities: action.response,
        };
      }
      default: {
        console.error('Unknown action', action);
        return state;
      }
    }
  }

  const [{
    showCreate,
    showEdit,
    showDelete,
    entities,
    entity,
    entityDeleteService
  }, dispatch] = useReducer(absenceTypesReducer, createInitialState);

  const loadEntities = () => {
    dispatch({ type: 'LoadEntities' });
    AbsenceApi.list((r: Service<AbsenceType[]>) =>
        dispatch({ type: 'EntitiesLoaded', response: r })
    );
  }

  useEffect(loadEntities, [ ]);

  const upsertHandler = (formikHelpers: FormikHelpers<AbsenceType>, successAction: EntityAction) => (response: Service<any | undefined>) => {
    switch (response.status) {
      case "loaded":
        formikHelpers.setFieldTouched("submit", true);
        dispatch(successAction);
        loadEntities();
        return;
      case "error":
        formikHelpers.setFieldError("submit", response.error.toString());
        return;
      default:
        return;
    }
  };

  const createEntity = (entity: AbsenceType, formikHelpers: FormikHelpers<AbsenceType>) =>
    AbsenceApi.create(entity)(upsertHandler(formikHelpers, { type: 'ToggleCreateModal' }));

  const updateEntity = (entity: AbsenceType, formikHelpers: FormikHelpers<AbsenceType>) =>
    AbsenceApi.update(entity.id, entity)(upsertHandler(formikHelpers, { type: 'ToggleEditModal', entity: none }));

  const deleteEntity = () =>
    pipe(
      entity,
      option.map(t => t.id),
      option.fold(
        () => console.warn('cannot delete, id is not found'),
        (id) => {
          dispatch({ type: 'DeleteEntity', id: id });
          AbsenceApi.delete(id)((r: Service<string>) => {
              dispatch({ type: 'EntityDeleted', response: r });
              if (r.status === 'loaded') {
                loadEntities();
              }
            }
          );
        }
      )
    )

  const findEntity: (id: number) => Option<AbsenceType> = (id) =>
    pipe(entities,
        s => toOption(s),
        option.chain(fills => pipe(fills, array.findFirst(f => f.id === id))),
    )

  return (
    <>
      <Helmet title="Afwezigheden"/>
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col xs="auto" className="d-none d-sm-block">
            <h3>Afwezigheden</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="success" className="shadow-sm me-1" onClick={() => dispatch({ type: 'ToggleCreateModal' })}>
              <PlusCircle className="feather me-1" />Toevoegen
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandlingTS service={entities} onLoaded={(types) =>
                <Card>
                <SimpleTable bordered renderFooter={undefined} striped data={types}
                             columns={columns(
                                 (entity: AbsenceType) => dispatch({ type: 'ToggleEditModal', entity: findEntity(entity.id) }),
                                 (entity: AbsenceType) => dispatch({ type: 'ToggleDeleteModal', entity: findEntity(entity.id) })
                             )} />
                </Card>
            }
            />
          </Col>
        </Row>
      </Container>
      <AbsenceTypeControl title={`Afwezigheid type aanmaken`}
                          modalOpen={showCreate}
                          onModalHide={() => dispatch({ type: 'ToggleCreateModal' })}
                          onSave={createEntity}
                          absenceType={{
                            id: 0,
                            name: '',
                            code: '',
                            paid: false,
                         }}
      />
      {
        pipe(entity, option.fold(
          () => <></>,
          (absenceT) =>
            <AbsenceTypeControl title={`Afwezigheid type aanpassen`}
                                absenceType={absenceT}
                                modalOpen={showEdit}
                                onModalHide={() => dispatch({ type: 'ToggleEditModal', entity: none })}
                                onSave={updateEntity}
            />
        ))
      }
      <ConfirmationDialog title={`Afwezigheid type verwijderen`}
                          open={showDelete}
                          loading={entityDeleteService.status === 'loading'}
                          error={entityDeleteService.status === 'error'}
                          onCancel={() => dispatch({ type: 'ToggleDeleteModal', entity: none })}
                          onConfirm={deleteEntity}>
        <p>Ben je zeker dat je dit type wil verwijderen?</p>
      </ConfirmationDialog>
    </>
  );
}

export default AbsenceTypes;
