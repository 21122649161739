import React, {useEffect, useState} from "react";
import DateTime, {DatetimepickerProps} from "react-datetime";
import {FormControl, InputGroup} from "react-bootstrap";
import moment, {Moment} from "moment";
import {currentMonthRange, DateRange} from "../../model/Common";
import util from "../../utils/util";

type DateRangePickerProps = {
  initialRange?: DateRange,
  onSuccess: (range: DateRange) => void;
}

const commonProps: DatetimepickerProps = ({
  input: true,
  dateFormat: "DD/MM/YYYY",
  timeFormat: false,
  closeOnSelect: true,
  locale: "nl-be",
  inputProps: {
    className: "form-control mh-38 text-center",
    style: { zIndex: 9999 }
  }
});

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onSuccess,
  initialRange /*= currentMonthRange() */
}) => {

  const initRange = initialRange ? initialRange : currentMonthRange();

  const [from, setFrom] = useState<Moment>(initRange.from);
  const [to, setTo] = useState<Moment>(initRange.to);

  const inputsChanged = () => {
    if (util.isDefined(from) && moment.isMoment(from) && util.isDefined(to) && moment.isMoment(to)) {
      onSuccess({from: from, to: to});
    }
  };

  useEffect(inputsChanged, [ onSuccess, from, to ]);

  const fromProps = {...commonProps,
    value: from,
    onChange: (selected: string | Moment) => {
      if (moment.isMoment(selected)) {
        setFrom(selected);
      }
    }
  };

  const toProps = {...commonProps,
    value: to,
    onChange: (selected: string | Moment) => {
      if (moment.isMoment(selected)) {
        setTo(selected);
      }
  }};

  return (
    <DateTime renderInput={(fromProps) =>
        <DateTime renderInput={(toProps) =>
            <InputGroup className='mh-38'>
              <InputGroup.Text>Van</InputGroup.Text>
              <FormControl {...fromProps} />
              <InputGroup.Text>Tem</InputGroup.Text>
              <FormControl {...toProps} />
            </InputGroup>
          }
          {...toProps}
        />
      }
      {...fromProps}
    />
  );
}

export default DateRangePicker;