import React, {useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";
import classNames from "classnames";
import {Service, toOption} from "../../../services/Service";
import {Registratie} from "../../../../libs/api/time/api-model";
import {Registraties} from "../../../services/TimeApi";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import {Form} from "react-bootstrap";

export interface SelectRegistratieProps {
  value: string | undefined
  required?: boolean
  isInvalid?: boolean
  useOptionValue?: boolean // geef de value van de option door bij selectie, of de hele option
  onChange: (c: string | Registratie | undefined) => void
}

type RegistratieOption = {
  label: string,
  value: string
}

const getId = (registratie: Registratie) => registratie.code.toString();

const toRegistratieOption = (registratie: Registratie) => (
  { value: getId(registratie), label: registratie.name } as RegistratieOption
);
const emptyOption = () => ({value: "", label: "Maak een keuze"} as RegistratieOption);

export const SelectRegistratie: React.FC<SelectRegistratieProps> = (
  { value, required, isInvalid: isInvalidProp = false , useOptionValue = false, onChange, ...props }
) => {

  const [registraties, setRegistraties] = useState<Service<Registratie[]>>({ status: 'init' });
  const loadEntries = () => Registraties.list(setRegistraties);
  useEffect(loadEntries, []);

  const registratieOptions: RegistratieOption[] =
    pipe(registraties,
      s => toOption(s),
      option.map(array.map(toRegistratieOption)),
      option.getOrElse(() => [] as RegistratieOption[]),
    );

  const isInvalid = isInvalidProp || registraties.status === "error";

  const findOption = pipe(registratieOptions,
    array.findFirst(o => o.value === value?.toString()),
    option.getOrElse(() => emptyOption())
  );

  const findRegistratie = (selected: SingleValue<RegistratieOption>) =>
    pipe(registraties,
      s => toOption(s),
      option.chain(list =>
        array.findFirst((registratie: Registratie) => {
          const nonEmptyValue = pipe(option.fromNullable(selected), option.map(o => o.value), option.getOrElse(() => ''));
          return getId(registratie) === nonEmptyValue;
        })(list)
      )
    );

  const onValueSelected = (selected: SingleValue<RegistratieOption>) =>
    pipe(findRegistratie(selected),
      option.fold(
        () => onChange(undefined),
        (v) => onChange(useOptionValue ? v.code : v)
      )
    );

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={registratieOptions}
        isLoading={ registraties.status === "loading" }
        id={`input_registratie_name_select`}
        onChange={onValueSelected}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      {
        isInvalid && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van registratie codes: ${registraties.status === 'error' ? registraties.error.message : '?'}`}</Form.Control.Feedback>
      }
    </>
  );
};

export default SelectRegistratie;
