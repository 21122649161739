import React from "react";
import {EducationAttendance} from "../../../../libs/api/time/api-model";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Trash} from "react-feather";
import {EmployeeFunctions} from "../../../libs/api/time/api-helpers";

type EntityActionHandler<T> = (entity: T) => void;

type ActionProps<T> = {
  value: T
  handleDelete: EntityActionHandler<T>
}

const DeleteAttendance: React.FC<ActionProps<EducationAttendance>> = ({ value, handleDelete }) =>
  <Button variant="link" size="sm" className="text-danger" onClick={_ => handleDelete(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const AttendanceActions: React.FC<ActionProps<EducationAttendance>> = ({ value, handleDelete }) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <DeleteAttendance value={value} handleDelete={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const attendanceColumns = (handleDelete: EntityActionHandler<EducationAttendance>) => [
  {
    Header: "Datum",
    accessor: "date",
    width: "20%",
  },
  {
    Header: "Deelnemer",
    accessor: (data: any) => EmployeeFunctions.fullname(data.employee),
    width: "20%",
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    width: "10%",
    Cell: (data: any) => <AttendanceActions value={data.row.original} handleDelete={handleDelete} />
  }
];

export { attendanceColumns };
