import util from "../../utils/util";
import { ColumnDefinition } from "../components/PdfTypes";
import {FleetCostEntry} from "../../../libs/api/time/api-model";
import {Style} from "@react-pdf/types";
import {columnBorderColor} from "../components/ReportStyles";

const columnStyle: (styles: Style) => Style = (styles) => {
  const defaults: Style = {
    borderRightColor: columnBorderColor,
    borderRightWidth: 1,
    textAlign: 'left',
  }
  return {
    ...defaults,
    ...styles
  };
}

const fleetCostPDFColums: ColumnDefinition<FleetCostEntry>[] = [
  {
    header: "Type",
    accessorPath: "vehicle.type.name",
    styles: columnStyle({ width: '10%' }),
  },
  {
    header: "Nummerplaat",
    accessorPath: "vehicle.licensePlate",
    styles: columnStyle({ width: '10%' }),
  },  
  {
    header: "Merknaam",
    accessorPath: "vehicle.model",
    styles: columnStyle({ width: '14%' }),
  },
  {
    header: "Bestuurder",
    accessorPath: "vehicle.driver.name",
    styles: columnStyle({ width: '10%' }),
  },
  {
    header: "Km start",
    accessorPath: "odometerStart",
    styles: columnStyle({ width: '7%', textAlign: 'right' }),
    formatter: util.formatInt,
  },
  {
    header: "Km eind",
    accessorPath: "odometerEnd",
    styles: columnStyle({ width: '7%', textAlign: 'right' }),
    formatter: util.formatInt,
  },
  {
    header: "Gereden km",
    accessorPath: "odometerDiff",
    styles: columnStyle({ width: '7%', textAlign: 'right' }),
    formatter: util.formatInt,
  },
  {
    header: "Km kost",
    accessorPath: "vehicle.kmCost",
    styles: columnStyle({ width: '7%', textAlign: 'right' }),
    formatter: util.formatCurrency,
  },
  {
    header: "ICC",
    accessorPath: "totalKmCost",
    styles: columnStyle({ width: '7%', textAlign: 'right' }),
    formatter: util.formatCurrency,
  },
  {
    header: "Brandstof",
    accessorPath: "totalFuelCost",
    styles: columnStyle({ width: '7%', textAlign: 'right' }),
    formatter: util.formatCurrency,
  },
  {
    header: "Aankoop",
    accessorPath: "totalPurchaseCost",
    styles: columnStyle({ width: '7%', textAlign: 'right' }),
    formatter: util.formatCurrency,
  },
  {
    header: "Totaal",
    accessorPath: "totalPeriodCost",
    styles: columnStyle({ width: '7%', textAlign: 'right' }),
    formatter: util.formatCurrency,
  }
];

export { fleetCostPDFColums };
