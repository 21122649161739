import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Alert, Badge, Button, ButtonGroup, Card, Col, Container, Row, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import util from "../../../utils/util";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/option";
import * as NEA from "fp-ts/NonEmptyArray";
import * as R from "fp-ts/Record";
import * as S from 'fp-ts/string'
import * as B from 'fp-ts/boolean'
import { Eq } from "fp-ts/Eq";
import { contramap, getMonoid, reverse, Ord } from 'fp-ts/Ord';
import { concatAll } from 'fp-ts/Monoid'
import { pipe } from "fp-ts/function";
import {useNavigate, useParams} from "react-router-dom";
import {Service} from "../../../services/Service";
import {
  DailyPlanningItem, ProjectGroupRef, DailyPlanning, EmployeeSummary, DailyPlanningVehicle
} from "../../../../libs/api/time/api-model";
import {Planning} from "../../../services/TimeApi";
import ErrorHandlingTS from "../../components/ErrorHandlingTS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import DatePickerWithNavigation from "../../components/DatePickerWithNavigation";
import {ExternalLink, UserCheck} from "react-feather";
import {Departement} from "../../postcalc/daily/PostCalculationByDay";
import {EmployeeFunctions} from "../../../libs/api/time/api-helpers";

const dateFormat = 'yyyy-MM-DD';

const dailyPlanningUrl = (date: Moment, activity: O.Option<Departement>, prefix: string = 'pages') => {
  const activityPart = pipe(
    activity,
    O.map((a) => `/activity/${a.toLowerCase().trim()}`),
    O.getOrElse(() => '')
  );
  return `/${prefix}/planning/daily/${date.format(dateFormat)}${activityPart}`;
};

export const parseDate = (dateAsString: string | undefined) => {
  const parsed = util.isDefined(dateAsString) && moment(dateAsString);
  return moment.isMoment(parsed) ? parsed : moment();
};

const parseActivity: (ac?: string) => O.Option<Departement> = (activityCode?: string) => {
  if (activityCode) {
    switch (activityCode.toLowerCase().trim()) {
      case 't':
      case 'transport':
        return O.some<Departement>('Transport');
      case 'm':
      case 'montage':
        return O.some<Departement>('Montage');
      default:
        return O.none;
    }
  } else {
    return O.none;
  }
};

export const parseActivityWithFallback: (ac?: string) => Departement = (activityCode?: string) =>
  pipe(activityCode, parseActivity, O.getOrElse<Departement>(() => 'Montage'));

const toActivity: (dpi: DailyPlanningItem) => O.Option<Departement> = (item) =>
  pipe(item.activityRef.code, parseActivity);

const eqActivity: Eq<Departement> = S.Eq;

const valueOrEmpty = (str: string | undefined) =>
  pipe(O.fromNullable(str), O.getOrElse(() => ''));

export const formatGroupId = (groupId: string) =>
  groupId.trim().length > 5 ? groupId.trim().substring(0, 5) + ' ' + groupId.trim().substring(5) : groupId;

const byEmployee: Ord<DailyPlanningItem> = pipe(S.Ord, contramap((item) =>
  item.employeeRef ? item.employeeRef.lastname : ''
));
const byStartTime: Ord<DailyPlanningItem> = pipe(S.Ord, contramap((item) =>
  item.start
));
const byVehicle: Ord<DailyPlanningItem> = pipe(S.Ord, contramap((item) =>
  item.vehicleRef ? item.vehicleRef.licensePlate : ''
));
const byDriver: Ord<DailyPlanningItem> = pipe(B.Ord, contramap((item) =>
  item.driver
));

const byStringKey: Ord<[string, any]> = pipe(S.Ord, contramap(([key, values]) => key));

const M = getMonoid<DailyPlanningItem>();
export const ordItem = concatAll(M)([ byStartTime, byVehicle, reverse(byDriver), byEmployee ]);

const filterOnActivity: (a: Departement) => (dp: DailyPlanning) => O.Option<NEA.NonEmptyArray<DailyPlanningItem>> =
  (activity) => (dailyPlanning) => pipe(dailyPlanning.items,
    NEA.fromArray,
    O.chain((nea) =>
      pipe(nea,
        NEA.filter(item => pipe(item, toActivity, O.elem(eqActivity)(activity))),
      )
    ));

type DailyPlanningByProject = Array<[string, NEA.NonEmptyArray<DailyPlanningItem>]>;

export const groupByProject: (dp: DailyPlanning, a: Departement) => O.Option<DailyPlanningByProject> = (dailyPlanning, activity) =>
  pipe(dailyPlanning,
    filterOnActivity(activity),
    O.map((nea) =>
      pipe(nea, NEA.groupBy(item => valueOrEmpty(item.projectRef?.groupId)))
    ),
    O.map(grouped =>
      pipe(R.toArray(grouped), A.sort(byStringKey))
    )
  );

type ByEmployeeStartTime = {
  start: string
  employee: EmployeeSummary
  items: NEA.NonEmptyArray<DailyPlanningItem>
}
type DailyPlanningByEmployeeStartTime = Array<ByEmployeeStartTime>;

const findEmployeeByNr = (nr: string) => (items: NEA.NonEmptyArray<DailyPlanningItem>) =>
  pipe(items, A.filterMap(i => O.fromNullable(i.employeeRef)), A.findFirst(e => e.employeeNr.toString() === nr));

const toByEmployeeStartTime:
  (e: string) => (s: string, i: NEA.NonEmptyArray<DailyPlanningItem>) => O.Option<ByEmployeeStartTime> =
  (employeeNr: string) => (start: string, items: NEA.NonEmptyArray<DailyPlanningItem>) =>
    pipe(items, findEmployeeByNr(employeeNr), O.map(e => (
      {
        start: start,
        employee: e,
        items: items
      }
    )));

const groupByStart: (e: string, i: NEA.NonEmptyArray<DailyPlanningItem>) => Array<ByEmployeeStartTime> =
  (employeeNr: string, items: NEA.NonEmptyArray<DailyPlanningItem>) =>
    pipe(items,
      NEA.groupBy(item => valueOrEmpty(item.start)),
      R.collect(S.Ord)(toByEmployeeStartTime(employeeNr)),
      A.compact
    );

const groupByEmployeeThenStart: (i: NEA.NonEmptyArray<DailyPlanningItem>) => ByEmployeeStartTime[][] =
  (items: NEA.NonEmptyArray<DailyPlanningItem>) =>
    pipe(items,
      NEA.groupBy(item => valueOrEmpty(item.employeeRef?.employeeNr?.toString())),
      R.collect(S.Ord)(groupByStart)
    );

export const groupByEmployeeStartTime: (dp: DailyPlanning, a: Departement) => O.Option<DailyPlanningByEmployeeStartTime> = (dailyPlanning, activity) =>
  pipe(dailyPlanning,
    filterOnActivity(activity),
    O.map((nea) =>
      pipe(nea,
        groupByEmployeeThenStart,
        A.flatten
      )
    )
  );

export const allDescriptions: (i: DailyPlanningItem[]) => string[] = (items) =>
  pipe(items,
    A.filterMap(item => O.fromNullable(item.description)),
    A.filter(s => !S.isEmpty(s.trim()))
  );

type ProjectHeaderProps = {
  projectRef?: ProjectGroupRef,
  planningDescriptions: string[]
}

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({ projectRef, planningDescriptions }) => (
  pipe(
    projectRef,
    O.fromNullable,
    O.fold(
      () => <h4><Badge bg='danger'>Project onbekend</Badge></h4>,
      (project) => (
        <>
          <Row className="">
            <Col xs="auto" className="d-none d-sm-block">
              <h4>
                <Badge bg='secondary'>{ formatGroupId(project.groupId) }</Badge>
                <small className="ms-2 text-muted text-truncate">{ project.company }</small>
                <small className="ms-2 text-truncate">| { project.location }</small>
              </h4>
            </Col>
            <Col xs="auto" className="ms-auto text-end">
              <h4>{ project.checkinId && <Badge bg='warning'>{ project.checkinId }</Badge> }</h4>
            </Col>
          </Row>
          <h4 className={'project-header m-0'}>{ project.description }</h4>
          {
            A.isNonEmpty(planningDescriptions) &&
              <h5 className='fst-italic text-primary'>
                { pipe(planningDescriptions,
                  A.mapWithIndex((i, d) =>
                    <div key={`description${project.groupId}-${i}`}>{d}</div>
                  ))
                }
              </h5>
          }
        </>
      )
    )
  )
);

type StartTimeHeaderProps = {
  start: string
  employee: EmployeeSummary
  vehicle: O.Option<DailyPlanningVehicle>
}

const StartTimeHeader: React.FC<StartTimeHeaderProps> = ({ start, employee, vehicle }) => (
  <>
    <Row>
      <Col xs="auto" className="d-none d-sm-block">
        <h4 className="mb-0">
          <Badge bg='secondary'>{ util.localTimeToHours({ value: start }) }</Badge>
          <small className="ms-2 text-truncate">{ EmployeeFunctions.fullname(employee) }</small>
        </h4>
      </Col>
      <Col xs="auto" className="ms-auto text-end">
          {pipe(vehicle, O.fold(
            () =>
              <h4 className="mb-0">
                <Badge bg='danger'>'Geen voertuig gevonden'</Badge>
              </h4>,
            (v) =>
              <h4 className="mb-0">
                <Badge bg='secondary'>{ v.licensePlate }</Badge>
                <small className="ms-2 text-truncate">{ v.name }</small>
              </h4>
          ))}
      </Col>
    </Row>
  </>
);

export const EmptyPlanning: React.FC = () => (
  <Alert
    variant='info'
    className="alert-outline-coloured"
  >
    <div className="alert-icon">
      <FontAwesomeIcon icon={faInfo} fixedWidth/>
    </div>
    <div className="alert-message">
      <span>Geen planning gegevens gevonden voor deze datum</span>
    </div>
  </Alert>
);

type PlanningByProjectProps = {
  projectRef?: ProjectGroupRef
  planningItems: DailyPlanningItem[]
  description: string[]
}

export const PlanningItemRow: React.FC<{ item: DailyPlanningItem }> = (
  { item: { employeeRef, start, licensePlate, vehicleRef, driver } }
) => (
  <tr role="row" className={driver ? 'driver' : ''}>
    <td role="cell" className="employeeRef" style={{ width: "30%" }}>
      { employeeRef ? EmployeeFunctions.fullname(employeeRef) : '' }
    </td>
    <td role="cell" className="start" style={{ width: "15%" }}>
      { util.localTimeToHours({ value: start }) }
    </td>
    <td role="cell" className="licensePlate" style={{ width: "10%" }}>
      { licensePlate }
    </td>
    <td role="cell" className="vehicleRef" style={{ width: "30%" }}>
      { vehicleRef?.name }
    </td>
    <td role="cell" className="driver" style={{ width: "15%" }}>
      { driver && <UserCheck className="text-success" size={18} /> }
    </td>
  </tr>
);

export const PlanningByProject: React.FC<PlanningByProjectProps> = (
  {projectRef, planningItems, description}
) => (
  <Card key={projectRef ? projectRef.groupId : 'empty-project'}>
    <Card.Header>
      <Card.Title className="mb-0">
        <ProjectHeader projectRef={projectRef} planningDescriptions={description}/>
      </Card.Title>
    </Card.Header>
    <Table className='my-0' bordered={true} size='sm'>
      <thead className='nobreak-before'>
      <tr role="row">
        <th role="columnheader" className="employeeRef" style={{ width: "30%" }}>Werknemer</th>
        <th role="columnheader" className="start" style={{ width: "15%" }}>Begin</th>
        <th colSpan={2} role="columnheader" className="licensePlate" style={{ width: "40%" }}>Voertuig</th>
        <th role="columnheader" className="driver" style={{ width: "15%" }}>Bestuurder</th>
      </tr>
      </thead>
      <tbody>
      { pipe(planningItems, A.map(item => <PlanningItemRow key={item.id} item={item} /> )) }
      </tbody>
    </Table>
  </Card>
);


type PlanningByStartTimeProps = {
  start: string
  employee: EmployeeSummary
  planningItems: NEA.NonEmptyArray<DailyPlanningItem>
}

const PlanningByStartTime: React.FC<PlanningByStartTimeProps> = (
  { start, employee, planningItems }
) => (
  <Card key={ `planning-by-start-${start}-${employee.id}` }>
    <Card.Header>
      <Card.Title className="mb-0">
        <StartTimeHeader start={ start }
                         employee={ employee }
                         vehicle={ pipe(NEA.head(planningItems), (item => O.fromNullable(item.vehicleRef))) }
        />
      </Card.Title>
    </Card.Header>
    <Table className='my-0' bordered={true} size='sm'>
      <thead className='nobreak-before'>
      <tr role="row">
        <th role="columnheader" className="projectGroupNr" style={{ width: "15%" }}>Nr</th>
        <th role="columnheader" className="company" style={{ width: "20%" }}>Klant</th>
        <th role="columnheader" className="location" style={{ width: "20%" }}>Locatie</th>
        <th role="columnheader" className="description" style={{ width: "45%" }}>Omschrijving</th>
      </tr>
      </thead>
      <tbody>
      {
        pipe(planningItems,
          A.filterMap(item =>
            pipe(item.projectRef, O.fromNullable, O.map(project =>
              <ProjectDetailsRow key={`projectdetails-${item.id}`}
                                 project={project}
                                 description={O.fromNullable(item.description)}
              />
            ))
          )
        )
      }
      </tbody>
    </Table>
  </Card>
);

type ProjectDetailsProps = {
  project: ProjectGroupRef
  description: O.Option<string>
}

const ProjectDetailsRow: React.FC<ProjectDetailsProps> = ({ project, description }) => (
  <>
    <tr role="row">
      <td role="cell" className="projectGroupNr text-truncate" style={{ width: "15%" }}>
        { formatGroupId(project.groupId) }
      </td>
      <td role="cell" className="company text-truncate" style={{ width: "20%" }}>
        { project.company }
      </td>
      <td role="cell" className="location text-truncate" style={{ width: "20%" }}>
        { project.location }
      </td>
      <td role="cell" className="description text-truncate" style={{ width: "45%" }}>
        { project.description }
      </td>
    </tr>
    {
      pipe(description, O.fold(
        () => <></>,
        (d) =>
          <tr role="row" className='fst-italic text-primary'>
            <td colSpan={4} role="cell" className="employeeRef">{ d }</td>
          </tr>
      ))
    }
  </>
);

export const PlanningByActivity: React.FC<{ activity: Departement, dailyPlanning: DailyPlanning }> = ({ activity, dailyPlanning }) => {
  switch (activity) {
    case "Montage":
      return pipe(dailyPlanning,
        (dailyPlanning) => groupByProject(dailyPlanning, activity),
        O.map(grouped => (
          <>
            {
              pipe(
                grouped,
                A.map(([key, groupedByProject]) => (
                  <PlanningByProject key={`projectplanning-${key}`}
                                     planningItems={pipe(groupedByProject, A.sort(ordItem))}
                                     projectRef={pipe(A.head(groupedByProject), O.chain(item => O.fromNullable(item.projectRef)), O.toUndefined)}
                                     description={pipe(groupedByProject, allDescriptions)}
                  />
                ))
              )
            }
          </>
        )),
        O.getOrElse(() => <EmptyPlanning />)
      );
    case "Transport":
      return pipe(dailyPlanning,
        (dailyPlanning) => groupByEmployeeStartTime(dailyPlanning, activity),
        O.map(grouped => (
          <>
            {
              pipe(
                grouped,
                A.map(({ start, employee, items }) => (
                  <PlanningByStartTime key={`projectplanning-${start}-${employee}`}
                                       start={start}
                                       employee={employee}
                                       planningItems={ pipe(items, NEA.sort(ordItem)) }
                  />
                ))
              )
            }
          </>
        )),
        O.getOrElse(() => <EmptyPlanning />)
      );
    default:
      return <></>;
  }
};

const DailyPlanningByActivity: React.FC = () => {
  const {date: dateUrlParam, activity: activityUrlParam} = useParams();
  const navigate = useNavigate();

  const [date, setDate] = useState<Moment>(parseDate(dateUrlParam));
  const [departement, setDepartement] = useState<Departement>(parseActivityWithFallback(activityUrlParam));
  const [dailyPlanning, setDailyPlanning] = useState<Service<DailyPlanning>>({status: 'init'});

  const loadReport = () => Planning.daily(date)(setDailyPlanning);
  useEffect(loadReport, [date]);

  const dateSelected = (value: Moment | string) => {
    if (typeof value === 'string') {
      console.error(`received invalid date ${value}`)
      // setEntriesData({ status: 'error', error: new Error(`invalid date selection: ${value}`)});
    } else if (date.format(dateFormat) !== value.format(dateFormat)) {
      setDate(value);
      navigate(dailyPlanningUrl(value, O.some(departement)));
    }
  };

  const activitySelected = (value: Departement) => {
    if (value !== departement) {
      setDepartement(value);
      navigate(dailyPlanningUrl(date, O.some(value)));
    }
  };

  return (
    <>
      <Helmet title="Dagplanning Activiteit" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Dagplanning <span
          className='text-muted'>{date.format('DD/MM/YYYY')} - { departement }</span></h1>

        <Card>
          <Card.Body>
            <Row>
              <Col md={3}>
                <DatePickerWithNavigation
                  input={true}
                  value={date}
                  initialValue={moment()}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={dateSelected}
                  closeOnSelect={true}
                  locale="nl-be"
                  inputProps={{
                    className: "form-control text-center",
                  }}
                />
              </Col>
              <Col md={6} className='text-center'>
                <ButtonGroup size="lg">
                  <Button variant="outline-danger"
                          active={departement === 'Transport'}
                          onClick={() => activitySelected('Transport')}>T</Button>
                  <Button variant="outline-dark"
                          active={departement === 'Montage'}
                          onClick={() => activitySelected('Montage')}>M</Button>
                </ButtonGroup>
              </Col>
              <Col md={3} className='ms-auto text-end'>
                <Button size={"lg"} variant="outline-primary" className="shadow-sm me-1"
                        disabled={dailyPlanning.status === 'loading'}
                        href={dailyPlanningUrl(date, O.some(departement), 'preview')}
                        target='_blank'>
                  <ExternalLink className="feather me-1"/> Print Preview
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ErrorHandlingTS service={dailyPlanning} onLoaded={(report) => (
          <PlanningByActivity dailyPlanning={report} activity={departement} />
        )} />
      </Container>
    </>
  );
}

export default DailyPlanningByActivity;
