import React from "react";
import {
  Button,
  Modal,
  Form, Row, Col,
} from "react-bootstrap";
import {Formik, Form as FormikForm, useFormikContext, FormikHelpers} from 'formik';
import * as Yup from "yup";
import util, {numberOrNull} from "../../utils/util";
import moment from "moment";
import "moment-duration-format";
import FormSubmitButton from "../components/form/FormSubmitButton";
import classNames from "classnames";
import SelectProjectFormik from "../components/form/SelectProjectFormik";
import SelectActivityTime from "../components/form/SelectActivityTime";
import SelectVehicleFormik from "../components/form/SelectVehicleFormik";
import {Activity, TimesheetEntry} from "../../../libs/api/time/api-model";
import {FormikValues} from "formik/dist/types";
import {pipe} from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import * as S from "fp-ts/string";
import TimeFormikField from "../components/form/TimeFormikField";

const showVehicleInput = (activity?: Activity): boolean =>
  pipe(activity,
    O.fromNullable,
    O.map(a => a.code),
    O.map(c => pipe(['M', 'T', 'LL'], A.elem(S.Eq)(c))),
    O.getOrElse(() => false)
  );

export interface PostCalculationControlProps {
  item: TimesheetEntry,
  onSave: (values: TimesheetEntry, formikHelpers: FormikHelpers<TimesheetEntry>) => void | Promise<any>;
  modalOpen: boolean,
  onModalHide: () => void
}

const nummer = () => Yup.number().typeError('geef een geldig cijfer').nullable(true);

const PostCalculationSchema = Yup.object().shape({
  activityRef: Yup.object().required().typeError('verplicht veld'),
  projectRef: Yup.object().required().typeError('verplicht veld'),
  km: nummer(),
  start: Yup.string().required('verplicht veld'),
  end: Yup.string().required('verplicht veld'),
  pause: Yup.string().required('verplicht veld')
});

const normalizeValues = (values: TimesheetEntry): TimesheetEntry => {
  let pause: string = util.isDefined(values) && util.isDefined(values.pause) ? util.duration(values.pause) : '00:00';
  let duration: string = util.isDefined(values) && util.isDefined(values.duration) ? util.duration(values.duration) : '00:00';
  return { ...values,
    pause: pause,
    duration: duration
  };
}
const deNormalizeValues = (values: TimesheetEntry): TimesheetEntry => {
  let pause: string = util.hoursToDuration(util.isDefined(values) && util.isDefined(values.pause) ? values.pause : 0);
  let duration: string = util.hoursToDuration(util.isDefined(values) && util.isDefined(values.duration) ? values.duration : 0);
  return { ...values,
    pause: pause,
    duration: duration,
    km: numberOrNull(values.km)
  };
}

export interface FormFieldProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
}

const FormField: React.FC<FormFieldProps> = (
  { label, name, required, ...props }
) => {
  const {
    values,
    errors,
    handleChange,
  } = useFormikContext<FormikValues>();

  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor={`input${name}`}>
        {required ? <strong>{label} *</strong> : label}
      </Form.Label>
      <Form.Control
        {...props}
        name={name}
        id={`input${name}`}
        value={values[name]}
        onChange={handleChange}
        isInvalid={!!errors[name]}
        className="mh-38"
      />
      {!!errors[name] && <Form.Control.Feedback type="invalid">{ util.byString(errors, name) }</Form.Control.Feedback>}
    </Form.Group>
  );
}

export interface FormFieldWrapperProps {
  label: string
  name: string
  required?: boolean
  children: React.ReactNode
}

const FormFieldWrapper: React.FC<FormFieldWrapperProps> = (
  { label, name, required, children }
) => {
  const { errors } = useFormikContext<FormikValues>();
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor={`input${name}`}>
        {required ? <strong>{label} *</strong> : label}
      </Form.Label>
      <Col>
        {children}
        {!!errors[name] && <Form.Control.Feedback type="invalid">{ util.byString(errors, name) }</Form.Control.Feedback>}
      </Col>
    </Form.Group>
  );
};

export interface DurationFieldProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
}

export interface DurationFieldValues {
  start: string
  end: string
  pause: string
}

const DurationField: React.FC<DurationFieldProps> = ({ label, name, required, disabled = false }) => {
  const {
    values: { start, end, pause },
    touched,
    setFieldValue,
  } = useFormikContext<DurationFieldValues>();

  React.useEffect(() => {
    if (
      start.trim() !== '' && moment.isDuration(moment.duration(start)) &&
      end.trim() !== '' && moment.isDuration(moment.duration(end)) &&
      (touched.start || touched.end || touched.pause)
    ) {
      setFieldValue(name, util.calculateDuration(end, start, pause));
    }
  }, [start, end, pause, touched.start, touched.end, touched.pause, setFieldValue, name]);

  return (
    <FormField label={label} name={name} required={required} disabled={disabled} />
  );
};

const PostCalculationControl: React.FC<PostCalculationControlProps> = (
  { modalOpen, onModalHide, onSave, item }
) => (
  <Modal
    scrollable={true}
    size="lg"
    show={modalOpen}
    onHide={onModalHide}>
    <Formik
      initialValues={normalizeValues(item)}
      onSubmit={(values, formikHelpers) => onSave(deNormalizeValues(values), formikHelpers)}
      validationSchema={PostCalculationSchema}
    >
      {({
          values,
          errors,
          handleChange,
      }) => (
        <FormikForm>
          <Modal.Header closeButton>Timesheet aanpassen</Modal.Header>
          <Modal.Body>
            <Row><pre>{JSON.stringify(errors)}</pre></Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-sm-right" htmlFor="input_activityRef">Activiteit</Form.Label>
                  <SelectActivityTime name="activityRef" useOptionValue={false} />
                </Form.Group>
              </Col>
              <Col md={2}>
                <FormFieldWrapper label="Begin" name="start">
                  <TimeFormikField name="start" />
                </FormFieldWrapper>
              </Col>
              <Col md={2}>
                <FormFieldWrapper label="Eind" name="end">
                  <TimeFormikField name="end" />
                </FormFieldWrapper>
              </Col>
              <Col md={2}>
                <FormFieldWrapper label="Pauze" name="pause">
                  <TimeFormikField name="pause" />
                </FormFieldWrapper>
              </Col>
              <Col md={2}>
                <DurationField label="Duurtijd" name="duration" disabled />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="text-sm-right" htmlFor="input_projectRef">Project</Form.Label>
                  <SelectProjectFormik name="projectRef"
                                       required={true}
                                       useOptionValue={false} />
                  <small className="text-muted">geef min 3 karakters om te zoeken</small>
                </Form.Group>
              </Col>
              <Col md={8}>
                <FormField label="Omschrijving" name="description" />
              </Col>
            </Row>
            <Row>
            </Row>
            { showVehicleInput(values.activityRef) &&
              <>
                <hr/>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="inputLicensePlate">Voertuig</Form.Label>
                      <SelectVehicleFormik name="licensePlate" useOptionValue={true} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Label htmlFor="inputKilometers">Kilometers</Form.Label>
                    <div className={classNames('input-group', 'mb-3', errors["km"] || errors["driver"] ? 'is-invalid' : '')}>
                      <Form.Control
                        name="km"
                        value={values.km}
                        onChange={handleChange}
                        isInvalid={!!errors["km"]}
                        className="mh-38"
                      />
                      <div className='input-group-text'>
                        <Form.Check
                          inline={true}
                          type="switch"
                          id="inputDriver"
                          name="driver"
                          label="Bestuurder"
                          checked={values.driver}
                          onChange={handleChange}
                          isInvalid={!!errors["driver"]}
                        />
                      </div>
                    </div>
                    <Form.Control.Feedback type="invalid">{errors["km"]} {errors["driver"]}</Form.Control.Feedback>
                  </Col>
                </Row>
              </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onModalHide}>Annuleren</Button>
            {' '}
            <FormSubmitButton />
          </Modal.Footer>
        </FormikForm>
        )}
    </Formik>
  </Modal>
);

export default PostCalculationControl;
