import React from "react";

import { Dropdown } from "react-bootstrap";

import {Paperclip, Settings} from "react-feather";
import useAuth from "../../hooks/useAuth";
import util from "../../utils/util";

const NavbarUser = () => {
  const { signOut, user } = useAuth();
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <span className="text-dark">{util.isDefined(user) && user.user}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.ItemText>
          <Paperclip size={18} className="align-middle me-2" title={util.isDefined(user) && util.isDefined(user.accessRights) && user.accessRights.join(", ")} />
          {util.isDefined(user) && util.isDefined(user.roles) && user.roles.join(", ")}
        </Dropdown.ItemText>
        <Dropdown.Divider />
        <Dropdown.Item className={'text-danger'} onClick={signOut}>Afmelden</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
