import React from "react";
import {
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import {Formik, Form as FormikForm} from 'formik';
import * as Yup from "yup";
import SelectRegistratie from "../../components/form/SelectRegistratie";
import FormSubmitButton from "../../components/form/FormSubmitButton";
import {FormikHelpers} from "formik/dist/types";
import {CalendarEntry} from "../../../../libs/api/time/api-model";

type DailyActivityControlProps = {
  isOpen: boolean,
  onHide: () => void,
  onSave: (values: CalendarEntry, formikHelpers: FormikHelpers<CalendarEntry>) => void,
  initialFormValues: CalendarEntry
}

const DailyActivitySchema = Yup.object().shape({
  registratie: Yup.object().required(),
});

const DailyActivityControl: React.FC<DailyActivityControlProps> = (
  { isOpen, onHide, onSave, initialFormValues }
) => (
  <Modal
    scrollable={true}
    size="sm"
    show={isOpen}
    onHide={onHide}>
    <Formik
      initialValues={initialFormValues}
      onSubmit={onSave}
      validationSchema={DailyActivitySchema}
    >
      {({
          values,
          errors,
          setFieldValue
        }) => (
        <FormikForm>
          <Modal.Header closeButton>
            <h4>{`Activiteit op ${values.date}`}</h4>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <SelectRegistratie value={values.registratie?.code}
                                 isInvalid={!!errors['registratie']}
                                 onChange={(codeOrRegistratieOrUndefined) => setFieldValue('registratie', codeOrRegistratieOrUndefined)}
              />
              <Form.Control.Feedback type="invalid">{errors["registratie"]}</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onHide}>Annuleren</Button>
            {' '}
            <FormSubmitButton />
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  </Modal>
);

export default DailyActivityControl;
