import React from "react";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Edit, Trash} from "react-feather";
import moment from "moment/moment";
import util from "../../../utils/util";

const EditCost = ({value, handleEditCost}) =>
  <Button variant="link" size={"sm"} className="p-0" onClick={_ => handleEditCost(value)}>
    <Edit className="me-1" size={18} />
  </Button>

const DeleteCost = ({value, handleDeleteCost}) =>
  <Button variant="link" size="sm" className="text-danger p-0" onClick={_ => handleDeleteCost(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const CostActions = ({value, handleEditCost, handleDeleteCost}) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <EditCost value={value} handleEditCost={handleEditCost} />
      <DeleteCost value={value} handleDeleteCost={handleDeleteCost} />
    </ButtonGroup>
  </ButtonToolbar>

const costColumns = (editCost, deleteCost) => [
  {
    Header: "Datum",
    accessor: "costDate",
    className: "text-end",
    width: "10%",
    Cell: ({ value }) => moment(value).format('DD/MM/yyyy')
  },
  {
    Header: "Type",
    accessor: "costType.name",
    width: "10%",
  },
  {
    Header: "Referentie",
    accessor: "reference",
    width: "10%",
  },
  {
    Header: "Omschrijving",
    accessor: "description",
  },
  {
    Header: "Bedrag",
    id: "amount",
    className: "text-end",
    width: "12%",
    accessor: (row) => util.formatDecimal(row.amount),
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    accessor: 'id',
    width: "10%",
    Cell: ({value}) => <CostActions value={value} handleEditCost={editCost} handleDeleteCost={deleteCost} />
  }
];

export { costColumns };
