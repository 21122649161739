import * as React from "react";
import {useEffect, useState} from "react";
import {Link, Params, useParams} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {ChevronLeft} from "react-feather";
import {
  Badge,
  Card,
  Col,
  Container,
  ListGroup,
  Row, Spinner,
  Tab
} from "react-bootstrap";
import VehicleInfo from "./VehicleInfo";
import {FormikHelpers, FormikValues} from "formik/dist/types";
import DalService from "../../services/DalService";
import {getOrElse, mapService, Service, toOption} from "../../services/Service";
import VehicleAccountingInfo from "./VehicleAccountingInfo";
import VehicleControlInfo from "./VehicleControlInfo";
import VehicleLicenseInfo from "./VehicleLicenseInfo";
import VehicleCosts from "./costs/VehicleCosts";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import {Vehicle as VehicleModel} from "../../../libs/api/dal/api-model";
import {Vehicles as TimeVehicles} from "../../services/TimeApi";
import {Vehicles as DALVehicles} from "../../services/DalServiceTS";
import ConfirmationDialog from "../components/ConfirmationDialog";
import {pipe} from "fp-ts/function";
import {option} from "fp-ts";
import {isActive, isDeleted} from "../../model/api-model-operators";
const VehicleRefuels = React.lazy(() => import("./refuels/VehicleRefuels"));

const labelColWidth = 4;
const valueColWidth = 8;

const EmptyAccountingInfo = {
  "tankcard": "",
  "aovkbel": "",
  "polis": "",
  "insuranceCost": null, // 0
  "insuranceDate": null, //1676246400000,
  "fiscPk": null,
  "fiscPkTax": null, // 0
  "co2Tax": null, // 0
  "taxDate": null, //1676160000000,
  "kmCost": undefined, // 0
  "co2": null, // 0
}

const Navigation: React.FC<{ isActive: boolean, onDelete: React.MouseEventHandler, onActivate: React.MouseEventHandler }> =
  ({ isActive, onDelete, onActivate }) => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">
        <h5>Eigenschappen</h5>
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item href="#general" action>
        Algemeen
      </ListGroup.Item>
      <ListGroup.Item href="#accounting" action>
        Boekhouding
      </ListGroup.Item>
      <ListGroup.Item href="#technical" action>
        Keuring & Onderhoud
      </ListGroup.Item>
      <ListGroup.Item href="#licensing" action>
        Licenties
      </ListGroup.Item>
      <ListGroup.Item href="#transactions" action>
        Tankbeurten
      </ListGroup.Item>
      <ListGroup.Item href="#costs" action>
        Kosten
      </ListGroup.Item>
      {
        isActive ?
          <ListGroup.Item action variant="danger" onClick={onDelete}>
              Deactiveren{" "}
          </ListGroup.Item> :
          <ListGroup.Item action variant="success" onClick={onActivate}>
            Opnieuw activeren{" "}
          </ListGroup.Item>
      }
    </ListGroup>
  </Card>
);

const TitleInfo: React.FC<{ vehicle: VehicleModel }> = ({vehicle}) =>
  <> | {vehicle.name}{isDeleted(vehicle) && <Badge bg='danger' className="ms-2">niet actief</Badge>}</>

export interface Props {
  router: Params
}

export interface CreateVehicleResponse {
  licensePlate: string
}

const Vehicle: React.FC<Props> = props => {
  // const navigate = useNavigate();
  const { vehicleId } = useParams();

  const [vehicleData, setVehicleData] = useState<Service<VehicleModel>>({
    status: 'loading'
  });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showActivateModal, setShowActivateModal] = useState<boolean>(false);
  const [deleteState, setDeleteState] = useState<Service<string>>({ status: 'init' });
  const [activateState, setActivateState] = useState<Service<string>>({ status: 'init' });

  const loadVehicle = () => vehicleId !== undefined ? DALVehicles.get(vehicleId)(setVehicleData) : console.debug("vehicle id is empty");

  useEffect(loadVehicle, [ vehicleId ]);

  const saveVehicle = (vehicle: {}, formikBag: FormikHelpers<FormikValues>) =>
    DalService.updateVehicle(vehicle,
      (response: any) => {
        formikBag.setFieldError("submit", undefined);
        formikBag.setFieldTouched("submit", true);
      }, (error: any) => {
        formikBag.setFieldError("submit", error.toString());
        formikBag.setSubmitting(false);
      }
    )

  const deleteVehicle = () => pipe(vehicleId,
    option.fromNullable,
    option.map(vehicleId => {
      setDeleteState({status: 'loading'});
      return TimeVehicles.delete(vehicleId)(response => {
        setDeleteState(response);
        if (response.status === 'loaded') {
          setShowDeleteModal(false);
          // navigate("/pages/material/vehicles");
          loadVehicle();
        }
      })
    }
    )
  );

  const activateVehicle = () => pipe(vehicleId,
    option.fromNullable,
    option.map(vehicleId => {
      setActivateState({status: 'loading'});
      return TimeVehicles.activate(vehicleId)(response => {
        setActivateState(response);
        if (response.status === 'loaded') {
          setShowActivateModal(false);
          loadVehicle();
        }
      })
      }
    )
  );

  const vehicleIsActive: boolean = pipe(vehicleData,
    toOption,
    option.map(v => isActive(v)),
    option.getOrElse(() => false)
  );

  return (
    <>
      <Helmet title={vehicleId} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          <Link to="/pages/material/vehicles" className="me-1 btn btn-link btn-lg" role='button'>
            <ChevronLeft className='align-text-bottom' size={24} />
          </Link>
          {vehicleId}
          { pipe(vehicleData,
              mapService(v => <TitleInfo vehicle={v}/>),
              getOrElse(<></>)
            )
          }
        </h1>
        <div className={"tab tab-vertical"}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="#general">
            <Row>
              <Col sm={4} md={3} xl={2}>
                <Navigation isActive={vehicleIsActive}
                            onDelete={() => setShowDeleteModal(true)}
                            onActivate={() => setShowActivateModal(true)} />
              </Col>
              <Col sm={8} md={9} xl={10}>
                <ErrorHandlingTS service={vehicleData} onLoaded={ (vehicle) => {
                  const formValues = { ...EmptyAccountingInfo, ...vehicle};
                  return (
                    <Tab.Content className="p-0">
                      <Tab.Pane eventKey="#general">
                        <VehicleInfo formState='update' vehicle={formValues} onSave={saveVehicle} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="#accounting">
                        <VehicleAccountingInfo vehicle={formValues} onSave={saveVehicle} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="#technical">
                        <VehicleControlInfo vehicle={formValues} onSave={saveVehicle} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="#licensing">
                        <VehicleLicenseInfo vehicle={formValues} onSave={saveVehicle} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="#transactions">
                        <React.Suspense fallback={<Spinner animation={"grow"} color="dark" />}>
                          <ErrorHandlingTS service={vehicleData} onLoaded={(vehicle: VehicleModel) =>
                            <VehicleRefuels licensePlate={vehicle.licensePlate} />
                          } />
                        </React.Suspense>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#costs">
                        <React.Suspense fallback={<Spinner animation={"grow"} color="dark" />}>
                          <VehicleCosts vehicleId={vehicleId} />
                        </React.Suspense>
                      </Tab.Pane>
                    </Tab.Content>
                  );
                } } />
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <ConfirmationDialog title='Voertuig deactiveren'
                            open={showDeleteModal}
                            loading={deleteState.status === 'loading'}
                            error={deleteState.status === 'error'}
                            onCancel={() => setShowDeleteModal(false)}
                            onConfirm={deleteVehicle}>
          <p>Ben je zeker dat je dit voertuig op non-actief wil zetten?</p>
        </ConfirmationDialog>
        <ConfirmationDialog title='Voertuig opnieuw activeren'
                            open={showActivateModal}
                            loading={activateState.status === 'loading'}
                            error={activateState.status === 'error'}
                            onCancel={() => setShowActivateModal(false)}
                            onConfirm={activateVehicle}>
          <p>Ben je zeker dat je dit voertuig opnieuw wil activeren?</p>
        </ConfirmationDialog>
      </Container>
    </>
  );
};

export default Vehicle;
