import React from "react";
import {
  Button,
  Modal,
  Form
} from "react-bootstrap";
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup";
import moment from "moment";
import SelectEmployeeFormik from "../../components/form/SelectEmployeeFormik";
import util from "../../../utils/util";
import {Moment} from "moment/moment";
import {FormikHelpers} from "formik/dist/types";
import FormikDateField from "../../components/FormikDateField";
import FormSubmitButton from "../../components/form/FormSubmitButton";

export interface AttendanceForm {
  date: Moment;
  employeeId?: number
}

const YupValidationSchema = Yup.object().shape({
  date: Yup.date().required(),
  employeeId: Yup.number().required(),
});

type AttendanceControlProps = {
  title: string
  isOpen: boolean
  onHide?: () => void
  onSave: (values: AttendanceForm, formikHelpers: FormikHelpers<AttendanceForm>) => void | Promise<any>;
}

const AttendanceControl: React.FC<AttendanceControlProps> = (
  { title, isOpen, onHide, onSave }
) => (
  <Modal
    scrollable={true}
    size="sm"
    show={isOpen}
    onHide={onHide}>
    <Formik
      initialValues={ { date: moment() } as AttendanceForm }
      onSubmit={onSave}
      validationSchema={YupValidationSchema}
    >
      {({
          errors,
          touched,
        }) => (
        <FormikForm>
          <Modal.Header closeButton>{title}</Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label><strong>Deelnemer</strong></Form.Label>
              <SelectEmployeeFormik
                name='employeeId'
                idType='id'
                useOptionValue={true}
                placeholder="Kies een deelnemer..."
              />
              {!!touched.employeeId && (
                <Form.Control.Feedback type="invalid">{ util.byString(errors, 'employeeId') }</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label><strong>Datum</strong></Form.Label>
              <FormikDateField name="date"
                               input={false} />
              {!!touched.date && (
                <Form.Control.Feedback type="invalid">{ util.byString(errors, 'date') }</Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onHide}>Annuleren</Button>
            {' '}
            <FormSubmitButton />
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  </Modal>
);

export default AttendanceControl;
