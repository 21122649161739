import ReactPDF, {Font, StyleSheet} from "@react-pdf/renderer";
// @ts-ignore
import Poppins from '../../assets/font/Poppins-Regular.ttf';

Font.register({
  family: 'Poppins',
  format: "truetype",
  src: Poppins
});

const borderColorLight = '#e9ecef';
const borderColorDark = '#6c757d';
const contentColor= '#444';

export const columnBorderColor = borderColorLight;

const headerHeight = 19;
const rowHeight = 19;
const footerHeight = 19;

// const image = {
//   position: 'absolute',
//   left: 24,
//   top: 24,
// };

const styles: ReactPDF.Styles = StyleSheet.create({
  // image: image,
  page: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 35,
    paddingTop: 48,
    paddingBottom: 60,
  },
  header: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 5,
    borderColor: borderColorDark,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: borderColorLight,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  title: {
    fontSize: 20,
    textAlign: 'left',
    fontFamily: 'Poppins',
    letterSpacing: '-0.5px'
  },
  subtitle: {
    fontSize: 12,
    textAlign: 'left',
    fontFamily: 'Poppins',
    letterSpacing: '-0.5px'
  },
  tabletitle: {
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'Poppins',
    letterSpacing: '-0.5px',
    color: contentColor,
    borderColor: borderColorDark,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    paddingHorizontal: 5,
  },
  section: {
  },
  tableContainer: {
    marginTop: 24,
    marginBottom: 40,
  },
  tableHeader: {
    flexDirection: 'row',
    borderColor: borderColorDark,
    borderBottomColor: borderColorDark,
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderTopLeftRadius: 3,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopRightRadius: 3,
    alignItems: 'center',
    height: headerHeight,
    textAlign: 'center',
    fontStyle: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: borderColorLight,
    borderBottomWidth: 0,
    borderLeftColor: borderColorDark,
    borderRightColor: borderColorDark,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    height: rowHeight,
  },
  tableFooter: {
    flexDirection: 'row',
    borderColor: borderColorDark,
    borderWidth: 1,
    borderBottomColor: borderColorDark,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,

    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    height: footerHeight,
    textAlign: 'center',
  },
  column: {
    fontSize: 9,
    fontWeight: 200,
    paddingHorizontal: 5,
    paddingVertical: 3,
    borderBottomColor: borderColorLight,
    borderBottomWidth: 1,
    color: contentColor
  },
  columnHeader: {
    fontSize: 9,
    fontWeight: 'bold',
    paddingHorizontal: 5,
    paddingVertical: 3,
    borderBottomWidth: 0,
  },
  columnFooter: {
    fontSize: 9,
    fontWeight: 'bold',
    paddingHorizontal: 5,
    paddingVertical: 3,
    borderBottomWidth: 0,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 35,
    left: 0,
    right: 35,
    textAlign: 'right',
    color: 'grey',
  },
  date: {
    position: 'absolute',
    fontSize: 9,
    bottom: 35,
    left: 35,
    textAlign: 'left',
    color: 'grey',
  }
});

export default styles;
