import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, ListGroup, Row, Tab} from "react-bootstrap";
import {ChevronLeft} from "react-feather";
import {Service} from "../../services/Service";
import {Employees as EmployeesApi} from "../../services/TimeApi";
import {CreateEmployeeRequest} from "../../../libs/api/time/api-model";
import {Link, useNavigate} from "react-router-dom";
import {FormikHelpers} from "formik";
import formikHandler from "../../services/FormikApiHandler";
import CreateEmployeeControl from "./CreateEmployeeControl";

const labelColWidth = 3;
const valueColWidth = 9;

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">
        <h5>Eigenschappen</h5>
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item href="#general" action disabled>
        Personalia
      </ListGroup.Item>
      <ListGroup.Item href="#contact" action disabled>
        Contact
      </ListGroup.Item>
      <ListGroup.Item href="#settings" action disabled>
        Instellingen
      </ListGroup.Item>
      <ListGroup.Item href="#educations" action disabled>
        Opleidingen
      </ListGroup.Item>
    </ListGroup>
  </Card>
);

const createEmployeeRequest = () => (
  {
    firstname: "",
    lastname: "",
    employeeType: "Internal",
    company: {
      "id": -1,
      "name": "",
      "code": "",
      "deleted": undefined,
      "oldId": 0
    },
    roles: [],
    calendarId: 0
  } as CreateEmployeeRequest
)

const CreateEmployee: React.FC = () => {
  const navigate = useNavigate();

  const saveEmployee = (data: CreateEmployeeRequest, formikHelpers: FormikHelpers<CreateEmployeeRequest>) => {
    EmployeesApi.create(data)(handleCreateResponse(formikHelpers));
  }

  const handleCreateResponse = (formikHelpers: FormikHelpers<CreateEmployeeRequest>) => (result: Service<string>) => {
    const employeeId = formikHandler<CreateEmployeeRequest, string>(formikHelpers)(result);
    if (employeeId) {
      navigate(`/pages/time/employee/${employeeId}`)
    }
    return employeeId;
  }

  const emptyEmployee = createEmployeeRequest();

  return (
    <>
      <Helmet title='Nieuwe Werknemer' />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          <Link to="/pages/time/employees" className="me-1 btn btn-link btn-lg" role='button'>
            <ChevronLeft className='align-text-bottom' size={24} />
          </Link>
          Nieuwe werknemer
        </h1>
        <Tab.Container id="employee-tabs" defaultActiveKey="#general">
          <Row>
            <Col sm={4} md={3} xl={3}>
              <Navigation />
            </Col>
            <Col sm={8} md={9} xl={9}>
              <Tab.Content className="p-0">
                <Tab.Pane eventKey="#general">
                  <CreateEmployeeControl employee={emptyEmployee} onSave={saveEmployee} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default CreateEmployee;
