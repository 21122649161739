import React from "react";
import * as Yup from "yup";
import {
  Formik,
  FormikHelpers,
  Form as FormikForm,
  useField,
} from "formik";
import {
  Card,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import {Employee} from "../../../libs/api/time/api-model";
import {UploadEmployeeImage} from "./components/UploadEmployeeImage";
import FormSubmitButton from "../components/form/FormSubmitButton";
import {FieldHookConfig} from "formik/dist/Field";
import SelectRolesFormik from "../components/form/SelectRolesFormik";

export interface PublicInfoProps {
  employee: Employee,
  labelColWidth: number,
  valueColWidth: number,
  onSave: (values: Employee, formikHelpers: FormikHelpers<Employee>) => void | Promise<any>;
}

const schema = Yup.object().shape({
  personalia: Yup.object().shape({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
  }),
});

const isCreating = (employee: Employee) => employee.id === 0;

export interface FormFieldProps<Val> {
  label: string
  props: string | FieldHookConfig<Val>
  required?: boolean
}

type UseFormFieldProps<P = any> = React.FC<FormFieldProps<P>>;

const PublicInfo: React.FC<PublicInfoProps> = ({employee, onSave, labelColWidth, valueColWidth}) => {

  const FormField: UseFormFieldProps = ({ label, required,...props }) => {
    const [field, meta] = useField(props.props);
    return (
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor={`input${field.name}`}>
          {required ? <strong>{label} *</strong> : label}
        </Form.Label>
        <Col sm={valueColWidth}>
          <Form.Control {...field} {...props} isInvalid={!!(meta.error)} className="mh-38" />
          {meta.error ? (
            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
          ) : null}
        </Col>
      </Form.Group>
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">
          <h5>Algemeen</h5>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          onSubmit={onSave}
          initialValues={employee}
        >
          {({
              handleSubmit,
            }) => (
            <FormikForm noValidate onSubmit={handleSubmit}>
              <Row>
                <Col md={8}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right">
                      Personeelsnummer
                    </Form.Label>
                    <Col sm={valueColWidth}>
                      <Form.Control readOnly={true} value={employee.employeeNr} className="mh-38" />
                    </Col>
                  </Form.Group>
                  <FormField label="Voornaam" required props='personalia.firstname' />
                  <FormField label="Achternaam" required props="personalia.lastname" />
                  <FormField label="Initialen" props="personalia.initials" />
                  <FormField label="Functie" props="personalia.function" />
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="input_roles_select">Taken</Form.Label>
                    <Col sm={valueColWidth}>
                      <SelectRolesFormik name="roles" />
                    </Col>
                  </Form.Group>
                  <FormField label="Memo" props="memo" />
                </Col>
                <Col md="4">
                  <UploadEmployeeImage employeeId={employee.employeeNr} disabled={isCreating(employee)} />
                </Col>
              </Row>
              <FormSubmitButton />
            </FormikForm>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default PublicInfo;
