import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import classNames from "classnames";
import {Planning} from "../../../services/TimeApi";
import {Service, toOption} from "../../../services/Service";
import {DailyPlanningVehicle} from "../../../../libs/api/time/api-model";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import {useFormikContext} from "formik";
import {FormikValues} from "formik/dist/types";
import util from "../../../utils/util";
import {Form} from "react-bootstrap";

export interface SelectVehicleFormikProps {
  name: string
  required?: boolean
  useOptionValue?: boolean // geef de value van de option door bij selectie, of de hele option
}

type SelectOption = {
  label: string,
  value: string
}

const toSelectOption = (entry: DailyPlanningVehicle) => (
  { value: entry.licensePlate, label: `${entry.licensePlate}${entry.name ? ' | ' + entry.name : ''}` } as SelectOption
);
const emptyOption: SelectOption = { value: "", label: "Maak een keuze" };

export const SelectVehicleFormik: React.FC<SelectVehicleFormikProps> = (
  { name, required, useOptionValue = false, ...props }
) => {

  const [entities, setEntities] = useState<Service<DailyPlanningVehicle[]>>({ status: 'init' });
  const loadEntries = () => Planning.vehicles(setEntities);
  useEffect(loadEntries, []);

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const selectOptions: SelectOption[] =
    pipe(entities,
      s => toOption(s),
      option.map(array.map(toSelectOption)),
      option.getOrElse(() => [] as SelectOption[]),
    );

  const findOption = pipe(entities,
    s => toOption(s),
    option.chain(
      array.findFirst(o =>
        pipe(util.byString(values, name),
          option.fromNullable,
          option.exists((t: any) =>
            useOptionValue ?
              String(t) === o.licensePlate :
              (t as DailyPlanningVehicle).licensePlate === o.licensePlate
          )
        )
      )
    ),
    option.map(toSelectOption),
    option.getOrElse(() => emptyOption)
  );

  const isInvalid= !!util.byString(errors, name);
  const hasDataError = entities.status === "error";

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid || hasDataError ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={selectOptions}
        isLoading={entities.status === "loading"}
        id={`input_${name}`}
        onChange={option => setFieldValue(name, useOptionValue ? option?.value : option)}
        onBlur={handleBlur}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      {
        entities.status === "error" && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van voertuigen: ${entities.error ? entities.error.message : '?'}`}</Form.Control.Feedback>
      }
      {!!util.byString(errors, name) && <Form.Control.Feedback type="invalid">{util.byString(errors, name)}</Form.Control.Feedback>}
    </>
  );
}

export default SelectVehicleFormik;
