import React, {useRef} from "react";
import DateTime, {DatetimepickerProps} from "react-datetime";
import {Button, FormControl, InputGroup} from "react-bootstrap";
import moment, {Moment} from "moment";
import {ChevronLeft, ChevronRight} from "react-feather";

type DatePickerWithNavigationProps = DatetimepickerProps & {
  onScroll?: (amount: number) => (value: Moment) => Moment
}

const DatePickerWithNavigation: React.FC<DatePickerWithNavigationProps> = (props: DatePickerWithNavigationProps) => {
  const dateTimeRef = useRef<any>(null);

  const navigateWithFallback = props.onScroll !== undefined ?
    props.onScroll :
    (amount: number) => (value: Moment) => value.subtract(amount, "days")

  const navigate = (days: number)  => {
    try {
      if (dateTimeRef.current !== null) {
        const oldValue = dateTimeRef.current ? dateTimeRef.current.state.viewDate : undefined;
        if (oldValue && moment.isMoment(oldValue)) {
          const newValue = navigateWithFallback(days)(oldValue);
          dateTimeRef.current.props.onChange(newValue);
        }
      }
    } catch (e) {
      console.error(`Error navigating date picker: ${e}`, e);
    }
  }

  return (
    <DateTime ref={dateTimeRef}
      renderInput={(props, openCalendar, closeCalendar) =>
        <InputGroup className='mh-38'>
          <Button className='mh-38' variant="outline-secondary" onClick={() => navigate(1)}><ChevronLeft size={14} /></Button>
          <FormControl role='button' {...props} />
          <Button className='mh-38' variant="outline-secondary" onClick={() => navigate(-1)}><ChevronRight size={14} /></Button>
        </InputGroup>
      }
      {...props}
    />
  );
}

export default DatePickerWithNavigation;
