import React from "react";
import {
  Button,
  Modal,
  Form
} from "react-bootstrap";
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup";
import moment, {Moment} from "moment";
import util from "../../../utils/util";
import SelectEducationFormik from "../../components/form/SelectEducationFormik";
import {FormikHelpers} from "formik/dist/types";
import FormSubmitButton from "../../components/form/FormSubmitButton";
import FormikDateField from "../../components/FormikDateField";

export interface AttendanceForm {
  date: Moment;
  educationId: string
}

const YupValidationSchema = Yup.object().shape({
  date: Yup.date().required(),
  educationId: Yup.string().required(),
});

type AttendanceControlProps = {
  title: string
  isOpen: boolean
  onHide?: () => void
  onSave: (values: AttendanceForm, formikHelpers: FormikHelpers<AttendanceForm>) => void | Promise<any>;
}

const AttendanceControl: React.FC<AttendanceControlProps> = (
  { title, isOpen, onHide, onSave }
) => (
  <Modal
    scrollable={true}
    size="sm"
    show={isOpen}
    onHide={onHide}>
    <Formik
      initialValues={ { date: moment(), educationId: '' } as AttendanceForm }
      onSubmit={ onSave }
      validationSchema={ YupValidationSchema }
    >
      {({
          errors,
          touched,
        }) => (
        <FormikForm>
          <Modal.Header closeButton>{title}</Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label><strong>Opleiding</strong></Form.Label>
              <SelectEducationFormik name="educationId" useOptionValue={true} required={true} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label><strong>Datum</strong></Form.Label>
              <FormikDateField name="date" input={false} />
              {!!touched.date && (
                <Form.Control.Feedback type="invalid">{ util.byString(errors, 'date') }</Form.Control.Feedback>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onHide}>Annuleren</Button>
            {' '}
            <FormSubmitButton />
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  </Modal>
);

export default AttendanceControl;
