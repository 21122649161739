import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Moment} from "moment";
import 'moment/locale/nl-be';
import {useParams} from "react-router-dom";
import {Service} from "../../../services/Service";
import {
  DailyPlanning
} from "../../../../libs/api/time/api-model";
import {Planning} from "../../../services/TimeApi";
import ErrorHandlingTS from "../../components/ErrorHandlingTS";
import {Departement} from "../../postcalc/daily/PostCalculationByDay";
import {
  parseActivityWithFallback,
  parseDate, PlanningByActivity
} from "./DailyPlanningByActivity";

const DailyPlanningByActivityPreview: React.FC = () => {
  const {date: dateUrlParam, activity: activityUrlParam} = useParams();

  const [date] = useState<Moment>(parseDate(dateUrlParam));
  const [departement] = useState<Departement>(parseActivityWithFallback(activityUrlParam));
  const [dailyPlanning, setDailyPlanning] = useState<Service<DailyPlanning>>({status: 'init'});

  const loadReport = () => Planning.daily(date)(setDailyPlanning);
  useEffect(loadReport, [date]);

  return (
    <>
      <Helmet title={`Dagplanning ${departement} `}/>
      <Container fluid className="p-0">
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">
              <Row>
                <Col xs="auto" className="d-none d-sm-block">
                  <h1 className="h2 m-0">Dagplanning {departement}</h1>
                </Col>
                <Col xs="auto" className="ms-auto text-end m-0">
                  <h3 className='text-capitalize m-0'>{date.format('dddd DD MMMM')}</h3>
                </Col>
              </Row>
            </Card.Title>
          </Card.Header>
        </Card>
        <ErrorHandlingTS service={dailyPlanning} onLoaded={(report) => (
          <PlanningByActivity dailyPlanning={report} activity={departement} />
        )}/>
      </Container>
    </>
  );
}

export default DailyPlanningByActivityPreview;
