import React from "react";
import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import {FormikValues} from "formik/dist/types";
import InputMask, {Props as ReactInputMaskProps} from "react-input-mask";

export interface TimeFormikFieldProps {
  name: string
}

export const TimeFormikField: React.FC<TimeFormikFieldProps> =
  ({
     name, ...props
  }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormikContext<FormikValues>();
  return (
    <InputMask mask="99:99"
               className="mh-38"
               value={values[name]}
               id={`input${name}`}
               name={name}
               onChange={handleChange}
               onBlur={handleBlur}
               isInvalid={!!touched[name] && !!errors[name]}>
      { (inputProps: ReactInputMaskProps) => <Form.Control {...inputProps} isInvalid={!!errors[name]} type="text"/>}
    </InputMask>
  );
}

export default TimeFormikField;
