import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Row} from "react-bootstrap";
import DateRangePicker from "../components/DateRangePicker";
import PdfDownload from "../components/PdfDownload";
import AnalyticFleetReportDocument from "./AnalyticFleetReportDocument";
import {fleetCostColums} from "./columns";
import {fleetCostPDFColums} from "./pdf-columns";
import {DateRange, equalDates, parseRange, toShortFormat, toUriQuery} from "../../model/Common";
import util from "../../utils/util";
import SimpleTable from "../../pages/components/SimpleTable";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Service} from "../../services/Service";
import {Reports} from "../../services/TimeApi";
import ErrorHandlingTS, {MapServiceElement} from "../../pages/components/ErrorHandlingTS";
import EmptyResult from "../components/EmptyResult";
import {AnalyticFleetCostReport} from "../../../libs/api/time/api-model";

const AnalyticFleetReport: React.FC = () => {

  const navigate = useNavigate();
  const {from, to} = useParams();
  const [period, setPeriod] = useState<DateRange>(parseRange(from, to));
  const [fleetCost, setFleetCost] = useState<Service<AnalyticFleetCostReport>>({
    status: 'loading'
  });

  const loadReport = () => Reports.fleetAnalytic(period)(setFleetCost);

  useEffect(() => navigate(`/reports/fleet/analytic/${toUriQuery(period)}`), [ navigate, period ]);
  useEffect(loadReport, [ period ]);

  const onDateSet: (_: DateRange) => void = (range: DateRange) => {
    if (!equalDates(period, range)) {
      setPeriod(range)
    }
  }

  return (
    <>
      <Helmet title="Analytische Fleet Kost" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Analytische Fleet Kost</h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <DateRangePicker onSuccess={onDateSet} initialRange={period} />
              </Col>
              <Col md={8} className='text-end'>
                <MapServiceElement service={fleetCost} onLoaded={(data: AnalyticFleetCostReport) =>
                  <PdfDownload dataLoading={fleetCost.status === 'loading'}
                               document={<AnalyticFleetReportDocument data={data.entries} columns={fleetCostPDFColums} period={period} />}
                               filename={`AnalytischeFleetKost-${toShortFormat(period)}.pdf`}
                               label={`AnalytischeFleetKost.pdf`}
                  />
                }
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ErrorHandlingTS service={fleetCost} onLoaded={(data: AnalyticFleetCostReport) =>
          <>
          {util.isDefined(data) && util.isDefined(data.entries) && data.entries.length === 0 && <EmptyResult />}
          {util.isDefined(data) && util.isDefined(data.entries) && data.entries.map(({analyticCode, totalKmCost, totalFuelCost, totalPurchaseCost, totalPeriodCost, entries}) =>
            <Card key={analyticCode}>
              <Card.Header>
                <Card.Title className="mb-0">
                  <h4>{analyticCode}</h4>
                </Card.Title>
              </Card.Header>
              <SimpleTable bordered data={entries} columns={fleetCostColums} renderFooter={(rows: any, visibleColumnsLength: number) =>
                <tr>
                  <th scope="row" colSpan={visibleColumnsLength - 4}>Totaal</th>
                  <td className="text-end fw-bolder">{util.formatCurrency(totalKmCost)}</td>
                  <td className="text-end fw-bolder">{util.formatCurrency(totalFuelCost)}</td>
                  <td className="text-end fw-bolder">{util.formatCurrency(totalPurchaseCost)}</td>
                  <td className="text-end fw-bolder">{util.formatCurrency(totalPeriodCost)}</td>
                </tr>
              } />
            </Card>
          )}
          </>
          }
        />
      </Container>
    </>
  );
}
export default AnalyticFleetReport;
