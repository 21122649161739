import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item me-0">
              <a className="text-muted"
                    href="mailto:it@vaneycken.be">
                Support
              </a>
            </li>
            <li className="list-inline-item mx-3">{"|"}</li>
            <li className="list-inline-item">
              <a className="text-muted"
                 href="https://planning.vaneycken.be"
                 target="_blank"
                 rel="noopener noreferrer">
                Planning
              </a>
            </li>
            <li className="list-inline-item">{"·"}</li>
            <li className="list-inline-item">
              <a className="text-muted"
                    href="https://articles.vaneycken.be"
                    target="_blank"
                    rel="noopener noreferrer">
                Articles
              </a>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-end">
          <p className="mb-0">
            &copy; {"2022"} -{" "}
            <span href="https://www.tinkr.be" className="text-muted">
              Tinker
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
