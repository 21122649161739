import {Text, View} from "@react-pdf/renderer";
import styles from "./ReportStyles";
import util from "../../utils/util";
import React from "react";
import {ColumnDefinition} from "./PdfTypes";
import {Style} from "@react-pdf/types";

type TableHeaderProps = {
  columns: ColumnDefinition<any>[]
  style?: Style;
}

const TableHeader: React.FC<TableHeaderProps> = ({ columns, style = {} }) => (
  <View style={styles.tableHeader} fixed>
    {util.isDefined(columns) && columns.map((column, index) => {
      const { styles: columnDefStyle = {}  } = column;
      const lastColumnStyle = index === columns.length - 1 ? { borderRightWidth: 0 } : {};
      const combinedStyles = [ columnDefStyle, style, lastColumnStyle ];
      return (
        <Text key={`columnheader-${column.header.replaceAll(" ", "_").toLowerCase().trim()}`}
              style={combinedStyles}>
          { column.header }
        </Text>
      );
    })}
  </View>
);

export default TableHeader;
