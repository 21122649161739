import moment from "moment/moment";
import "moment-duration-format";

//const durationToHours = (d) => moment.utc(d.as('milliseconds')).format('HH:mm');
// function customTemplate() {
//   return this.duration.asSeconds() >= 86400 ? "d [d] hh:mm" : "hh:mm";
// }
function customTemplate() {
  return this.duration.asSeconds() >= 86400 ? "hh:mm" : "hh:mm";
}

const durationToHours = (d) => d.format(customTemplate, { trim: false });

const duration = (value, showZero = true) => {
  const d = moment.duration(value);
  return showZero ? durationToHours(d) : d.asSeconds() === 0 ? '' : durationToHours(d);
};

const durationDiff = (isoDurationOne, isoDurationTwo) => {
  const momentOne = moment.duration(isoDurationOne);
  const momentTwo = moment.duration(isoDurationTwo);
  const millisOne = momentOne.as('milliseconds')
  const millisTwo = momentTwo.as('milliseconds')
  const negative = millisOne > millisTwo
  const millisDiff = negative ? millisOne - millisTwo : millisTwo - millisOne;
  const momentDiff = moment.duration(millisDiff);

  return {
    negative: negative,
    momentDiff: momentDiff,
    millisDiff: millisDiff,
    formatted: util.durationToHours(momentDiff),
  };
};

const calculateDuration = (one, two, pause) => {
  const diff = moment.duration(one).subtract(moment.duration(two));
  const minPause = pause && moment.isDuration(moment.duration(pause)) ? diff.subtract(moment.duration(pause)) : diff.subtract(moment.duration(0));
  return minPause.format("hh:mm", {
    trim: false
  });
};

const hoursToDuration = (hhMM) => moment.duration(hhMM).toISOString();

const localDateTimeToHours = ({value}) => typeof value !== 'undefined' && value ? moment(value).format("HH:mm") : '';
const localDateTimeToFull = (value) => typeof value !== 'undefined' && value ? moment(value).format("DD/MM/yyyy HH:mm:ss") : '';
const localTimeToHours = ({value}) => typeof value !== 'undefined' && value ? moment(value, 'HH:mm:ss.SSSSS').format("HH:mm") : '';

const isDefined = (obj) => typeof obj !== 'undefined' && obj !== null && obj;

const currencyFormatter = new Intl.NumberFormat('nl-BE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const intFormatter = new Intl.NumberFormat('nl-BE', {
  maximumFractionDigits: 0,
});

const decimalFormatter = new Intl.NumberFormat('nl-BE', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const bigDecimalFormatter = new Intl.NumberFormat('nl-BE', {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

const formatDecimal = (value) =>
  (isDefined(value) && !isNaN(Number(value)) ? decimalFormatter.format(value) : value);

const formatBigDecimal = (value) =>
  (isDefined(value) && !isNaN(Number(value)) ? bigDecimalFormatter.format(value) : value);

const formatInt = (value) =>
  (isDefined(value) && !isNaN(Number(value)) ? intFormatter.format(value) : value);

const formatCurrency = (value) =>
  (isDefined(value) && !isNaN(Number(value)) ? currencyFormatter.format(value) : value);

const byString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (o !== undefined && k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const parseNumber = (str) => isDefined(str) ? parseFloat(str.replaceAll(',','.').replaceAll(' ','')) : NaN;
export const numberOrNull = (str) => typeof str === "number" && !Number.isNaN(str) ? str : typeof str === "string" && !Number.isNaN(parseNumber(str)) ? parseNumber(str) : null;
export const numberOrUndefined = (str) => typeof str === "number" && !Number.isNaN(str) ? str : typeof str === "string" && !Number.isNaN(parseNumber(str)) ? parseNumber(str) : undefined;

const util = {
  durationToHours, duration, durationDiff, hoursToDuration, calculateDuration,
  localDateTimeToHours, localTimeToHours, localDateTimeToFull, isDefined,
  formatDecimal, formatBigDecimal, formatInt, formatCurrency, byString,
};

export default util;
