import util from "../../utils/util";
import {MobilityPremiumEntry} from "../../../libs/api/time/api-model";
import {ColumnDefinition} from "../components/PdfTypes";
import {Style} from "@react-pdf/types";
import {columnBorderColor} from "../components/ReportStyles";

const columnStyle: (styles: Style) => Style = (styles) => {
  const defaults: Style = {
    borderRightColor: columnBorderColor,
    borderRightWidth: 1,
    textAlign: 'left',
  }
  return {
    ...defaults,
    ...styles
  };
}

const columns: ColumnDefinition<MobilityPremiumEntry>[] = [
  {
    header: "Datum",
    accessorPath: "date",
    styles: columnStyle({ width: '55%' }),
  },
  {
    header: "Kilometers",
    accessorPath: "km",
    styles: columnStyle({ width: '15%', textAlign: 'right' }),
    formatter: util.formatInt,
  },
  {
    header: "Bedrag",
    accessorPath: "premium",
    styles: columnStyle({ width: '15%', textAlign: 'right' }),
    formatter: util.formatCurrency,
  },
  {
    header: "Chauffeur",
    accessorFn: ({driver}) => driver ? 'Chauffeur' : 'Passagier',
    styles: columnStyle({ width: '15%' }),
  }
];

export { columns };
