import {EmployeeSummary} from "../../../../libs/api/time/api-model";
import React, {useEffect, useState} from "react";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import {useFormikContext} from "formik";
import {FormikValues} from "formik/dist/types";
import util, {numberOrNull} from "../../../utils/util";
import Select from "react-select";
import classNames from "classnames";
import {Form} from "react-bootstrap";
import {Service, toOption} from "../../../services/Service";
import {Employees} from "../../../services/TimeApi";
import {EmployeeFunctions} from "../../../libs/api/time/api-helpers";

export type EmployeeIdType = "code" | "id"

export interface SelectEmployeeFormikProps {
  name: string
  idType: EmployeeIdType
  required?: boolean
  useOptionValue?: boolean // geef de value van de option door bij selectie, of de hele option
}

type EmployeeOption = {
  value: string,
  label: string
}

const getId = (idType: EmployeeIdType) => (employee: EmployeeSummary) => {
  switch (idType) {
    case "code":
      return employee.employeeNr.toString();
    case "id":
      return employee.id.toString();
  }
}

const toEmployeeOption = (idType: EmployeeIdType) => (employee: EmployeeSummary) =>
  ({
    value: getId(idType)(employee),
    label: EmployeeFunctions.fullname(employee)
  } as EmployeeOption);


export const SelectEmployeeFormik: React.FC<SelectEmployeeFormikProps> = ({name, idType, required, useOptionValue = false, ...props}) => {

  const [employees, setEmployees] = useState<Service<EmployeeSummary[]>>({ status: 'init' });
  const loadEntries = () => Employees.summaries(true)(setEmployees);
  useEffect(loadEntries, []);

  const employeeOptions: EmployeeOption[] =
    pipe(employees,
      s => toOption(s),
      option.map(us => pipe(us, array.map(toEmployeeOption(idType)))),
      option.getOrElse(() => [] as EmployeeOption[]),
    );

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const isInvalid= !!util.byString(errors, name);
  const hasDataError = employees.status === "error";

  const findOption = pipe(employeeOptions,
    array.findFirst(o => {
      const originalValue = pipe(option.fromNullable(util.byString(values, name)),
        option.fold(
          () => '',
          (v) => v.toString()
        )
      );
      return o.value === originalValue;
    }),
    option.getOrElse(() => ({value: util.byString(values, name), label: util.byString(values, name)} as EmployeeOption))
  )

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={employeeOptions}
        isLoading={employees.status === "loading"}
        id={`input_${name}`}
        onChange={option => setFieldValue(name, useOptionValue ? numberOrNull(option?.value) : option)}
        onBlur={handleBlur}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      {
        hasDataError && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van werknemers: ${employees.error ? employees.error.message : '?'}`}</Form.Control.Feedback>
      }
      {!!util.byString(errors, name) && <Form.Control.Feedback type="invalid">{util.byString(errors, name)}</Form.Control.Feedback>}
    </>
  );
};

export default SelectEmployeeFormik;
