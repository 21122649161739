import React from "react";
import { useTable } from "react-table";

import {
  Table,
} from "react-bootstrap";

const SimpleTable = ({ columns, data, renderFooter, ...props }) => {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns} =
    useTable(
      {
        columns,
        data,
      }
    );

  return (
    <Table className="my-0" striped {...props} {...getTableProps()}>
      <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            // Add the sorting props to control sorting. For this example
            // we can add them into the header props
            <th {...column.getHeaderProps([{
                className: column.className,
                style: { minWidth: column.minWidth, width: column.width, ...column.styles },
              }])}>
              {column.render("Header")}
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps([{
                    className: cell.column.className,
                    style: { minWidth: cell.column.minWidth, width: cell.column.width, ...cell.column.styles },
                  }])}>{cell.render("Cell")}</td>
              );
            })}
          </tr>
        );
      })}
      {rows && rows.length === 0 &&
        <tr role='row'>
          <td role='cell' colSpan={headerGroups[0].headers.length}>
            <p className='fst-italic my-1'>Geen resultaten gevonden</p>
          </td>
        </tr>
      }
      </tbody>
      {typeof renderFooter !== 'undefined' && renderFooter &&
        <tfoot>
        {renderFooter(rows, visibleColumns.length)}
        </tfoot>
      }
    </Table>
  );
};

export default SimpleTable;
