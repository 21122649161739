import React from "react";
import {
  Button,
  Modal,
  Form, Row, Col,
} from "react-bootstrap";
import {Formik, Form as FormikForm, ErrorMessage} from 'formik';
import FormikDateField from "../../components/FormikDateField";
import SelectCostType from "./SelectCostType";
import * as Yup from "yup";
import FormSubmitButton from "../../components/form/FormSubmitButton";
import HorizontalColumnField from "../../components/form/HorizontalColumnField";
import {numberOrNull} from "../../../utils/util";
import moment from "moment/moment";

const labelColWidth = 4
const valueColWidth = 8

const AddCostSchema = Yup.object().shape({
  costDate: Yup.date().required(),
  reference: Yup.string().optional(),
  costType: Yup.object().required(),
  description: Yup.string().required(),
  amount: Yup.number().typeError('Enkel numerieke waarde toegestaan').required(),
});

const normalizeInitialValues = (values) => {
  let costDate = typeof values !== 'undefined' && values ? values.costDate : null;
  if (typeof costDate !== 'undefined' && costDate && (typeof costDate === "string" || costDate instanceof String)) {
    costDate = moment(costDate);
  }
  return { ...values, costDate: costDate };
}

const normalizeValues = (values) => (
  {...values, ...{
      amount: numberOrNull(values.amount),
    }}
)

const VehicleCostControl = ({title, open, onToggle, onSave, initialFormValues}) => (
  <Modal
    scrollable={true}
    size="sm"
    show={open}
    onHide={onToggle}>
    <Formik
      initialValues={normalizeInitialValues(initialFormValues)}
      onSubmit={(values, formikHelpers) => onSave(normalizeValues(values), formikHelpers)}
      validationSchema={AddCostSchema}
    >
      {({
          values,
          errors,
          setFieldValue,
          setFieldTouched
      }) => (
        <FormikForm>
          <Modal.Header closeButton>{title}</Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="costDate"><strong>Datum *</strong></Form.Label>
              <Col sm={valueColWidth}>
                <FormikDateField name="costDate" />
                <ErrorMessage name='costDate' component={Form.Control.Feedback} type='invalid' />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={labelColWidth} className="text-sm-right"><strong>Type *</strong></Form.Label>
              <Col sm={valueColWidth}>
                <SelectCostType onChange={setFieldValue} onBlur={setFieldTouched} value={values.costType} isInvalid={!!errors.costType}/>
                <ErrorMessage name='costType' component={Form.Control.Feedback} type='invalid' />
              </Col>
            </Form.Group>
            <HorizontalColumnField label="Bedrag" required={true} name="amount" />
            <HorizontalColumnField label="Omschrijving" required name="description" />
            <HorizontalColumnField label="Referentie" name="reference" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onToggle}>Annuleren</Button>
            {' '}
            <FormSubmitButton />
          </Modal.Footer>
        </FormikForm>
        )}
    </Formik>
  </Modal>
);

export default VehicleCostControl;