import React from "react";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import util from "../../utils/util";
import classNames from "classnames";
import {AbsenceType} from "../../../libs/api/time/api-model";
import {AsProp} from "react-bootstrap/helpers";
import {Color} from "react-bootstrap/types";

const bgColor = (code: string) => {
  switch(code) {
    case 'W':
    case 'N':
      return 'light';
    case 'BF':
    case 'CD':
    case 'IRO':
    case 'V':
    case 'RD':
      return 'warning';
    case 'S':
      return 'info';
    case 'M':
    case 'T':
      return 'dark';
    case 'A':
      return 'primary';
    case 'AO':
    case 'Z':
      return 'danger';
    default:
      return 'light';
  }
}

const textColor = (code: string): Color => {
  switch(code) {
    case 'W':
    case 'N':
    case 'BF':
    case 'CD':
    case 'IRO':
    case 'V':
    case 'RD':
      return 'dark';
    case 'S':
      return 'secondary';
    case 'M':
    case 'T':
      return 'secondary';
    case 'A':
      return 'secondary';
    case 'AO':
    case 'Z':
      return 'secondary';
    default:
      return 'dark';
  }
}

type AbsenceBadgeProps = AsProp & {
  absenceType: AbsenceType,
  className?: string
}

const AbsenceBadge: React.FC<AbsenceBadgeProps> = ({absenceType, className, as}) =>
  <OverlayTrigger placement='top'
                  overlay={<Tooltip id={`tooltip-header-activity-${absenceType.id}`}>{absenceType.name}</Tooltip>}>
    <Badge as={util.isDefined(as) ? as : "sup"}
           bg={bgColor(absenceType.code)}
           text={textColor(absenceType.code)}
           className={classNames("p-1", className)}>
      {absenceType.code}
    </Badge>
  </OverlayTrigger>

export default AbsenceBadge;
