import React from "react";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Edit as EditIcon, Trash, UserCheck} from "react-feather";
import {DailyPlanningItem, Activity as ActivityModel, ProjectGroupRef} from "../../../../libs/api/time/api-model";
import util from "../../../utils/util";
import ActivityBadgeNew from "../../components/ActivityBadgeNew";

type EntityActionHandler<T> = (entity: T) => void;

type ActionButtonProps<T> = {
  value: T
  actionHandler: EntityActionHandler<T>
}

type ActionProps<T> = {
  value: T
  handleEdit: EntityActionHandler<T>
  handleDelete: EntityActionHandler<T>
}

const Project: React.FC<{value: ProjectGroupRef}> = ({value}) =>
  <div>
    { value.groupId }<small className="ms-2 text-muted text-truncate">{ value.location } - { value.company }</small><br/>
    { value.description }
  </div>

const Activity: React.FC<{value: ActivityModel}> = ({value}) =>
  <React.Fragment>
    <ActivityBadgeNew activity={value} />
    <br/><small className="text-muted">{value.name}</small>
  </React.Fragment>

const Driver: React.FC<{value: boolean}> = ({value}) =>
  <React.Fragment>
    { typeof value !== 'undefined' && value && <UserCheck className="text-success" size={18} /> }
  </React.Fragment>

const Edit: React.FC<ActionButtonProps<DailyPlanningItem>> = ({value, actionHandler}) =>
  <Button variant="link" size={"sm"} className="p-0" onClick={_ => actionHandler(value)}>
    <EditIcon className="me-1" size={18} />
  </Button>

const Delete: React.FC<ActionButtonProps<DailyPlanningItem>> = ({value, actionHandler}) =>
  <Button variant="link" size="sm" className="text-danger p-0" onClick={_ => actionHandler(value)}>
    <Trash className="me-1" size={18} />
  </Button>

/*
const CopyButton: React.FC<ActionButtonProps<DailyPlanningItem>> = ({value, actionHandler}) =>
  <Button variant="link" size="sm" className="text-secondary p-0"
          title="Lijn kopiëren"
          disabled={true}
          onClick={_ => actionHandler(value)}>
    <Copy className="me-1" size={18} />
  </Button>
 */

const Actions: React.FC<ActionProps<DailyPlanningItem>> = ({value, handleEdit, handleDelete}) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <Edit value={value} actionHandler={handleEdit} />
      {/*<CopyButton value={value} actionHandler={handleEdit} />*/}
      <Delete value={value} actionHandler={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const columns = (handleEdit: EntityActionHandler<DailyPlanningItem>, handleDelete: EntityActionHandler<DailyPlanningItem>) => [
  {
    Header: "Activiteit",
    accessor: "activityRef",
    className: "text-center",
    Cell: Activity,
    width: "7%",
  },
  {
    Header: "Project",
    accessor: "projectRef",
    Cell: Project,
  },
  {
    Header: "Begin",
    accessor: "start",
    width: "7%",
    className: "text-center",
    Cell: util.localTimeToHours,
  },
  {
    Header: "Bestuurder",
    accessor: "driver",
    Cell: Driver,
    width: "7%",
    className: "text-center",
  },
  {
    Header: "Voertuig",
    accessor: "licensePlate",
    width: "10%",
  },
  {
    Header: "Description",
    id: "description",
    accessor: "description",
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    accessor: 'id',
    width: "10%",
    Cell: (data: any) => <Actions value={data.row.original} handleEdit={handleEdit} handleDelete={handleDelete} />
  }
];

const renderDescription = (row: any, visibleColumnsLength: number) => (
  <React.Fragment>
    <td>&nbsp;</td>
    <td colSpan={visibleColumnsLength - 1}>
      <span className='fst-italic text-primary'>{row.values?.description}</span>
    </td>
  </React.Fragment>
)

export { columns, renderDescription };
