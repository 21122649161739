import * as React from "react";
import {useEffect, useReducer} from "react";
import { Service, toOption } from "../../services/Service";
import {
  GasType,
} from "../../../libs/api/dal/api-model";
import {none, Option, some} from "fp-ts/Option";
import {Button, Card, Col, Row} from "react-bootstrap";
import {PlusCircle} from "react-feather";
import SimpleTable from "../components/SimpleTable";
import {tankFillColumns} from "./columns";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import TankFillControl, {TankFillForm, toForm, toTankFill} from "./TankFillControl";
import ConfirmationDialog from "../components/ConfirmationDialog";
import {FormikHelpers} from "formik/dist/types";
import {array, option} from "fp-ts";
import {pipe} from "fp-ts/function";
import TankStats from "./TankStats";
import PriceStats from "./PriceStats";
import {TankFills as TankFillApi} from "../../services/TimeApi";
import {PagedList, TankFill, TankFillStats} from "../../../libs/api/time/api-model";
import formikHandler from "../../services/FormikApiHandler";
import moment from "moment/moment";

type TankFillActionBase = {
  type: string
}

type Init = TankFillActionBase & {
  type: 'init'
}

type ToggleCreateModal = TankFillActionBase & {
  type: 'ToggleCreateModal'
}
type ToggleEditModal = TankFillActionBase & {
  type: 'ToggleEditModal'
  id: Option<number>
}
type ToggleDeleteModal = TankFillActionBase & {
  type: 'ToggleDeleteModal'
  id: Option<number>
}

type DeleteTankFill = TankFillActionBase & {
  type: 'DeleteTankFill'
}
type TankFillDeleted = TankFillActionBase & {
  type: 'TankFillDeleted'
  response: Service<string>
}

type LoadTankFills = TankFillActionBase & {
  type: 'LoadTankFills'
}
type TankFillsLoaded = TankFillActionBase & {
  type: 'TankFillsLoaded'
  response: Service<PagedList<TankFill>>
}

type LoadTankFillStats = TankFillActionBase & {
  type: 'LoadTankFillStats'
}
type TankFillStatsLoaded = TankFillActionBase & {
  type: 'TankFillStatsLoaded'
  response: Service<TankFillStats>
}

type TankFillAction =
  | Init
  | DeleteTankFill
  | TankFillDeleted
  | ToggleCreateModal
  | ToggleEditModal
  | ToggleDeleteModal
  | LoadTankFills
  | TankFillsLoaded
  | LoadTankFillStats
  | TankFillStatsLoaded

type TankFillState = {
  showCreate: boolean
  showEdit: boolean
  showDelete: boolean
  tankFills: Service<PagedList<TankFill>>
  tankFillStats: Service<TankFillStats>
  tankFill: Option<TankFill>
  tankFillDeleteService: Service<string>
}

const createInitialState: TankFillState = ({
  showCreate: false,
  showEdit: false,
  showDelete: false,
  tankFills: { status: 'init' },
  tankFillStats: { status: 'init' },
  tankFill: none,
  tankFillDeleteService: { status: 'init' }
})

const findTankFill: (service: Service<PagedList<TankFill>>, id: number) => Option<TankFill> = (service, id) =>
  pipe(service,
    s => toOption(s),
    option.map(tf => tf.elements),
    option.chain(fills => pipe(fills, array.findFirst(f => f.id === id))),
  );

export interface TankFillsProps {
  gasType: GasType,
}

const TankFills: React.FC<TankFillsProps> = ({ gasType }) => {

  function tankFillsReducer(state: TankFillState, action: TankFillAction): TankFillState {
    switch (action.type) {
      case 'init': {
        return {...state};
      }
      case 'ToggleCreateModal': {
        return {...state,
          showCreate: !state.showCreate
        };
      }
      case 'ToggleEditModal': {
        return {...state,
          showEdit: !state.showEdit,
          tankFill: pipe(action.id, option.chain(id => findTankFill(state.tankFills, id)))
        };
      }
      case 'ToggleDeleteModal': {
        return {...state,
          showDelete: !state.showDelete,
          tankFill: pipe(action.id, option.chain(id => findTankFill(state.tankFills, id)))
        };
      }
      case 'DeleteTankFill': {
        return {...state,
          tankFillDeleteService: { status: 'loading' }
        };
      }
      case 'TankFillDeleted': {
        return {...state,
          tankFillDeleteService: action.response,
          showDelete: action.response.status !== 'loaded'
        };
      }
      case 'LoadTankFills': {
        return {...state,
          tankFills: { status: 'loading' }
        };
      }
      case 'TankFillsLoaded': {
        return {...state,
          tankFills: action.response,
        };
      }
      case 'LoadTankFillStats': {
        return {...state,
          tankFillStats: { status: 'loading' }
        };
      }
      case 'TankFillStatsLoaded': {
        return {...state,
          tankFillStats: action.response,
        };
      }
      default: {
        console.error('Unknown action', action);
        return state;
      }
    }
  }

  const [{
    showCreate,
    showEdit,
    showDelete,
    tankFills,
    tankFillStats,
    tankFill,
    tankFillDeleteService
  }, dispatch] = useReducer(tankFillsReducer, createInitialState);

  const loadTankFills = () => {
    dispatch({ type: 'LoadTankFills' });
    TankFillApi.paged(20, 0)(gasType.id)((r: Service<PagedList<TankFill>>) =>
      dispatch({ type: 'TankFillsLoaded', response: r })
    );
  };

  const loadStats = () => {
    dispatch({ type: 'LoadTankFillStats' });
    TankFillApi.stats(gasType.id)((r: Service<TankFillStats>) =>
      dispatch({ type: 'TankFillStatsLoaded', response: r })
    );
  };

  useEffect(loadTankFills, [ gasType ]);
  useEffect(loadStats, [ gasType ]);

  const upsertTankFill = (values: TankFillForm, formikHelpers: FormikHelpers<TankFillForm>, successAction: TankFillAction) =>
    TankFillApi.upsert(toTankFill(values))(response => {
      formikHandler(formikHelpers)(response);
      if (response.status === 'loaded') {
        loadTankFills();
        loadStats();
        dispatch(successAction);
      }
    });

  const createTankFill = (values: TankFillForm, formikHelpers: FormikHelpers<TankFillForm>) =>
    upsertTankFill(values, formikHelpers, { type: 'ToggleCreateModal' });

  const updateTankFill = (values: TankFillForm, formikHelpers: FormikHelpers<TankFillForm>) =>
    upsertTankFill(values, formikHelpers, { type: 'ToggleEditModal', id: none });

  const deleteTankFill = () =>
    pipe(
      tankFill,
      option.map(t => t.id),
      option.fold(
        () => console.warn('cannot delete, id is not found'),
        (id) => {
          dispatch({ type: 'DeleteTankFill' });
          TankFillApi.delete(id)(response => {
            dispatch({ type: 'TankFillDeleted', response: response });
            if (response.status === 'loaded') {
              loadTankFills();
              loadStats();
            }
          });
        }
      )
    );

  return (
    <>
      <Row>
        <Col lg="5" className="d-flex col-xxl-4">
          <div className="w-100">
            <Row>
              <Col sm="12" lg="12" xxl={12} className="d-flex col-xxl-6">
                <Card className="flex-fill">
                  <Card.Body className="py-4">
                    <ErrorHandlingTS service={tankFillStats} onLoaded={(stats) =>
                      <TankStats stats={stats} />
                    } />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="12" lg="12" xxl={12} className="d-flex">
                <ErrorHandlingTS service={tankFillStats} onLoaded={(stats) =>
                  <PriceStats stats={stats} />
                } />
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg="7" className="d-flex col-xxl-8">
          <Card className="flex-fill w-100">
            <Card.Header>
              <div className="card-actions float-end">
                <Button variant="success" onClick={() => dispatch({ type: 'ToggleCreateModal' })}>
                  <PlusCircle className="me-1" />Toevoegen
                </Button>
              </div>
              <Card.Title className="mb-0">
                <h5>Vulbeurten <span className='text-muted'>{gasType.name}</span> <i className='small text-muted'>(laatste 20)</i></h5>
              </Card.Title>
            </Card.Header>
            <ErrorHandlingTS service={tankFills} onLoaded={(pagedList) =>
              <SimpleTable size="sm" className="my-0" renderFooter={undefined} striped
                           data={pagedList.elements}
                           columns={tankFillColumns(
                             (fill) => dispatch({ type: 'ToggleEditModal', id: some(fill.id) }),
                             (fill) => dispatch({ type: 'ToggleDeleteModal', id: some(fill.id) })
                           )} />
            } />
          </Card>
        </Col>
      </Row>
      <TankFillControl title={`Vulling toevoegen voor ${gasType.name}`}
                       isOpen={showCreate}
                       onHide={() => dispatch({ type: 'ToggleCreateModal' })}
                       onSave={createTankFill}
                       initialFormValues={{
                         fillDate: moment(),
                         quantity: '',
                         estimate: '',
                         gasType: gasType,
                         rate: ''
                       }}
      />
      {
        pipe(tankFill, option.fold(
          () => <></>,
          (fill) =>
            <TankFillControl title={`Vulling aanpassen voor ${gasType.name}`}
                             isOpen={showEdit}
                             onHide={() => dispatch({ type: 'ToggleEditModal', id: none })}
                             onSave={updateTankFill}
                             initialFormValues={toForm(fill)}
            />
        ))
      }
      <ConfirmationDialog title={`Tank vulling verwijderen voor ${gasType.name}`}
                          open={showDelete}
                          loading={tankFillDeleteService.status === 'loading'}
                          error={tankFillDeleteService.status === 'error'}
                          onCancel={() => dispatch({ type: 'ToggleDeleteModal', id: none })}
                          onConfirm={deleteTankFill}>
        <p>Ben je zeker dat je deze tank vulling wil verwijderen?</p>
      </ConfirmationDialog>
    </>
  );
}

export default TankFills;
