import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Row} from "react-bootstrap";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import TableWithFiltering from "../components/TableWithFiltering";
import {vehicleColumns} from "./columns";
import {PlusCircle} from "react-feather";
import {useEffect, useState} from "react";
import {Service} from "../../services/Service";
import {LinkButton} from "../components/LinkButton";
import {Vehicles as DALVehicles} from "../../services/DalServiceTS";
import {Vehicle} from "../../../libs/api/dal/api-model";

const Header = () => {
  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>Voertuigen</h3>
      </Col>
      <Col xs="auto" className="ms-auto text-end mt-n1">
        <LinkButton to="/pages/material/vehicles/new" buttonProps={{variant: "success", className: "shadow-sm me-1"}}>
          <PlusCircle className="feather me-1" />Toevoegen
        </LinkButton>
      </Col>
    </Row>
  );
};

const Employees: React.FC = () => {
  const [vehicles, setVehicles] = useState<Service<Vehicle[]>>({ status: 'init' });
  const loadData = () => DALVehicles.list(true)(setVehicles);
  useEffect(loadData, []);

  return (
    <>
      <Helmet title="Voertuigen"/>
      <Container fluid className="p-0">
        <Header />
        <Row>
          <Col>
            <ErrorHandlingTS service={vehicles} onLoaded={(data) =>
              <Card>
                <TableWithFiltering striped bordered
                                    data={data}
                                    columns={vehicleColumns}
                                    initialFilters={[
                                      { 'id': 'active', 'value': true },
                                    ]}
                />
              </Card>
            } />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Employees;
