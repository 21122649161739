import React, {ReactElement, useEffect, useState} from "react";
import { Helmet } from "react-helmet-async";
import {Container} from "react-bootstrap";

import withRouter from "../../hooks/withRouter";
import EducationControl from "./EducationControl";
import Attendants from "./attendances/Attendants";
import {ChevronLeft} from "react-feather";
import {Link, useParams} from "react-router-dom";
import {Service} from "../../services/Service";
import {Education as EducationModel} from "../../../libs/api/time/api-model";
import {Educations as EducationsApi} from "../../services/TimeApi";
import {pipe} from "fp-ts/function";
import {option} from "fp-ts";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import {FormikHelpers} from "formik/dist/types";
import formikHandler from "../../services/FormikApiHandler";
import * as O from "fp-ts/Option";

const labelColWidth = 2;
const valueColWidth = 5;

const mapAsReact: <T>(onV: (nr: T) => ReactElement) => (o: O.Option<T>) => ReactElement = (generateElement) => (o) =>
  pipe(o,
    O.map(optionValue => generateElement(optionValue)),
    O.getOrElse(() => <></>)
  );

export const Education: React.FC = () => {

  const { educationId} = useParams();
  const [entity, setEntity] = useState<Service<EducationModel>>({ status: 'init' });

  const loadEducation = () => pipe(educationId,
    option.fromNullable,
    option.fold(
      () => undefined,
      id => EducationsApi.get(id)(setEntity)
    )
  );
  useEffect(loadEducation, [ educationId ]);

  const saveEducation = (entity: EducationModel, helpers: FormikHelpers<EducationModel>) =>
    EducationsApi.upsert(entity)(formikHandler(helpers));

  return (
    <>
      <Helmet title={ educationId } />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          <Link to="/pages/time/educations" className="me-1 btn btn-link btn-lg" role='button'>
            <ChevronLeft className='align-text-bottom' size={24} />
          </Link>
          { educationId }
        </h1>
        <ErrorHandlingTS service={entity} onLoaded={data =>
          <>
            <EducationControl education={data}
                              onSave={saveEducation}
                              labelColWidth={labelColWidth}
                              valueColWidth={valueColWidth}
                              flavor='edit'
            />
            {
              pipe(educationId,
                option.fromNullable,
                mapAsReact((id) =>
                  <Attendants educationId={id}/>
                )
              )
            }
          </>
        } />
      </Container>
    </>
  );
}

export default withRouter(Education);
