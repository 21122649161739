import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
} from "react-bootstrap";
import {attendanceColumns} from "./columns";
import SimpleTable from "../../components/SimpleTable";
import {PlusCircle} from "react-feather";
import AttendanceControl, {AttendanceForm} from "./AttendanceControl";
import {EducationAttendance} from "../../../../libs/api/time/api-model";
import {Service} from "../../../services/Service";
import {Educations as EducationsApi} from "../../../services/TimeApi";
import {none, Option, some} from "fp-ts/Option";
import {FormikHelpers} from "formik/dist/types";
import formikHandler from "../../../services/FormikApiHandler";
import {pipe} from "fp-ts/function";
import {option} from "fp-ts";
import moment from "moment/moment";
import ErrorHandlingTS from "../../components/ErrorHandlingTS";
import ConfirmationDialog from "../../components/ConfirmationDialog";

export interface AttendantsProps {
  educationId: string
}

export const Attendants: React.FC<AttendantsProps> = (
  { educationId }
) => {

  const [entities, setEntities] = useState<Service<EducationAttendance[]>>({ status: 'init' });
  const loadEntries = () => EducationsApi.attendancesByEducation(educationId)(setEntities);

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteState, setDeleteState] = useState<Service<string>>({ status: 'init' });

  const [editInstance, setEditInstance] = useState<Option<EducationAttendance>>(none);

  useEffect(loadEntries, [ educationId ]);

  const confirmDelete = (entity: EducationAttendance) => {
    setEditInstance(some(entity));
    setShowDeleteModal(true);
  };

  const createAttendance = (entity: AttendanceForm, helpers: FormikHelpers<AttendanceForm>) =>
    pipe(option.fromNullable(entity.employeeId),
      option.fold(
        () => undefined,
        (employeeId) =>
          EducationsApi.upsertAttendance(entity.date, educationId, employeeId)(response => {
            formikHandler(helpers)(response);
            if (response.status === 'loaded') {
              setShowCreateModal(false);
              setEditInstance(none);
              loadEntries();
            }
          })
      )
    );

  const deleteAttendance = () => pipe(editInstance,
    option.map(attendance => {
        setDeleteState({status: 'loading'});
        return EducationsApi.deleteAttendance(moment(attendance.date), educationId, attendance.employee.id)(response => {
          setDeleteState(response);
          if (response.status === 'loaded') {
            setShowDeleteModal(false);
            loadEntries();
          }
        })
      }
    )
  );

  return (
    <>
      <Card>
        <Card.Header>
          <div className="card-actions float-end">
            <Button variant="success" onClick={() => setShowCreateModal(true)}>
              <PlusCircle className="me-1" />Toevoegen
            </Button>
          </div>
          <Card.Title className="mb-0">
            <h5>Aanwezigen</h5>
          </Card.Title>
        </Card.Header>
        <ErrorHandlingTS service={entities} onLoaded={(data) =>
          <SimpleTable bordered data={data} columns={attendanceColumns(confirmDelete)} />
        } />
      </Card>
      <AttendanceControl title={`Deelname toevoegen`}
                         isOpen={showCreateModal}
                         onHide={() => setShowCreateModal(false)}
                         onSave={createAttendance}
      />
      <ConfirmationDialog title={`Deelname opleiding verwijderen`}
                          open={showDeleteModal}
                          loading={deleteState.status === 'loading'}
                          error={deleteState.status === 'error'}
                          onCancel={() => setShowDeleteModal(false)}
                          onConfirm={deleteAttendance}>
        <p>Ben je zeker dat je de deelname aan deze opleiding wilt verwijderen?</p>
      </ConfirmationDialog>
    </>
  );
}

export default Attendants;
