import * as React from "react";
import {Link, useNavigate} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {ChevronLeft} from "react-feather";
import {
  Card,
  Col,
  Container,
  ListGroup,
  Row,
  Tab
} from "react-bootstrap";
import VehicleInfo from "./VehicleInfo";
import {FormikHelpers, FormikValues} from "formik/dist/types";
import DalService from "../../services/DalService";

const labelColWidth = 4;
const valueColWidth = 8;

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">
        <h5>Eigenschappen</h5>
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item href="#general" action>
        Algemeen
      </ListGroup.Item>
      <ListGroup.Item href="#accounting" action disabled>
        Boekhouding
      </ListGroup.Item>
      <ListGroup.Item href="#technical" action disabled>
        Keuring & Onderhoud
      </ListGroup.Item>
      <ListGroup.Item href="#licensing" action disabled>
        Licenties
      </ListGroup.Item>
      <ListGroup.Item href="#transactions" action disabled>
        Tankbeurten
      </ListGroup.Item>
      <ListGroup.Item href="#costs" action disabled>
        Kosten
      </ListGroup.Item>
    </ListGroup>
  </Card>
);

export interface Props {
}

export interface CreateVehicleResponse {
  licensePlate: string
}


const emptyVehicle = {
  "licensePlate": "",
  "name": "",
  "tankcard": "",
  "odometer": 0,
  "analyticId": "",
  "carkit": "",
  "site": {
    "id": undefined,
    "name": "",
    "used": true
  }
}

const CreateVehicle: React.FC<Props> = (_) => {
  const navigate = useNavigate();

  const createVehicle = (vehicle: {}, formikBag: FormikHelpers<FormikValues>) =>
    DalService.insertVehicle(vehicle,
      (response: any) => {
        formikBag.setFieldError("submit", undefined);
        formikBag.setFieldTouched("submit", true);
        const licensePlate = (response as CreateVehicleResponse).licensePlate;
        navigate(`/pages/material/vehicle/${licensePlate}`);
      }, (error: any) => {
        console.log(error);
        formikBag.setFieldError("submit", error.toString());
        formikBag.setSubmitting(false);
      }
    )

  return (
    <>
      <Helmet title='Nieuw Voertuig' />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          <Link to="/pages/material/vehicles" className="me-1 btn btn-link btn-lg" role='button'>
            <ChevronLeft className='align-text-bottom' size={24} />
          </Link>
          Nieuw Voertuig Aanmaken
        </h1>
        <div className={"tab tab-vertical"}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="#general">
            <Row>
              <Col sm={4} md={3} xl={2}>
                <Navigation />
              </Col>
              <Col sm={8} md={9} xl={10}>
                <Tab.Content className="p-0">
                  <Tab.Pane eventKey="#general">
                    <VehicleInfo formState='create' vehicle={emptyVehicle} onSave={createVehicle} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </>
  );
};

export default CreateVehicle;