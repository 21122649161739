import React from "react";
import {
  Button,
  Modal,
  Form, Row, Col, Spinner,
} from "react-bootstrap";
import { Formik, Form as FormikForm } from 'formik';
import * as Icon from "react-feather";
import * as Yup from "yup";
import util from "../../utils/util";

const labelColWidth = 4
const valueColWidth = 8

const YupValidationSchema = Yup.object().shape({
  id: Yup.string().required(),
  name: Yup.string().required(),
  hourRate: Yup.number('Enkel numerieke waarde toegestaan'),
});

const FormField = ({label, name, values, errors, required, handleChange, ...props}) => (
  <Form.Group as={Row} className="mb-3">
    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor={`input${name}`}>
      {required ? <strong>{label} *</strong> : label }
    </Form.Label>
    <Col sm={valueColWidth}>
      <Form.Control
        {...props}
        name={name}
        id={`input${name}`}
        value={values[name]}
        onChange={handleChange}
        isInvalid={!!errors[name]}
      />
      <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
    </Col>
  </Form.Group>
)

const ActivityControl = ({title, open, onToggle, onSave, initialFormValues}) => (
  <Modal
    scrollable={true}
    size="sm"
    show={open}
    onHide={onToggle}>
    <Formik
      initialValues={initialFormValues}
      onSubmit={onSave}
      validationSchema={YupValidationSchema}
    >
      {({
          isSubmitting,
          values,
          errors,
          touched,
          handleChange,
        }) => (
        <FormikForm>
          <Modal.Header closeButton>{title}</Modal.Header>
          <Modal.Body>
            <FormField label="ID" required name="id" values={values} errors={errors} handleChange={handleChange} readOnly={util.isDefined(initialFormValues) && util.isDefined(initialFormValues.isEdit) && initialFormValues.isEdit} />
            <FormField label="Naam" required name="name" values={values} errors={errors} handleChange={handleChange} />
            <FormField label="Prijs/uur" name="hourRate" values={values} errors={errors} handleChange={handleChange} type="number" inputMode="numeric" />
            <FormField label="GP" name="gp" values={values} errors={errors} handleChange={handleChange} type="number" inputMode="numeric" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onToggle}>Annuleren</Button>
            {' '}
            <Button type="submit" variant="primary" disabled={isSubmitting}>Opslaan</Button>
            {isSubmitting && !errors.submit && <Spinner animation="grow" color="dark" size="sm" className="mx-2"/> }
            {errors.submit && <span className={"text-danger mx-2"}><Icon.Frown className="me-1" /> {errors.submit}</span>}
            {touched.submit && !errors.submit && <span className={"text-success mx-2"}><Icon.CheckCircle size={18} /></span>}
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  </Modal>
);

export default ActivityControl;