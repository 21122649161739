import React from "react";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import util from "../../utils/util";
import classNames, {Argument} from "classnames";
import {Registratie} from "../../../libs/api/time/api-model";
import {AsProp} from "react-bootstrap/helpers";
import {bgColor, textColor} from "./ActivityBadge";

type RegistratieBadgeProps = AsProp & {
  registratie: Registratie,
  className?: Argument
}

const RegistratieBadge: React.FC<RegistratieBadgeProps> = (
  { registratie, className, as }
) => {
  return (util.isDefined(registratie) &&
      <OverlayTrigger placement='top'
                      overlay={<Tooltip id={`tooltip-header-activity-${registratie.code}`}>{registratie.name}</Tooltip>}>
          <Badge as={util.isDefined(as) ? as : "sup"}
                 bg={bgColor(registratie.code)}
                 text={textColor(registratie.code)}
                 className={classNames("p-1", className)}>
            { registratie.code }
          </Badge>
      </OverlayTrigger>
  )
}

export default RegistratieBadge;
