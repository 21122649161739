import * as React from "react";
import {useParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, ListGroup, Row, Tab} from "react-bootstrap";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import TankFills from "./TankFills";
import {Service, toOption} from "../../services/Service";
import {GasTypes} from "../../services/TimeApi";
import {GasType} from "../../../libs/api/time/api-model";
import {array, option} from "fp-ts";
import {pipe} from "fp-ts/function";
import {none, Option} from "fp-ts/Option";

const Fuel: React.FC = () => {

  const { fueltype } = useParams();
  const navigate = useNavigate();
  const [gasTypes, setGasTypes] = useState<Service<GasType[]>>({ status: 'loading' });
  const [activeGasType, setActiveGasType] = useState<Option<GasType>>(none);

  const parseFuelType = () => setActiveGasType(pipe(
    gasTypes,
    s => toOption(s),
    option.chain(array.findFirst(f => f.id.toString() === fueltype))
  ));

  const loadGasTypes = () => GasTypes.list(setGasTypes);

  useEffect(loadGasTypes, []);
  useEffect(parseFuelType, [fueltype, gasTypes]);

  return (
    <>
      <Helmet title="Brandstof" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Brandstof</h1>

        <Tab.Container id="employee-tabs" defaultActiveKey="#gastype-0">
          <Row>
            <Col md="3" xl="2">
              <Card>
                <Card.Header>
                  <Card.Title className="mb-0">
                    <h5>Type Brandstof</h5>
                  </Card.Title>
                </Card.Header>
                <ListGroup variant="flush">
                  <ErrorHandlingTS service={gasTypes} onLoaded={ (entries) =>
                    <>
                    {entries.map(({id, name}) =>
                      <ListGroup.Item action
                                      active={id.toString() === fueltype}
                                      key={ `gastype-${id}` }
                                      onClick={() => navigate(`/pages/material/fuel/${id}`)}>
                        {name}
                      </ListGroup.Item>
                    )}
                    </>
                  } />
                </ListGroup>
              </Card>
            </Col>
            <Col md="9" xl="10">
              <Tab.Content className="p-0">
                {
                  pipe(
                    activeGasType,
                    option.map(gas =>
                      <TankFills gasType={gas} />
                    ),
                    option.getOrElse(() => <></>)
                  )
                }
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default Fuel;
