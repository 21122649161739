import util from "../../utils/util";

const borderColor = '#e9ecef';
const pdfStyles = (styles) => {
  const defaults = {
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
  }
  return {
    ...defaults,
    ...styles
  };
}

const numberColumn = (header, accessor, formatter) => ({
  Header: header,
  accessor: (data) => {
    const v = util.byString(data, accessor);
    return (util.isDefined(v) ? formatter(v) : null);
  },
  className: 'text-end',
  styles: pdfStyles({width: '7%', textAlign: 'right'}),
  //Cell: ({ value }) => (util.isDefined(value) ? formatter(value) : null)
});

const intColumn = (header, accessor) => numberColumn(header, accessor, util.formatInt)
const currencyColumn = (header, accessor) => numberColumn(header, accessor, util.formatCurrency)

const fleetCostColums = [
  {
    Header: "Type",
    accessor: "vehicle.type.name",
    styles: pdfStyles({width: '10%'}),
  },
  {
    Header: "Nummerplaat",
    accessor: "vehicle.licensePlate",
    styles: pdfStyles({width: '10%'}),
  },
  {
    Header: "Merknaam",
    accessor: "vehicle.model",
    styles: pdfStyles({width: '14%'}),
  },
  {
    Header: "Bestuurder",
    accessor: "vehicle.driver.name",
    styles: pdfStyles({width: '10%'}),
  },
  intColumn("Km start", "odometerStart"),
  intColumn("Km eind", "odometerEnd"),
  intColumn("Gereden km", "odometerDiff"),
  currencyColumn("Km kost", "vehicle.kmCost"),
  currencyColumn("ICC", "totalKmCost"),
  currencyColumn("Brandstof", "totalFuelCost"),
  currencyColumn("Aankoop", "totalPurchaseCost"),
  {
    Header: "Totaal",
    accessor: (data) => {
      const v = util.byString(data, "totalPeriodCost");
      return (util.isDefined(v) ? util.formatCurrency(v) : null);
    },
    className: 'text-end fw-bolder',
    styles: pdfStyles({width: '7%', textAlign: 'right'})
  }
];

export { fleetCostColums };
