import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Card, Col, Container, Row, Button} from "react-bootstrap";
import {PlusCircle} from "react-feather";

import { educationColumns } from "./columns";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SortableTable from "../components/SortableTable";
import {Service} from "../../services/Service";
import {Education} from "../../../libs/api/time/api-model";
import {Educations as EducationsApi} from "../../services/TimeApi";
import {none, Option, some} from "fp-ts/Option";
import {pipe} from "fp-ts/function";
import {option} from "fp-ts";
import ErrorHandlingTS from "../components/ErrorHandlingTS";

const Header = () => {
  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>Opleidingen</h3>
      </Col>
      <Col xs="auto" className="ms-auto text-end mt-n1">
        <Button variant="success" className="shadow-sm me-1" href="/pages/time/educations/new">
          <PlusCircle className="feather me-1" />Toevoegen
        </Button>
      </Col>
    </Row>
  );
};

export const Educations: React.FC = () => {

  const [entities, setEntities] = useState<Service<Education[]>>({ status: 'init' });
  const loadEntries = () => EducationsApi.list(setEntities);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteState, setDeleteState] = useState<Service<string>>({ status: 'init' });
  const [editInstance, setEditInstance] = useState<Option<Education>>(none);

  useEffect(loadEntries, []);

  const confirmDelete = (entity: Education) => {
    setEditInstance(some(entity));
    setShowDeleteModal(true);
  };

  const deleteEducation = () => pipe(editInstance,
    option.map(education => {
        setDeleteState({status: 'loading'});
        return EducationsApi.delete(education.id)(response => {
          setDeleteState(response);
          if (response.status === 'loaded') {
            setShowDeleteModal(false);
            loadEntries();
          }
        })
      }
    )
  );

  return (
    <React.Fragment>
      <Helmet title="Opleidingen"/>
      <Container fluid className="p-0">
        <Header />
        <Row>
          <Col>
            <ErrorHandlingTS service={entities} onLoaded={(data) =>
              <Card><SortableTable striped bordered data={data} columns={educationColumns(confirmDelete)} /></Card>
            } />
          </Col>
        </Row>
      </Container>
      <ConfirmationDialog title={`Opleiding ${pipe(editInstance, option.chain(e => option.fromNullable(e.title)), option.getOrElse(() => ""))}`}
                          open={showDeleteModal}
                          loading={deleteState.status === 'loading'}
                          error={deleteState.status === 'error'}
                          onCancel={() => setShowDeleteModal(false)}
                          onConfirm={deleteEducation}>
        <p>Ben je zeker dat je deze opleiding wil verwijderen?</p>
      </ConfirmationDialog>
    </React.Fragment>
  );
}

export default Educations;
