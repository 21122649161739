import React from "react";
import * as Yup from "yup";
import {ErrorMessage, Formik, useFormikContext} from "formik";
import {
  Card,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import SelectVehicleType from "./components/SelectVehicleType";
import SelectDriver from "./components/SelectDriver";
import SelectStationType from "./components/SelectStationType";
import SelectMaintenanceType from "./components/SelectMaintenanceType";
import SelectGasType from "./components/SelectGasType";
import SelectCounterType from "./components/SelectCounterType";
import FormSubmitButton from "../components/form/FormSubmitButton";
import SelectCompanyFormik from "../components/form/SelectCompanyFormik";

const schema = Yup.object().shape({
  licensePlate: Yup.string().required(),
  name: Yup.string().required(),
  analyticId: Yup.string().required(),
  site: Yup.object().shape({
    id: Yup.number().required(),
  }),
  maintenanceType: Yup.object().required(),
});

const VehicleInfo = ({formState, vehicle, onSave, labelColWidth, valueColWidth}) => {

  const FormField = ({label, name, required, ...props}) => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
    } = useFormikContext();
    return (
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor={`input${name}`}>
          {required ? <strong>{label} *</strong> : label }
        </Form.Label>
        <Col sm={valueColWidth}>
          <Form.Control
            {...props}
            className='mh-38'
            name={name}
            id={`input${name}`}
            value={values[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!touched[name] && !!errors[name]}
          />
          <ErrorMessage name={name} component={Form.Control.Feedback} type="invalid" />
        </Col>
      </Form.Group>
    );
  }
  const FormFieldWrapper = ({label, name, required, children}) => {
    const { errors } = useFormikContext();
    return (
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor={`input${name}`}>
          {required ? <strong>{label} *</strong> : label }
        </Form.Label>
        <Col sm={valueColWidth}>
          {children}
          <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  }

  return (
    <Card className="mb-0">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Algemeen
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          onSubmit={onSave}
          initialValues={vehicle}
        >
          {({
              handleSubmit,
              values,
              errors,
              setFieldValue,
              setFieldTouched,
            }) => {

            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <FormField label='Nummerplaat' name='licensePlate' required={true} readOnly={formState === 'update'} type="text" />
                  </Col>
                  <Col md={6}>
                    <FormFieldWrapper label='Bestuurder' name='driver'>
                      <SelectDriver
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.driver}
                      />
                    </FormFieldWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormField label='Merk/Model' name='name' required={true} type="text" />
                  </Col>
                  <Col md={6}>
                    <FormFieldWrapper label='Type' name='type'>
                      <SelectVehicleType
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.type}
                      />
                    </FormFieldWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormFieldWrapper label='Site' name='site.id' required>
                      <SelectCompanyFormik idType='id' name="site.id" useOptionValue={true} />
                    </FormFieldWrapper>
                  </Col>
                  <Col md={6}>
                    <FormFieldWrapper label='Brandstof' name='gasType'>
                      <SelectGasType
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.gasType}
                      />
                    </FormFieldWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormFieldWrapper label='Onderhoudstype' name='inputMaintenanceType' required>
                      <SelectMaintenanceType
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.maintenanceType}
                        isInvalid={!!errors.maintenanceType}
                      />
                    </FormFieldWrapper>
                  </Col>
                  <Col md={6}>
                    <FormFieldWrapper label='Type teller' name='counterType'>
                      <SelectCounterType
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.counterType}
                      />
                    </FormFieldWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormField label='Kostenplaats' name='analyticId' type="text" required />
                  </Col>
                  <Col md={6}>
                    <FormFieldWrapper label='Station type' name='stationType'>
                      <SelectStationType
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.stationType}
                      />
                    </FormFieldWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormField label='Kilometerstand' name='odometer' type="text" readOnly={true} />
                  </Col>
                  <Col md={6}>
                    <FormField label='Carkit' name='carkit' type="text" />
                  </Col>
                </Row>
                <FormSubmitButton />
              </Form>
          );}
          }
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default VehicleInfo;