import axios, {AxiosResponse} from "axios";
import { dalConfig } from "../config";
import {Moment} from "moment";
import {
  PrestatieControle,
  PrestatiePatch,
  EmployeeSummary as DALEmployeeSummary,
  ProjectGroupRef as DALProjectGroupRef,
  Activity as DALActivity,
  Site as DALSite,
  Vehicle
} from "../../libs/api/dal/api-model";
import {
  EmployeeSummary as TimeEmployeeSummary,
  ProjectGroupRef as TimeProjectGroupRef,
  Activity as TimeActivity,
  Company
} from "../../libs/api/time/api-model";
import {Service} from "./Service";

const http = axios.create({
  baseURL: dalConfig.apiBaseUrl,
  auth: {
    username: dalConfig.username as string,
    password: dalConfig.password as string
  }
});

function handleSuccess<T>(response: AxiosResponse<T>, onSuccess: (t: T) => void) {
  console.debug(`[${response.status}] ${response.statusText}`, response.data);
  onSuccess(response.data)
}
function handleError(error: any, onFailure: (reason: any) => void) {
  if (axios.isAxiosError(error)) {
    console.error(`[${error.response?.status}] ${error.response?.statusText}`, error.response);
    console.error(error.response);
  } else {
    console.error('Not an axios error !!');
    console.error(error);
  }
  onFailure(error);
}

const getAsService = <T>(url: string, params?: any) => (serviceStateHandler: (_: Service<T>) => void) => {
  serviceStateHandler({ status: 'loading' });
  http.get<T>(url, { params: params })
    .then((response) => serviceStateHandler({status: 'loaded', payload: response.data}))
    .catch((error) => serviceStateHandler({ status: 'error', error }));
};

function isNewEmployeeSummary(object: any): object is TimeEmployeeSummary {
  return 'employeeNr' in object;
}

export const toLegacyEmployeeSummary: (_: TimeEmployeeSummary) => DALEmployeeSummary = (from) => (
  {
    "id": from.employeeNr,
    "name": `${from.firstname} ${from.lastname}`,
    "workUnit": '',
  }
);

export const toLegacyEmployeeSummaryAny: (_: any) => any = (from) => (
  isNewEmployeeSummary(from) ? toLegacyEmployeeSummary(from) : from
);

export const toLegacyProjectGroupRef: (_: TimeProjectGroupRef) => DALProjectGroupRef = (from) => (
  from as DALProjectGroupRef
);

export const toLegacyActivity: (a?: TimeActivity) => DALActivity | undefined = (from) => (
  from ? {
    id: from.code,
    name: from.name,
    hourRate: from.rate,
    gp: 0
  } : undefined
);

export const toTimeProjectGroupRef: (_: DALProjectGroupRef) => TimeProjectGroupRef = (from) => (
  from as TimeProjectGroupRef
);

export const toLegacySite: (_: Company) => DALSite = (from) => (
  {
    "id": from.oldId,
    "name": from.name,
    "used": from.deleted === undefined
  }
);

function isCompany(object: any): object is Company {
  return 'oldId' in object;
}

export const toLegacySiteAny: (_: any) => any = (from) => (
  isCompany(from) ? toLegacySite(from) : from
);

const getTimesheets = (date: Moment, onSuccess: (value: PrestatieControle[]) => void, onFailure: (reason: any) => void) =>
  http.get<PrestatieControle[]>('/hr/timesheets', { params: { date: date.format('yyyy-MM-DD') }} )
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onFailure));

const patchTimesheet = (date: Moment, employeeId: number, entity: PrestatiePatch, onSuccess: (msg: PrestatiePatch) => void, onFailure: (reason: any) => void) =>
  http.put<PrestatiePatch>(`/hr/timesheets/employee/${employeeId}/period/${date.format('yyyy-MM-DD')}`, entity)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onFailure));

export const Timesheets = {
  get: getTimesheets,
  patch: patchTimesheet
};

export const Vehicles = {
  get: (licensePlate: String) => getAsService<Vehicle>(`/infra/vehicles/${licensePlate}`),
  list: (full: boolean = true) => getAsService<Vehicle[]>(`/infra/vehicles?withRefs=${full}`),
};
