import React from "react";
import {Helmet} from "react-helmet-async";

import {Card, Col, Container, Row, Button} from "react-bootstrap";
import {PlusCircle} from "react-feather";

import { activityColumns } from "./columns.js";
import util from "../../utils/util";
import DalService from "../../services/DalService";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ErrorHandling from "../components/ErrorHandling";
import SimpleTable from "../components/SimpleTable";
import ActivityControl from "./ActivityControl";
import UnderConstructionAlert from "../components/UnderConstructionAlert";

class Activities extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activities: {
          data: [],
          error: null,
          loaded: false,
      },
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      editId: null,
      deleteId: null,
      deleting: false,
      deleteError: null
    };
    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.createActivity = this.createActivity.bind(this);
    this.updateActivity = this.updateActivity.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
  }

  async componentDidMount() {
    this.loadActivities();
  }

  loadActivities() {
    DalService.getActivities(
      (activities) => {
        this.setState({
          activities: {
            data: activities,
            loaded: true,
            error: null,
          }
        })
      },
      (error) => {
        this.setState({
          activities: {
            data: [],
            loaded: true,
            error: error,
          }
        })
      }
    )
  }

  toggleCreateModal() {
    this.setState((state, _) => ({
      showCreateModal: !state.showCreateModal
    }));
  }

  toggleEditModal(entityId) {
    this.setState((state, _) => ({
      showEditModal: !state.showEditModal,
      editId: entityId
    }));
  }

  toggleDeleteModal(entityId) {
    this.setState((state, _) => ({
      showDeleteModal: !state.showDeleteModal,
      deleteId: entityId
    }));
  }

  findEntity(id, isEdit) {
    const {data} = this.state.activities;
    return (typeof data !== 'undefined' && data) ? this.addEditFlag(data.find(c => c.id === id), isEdit) : null;
  }

  addEditFlag(activity, isEdit) {
    return util.isDefined(activity) ? { ...activity, isEdit: isEdit } : activity;
  }

  createActivity(entity, formikBag) {
    console.debug('creating activity', entity);
    this.saveActivity(entity, formikBag, this.toggleCreateModal);
  }

  updateActivity(entity, formikBag) {
    console.debug('updating activity', entity);
    this.saveActivity(entity, formikBag, this.toggleEditModal);
  }

  saveActivity(entity, formikBag, toggler) {
    DalService.saveActivity(entity,
      (entities) => {
        formikBag.setFieldTouched("submit", true);
        this.loadActivities();
        toggler();
      },
      (error) => {
        formikBag.setFieldError("submit", error.toString());
      }
    );
  }

  deleteActivity() {
    console.debug('deleting activity', this.state.deleteId);
    this.setState((state, _) => ({
      deleting: true,
    }), () => {
      DalService.deleteActivity(this.state.deleteId,
        (_) => {
          this.setState({
            deleteError: null,
            deleting: false
          });
          this.loadActivities();
          this.toggleDeleteModal(null);
        },
        (error) => {
          this.setState({
            deleteError: error,
            deleting: false
          });
        }
      );
    });
  }

  render() {
    const {error, loaded, data} = this.state.activities;
      return (
        <React.Fragment>
          <Helmet title="Activiteiten"/>
          <Container fluid className="p-0">
            <Row className="mb-2 mb-xl-3">
              <Col xs="auto" className="d-none d-sm-block">
                <h3>Activiteiten</h3>
              </Col>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="success" className="shadow-sm me-1" onClick={this.toggleCreateModal}>
                  <PlusCircle className="feather me-1" />Toevoegen
                </Button>
              </Col>
            </Row>
            <Row>
              <UnderConstructionAlert msg='Deze pagina is nog niet klaar. Opsplitsen in activiteiten, afwezigheden en premies' />
              <Col>
                <ErrorHandling error={error} loading={!loaded}>
                  <Card><SimpleTable bordered data={data} columns={activityColumns(this.toggleEditModal, this.toggleDeleteModal)} /></Card>
                </ErrorHandling>
              </Col>
            </Row>
          </Container>
          <ActivityControl title={`Activiteit toevoegen`}
                           open={this.state.showCreateModal}
                           onToggle={this.toggleCreateModal}
                           onSave={this.createActivity}
                           initialFormValues={{
                             id: '',
                             name: '',
                             gp: 0,
                             isEdit: false
                           }}
          />
          <ActivityControl title={`Activiteit aanpassen`}
                           open={this.state.showEditModal}
                           onToggle={this.toggleEditModal}
                           onSave={this.updateActivity}
                           initialFormValues={this.findEntity(this.state.editId, true)}
          />
          <ConfirmationDialog title={`Activiteit verwijderen`}
                              open={this.state.showDeleteModal}
                              loading={this.state.deleting}
                              error={this.state.deleteError}
                              onCancel={_ => this.toggleDeleteModal(null)}
                              onConfirm={this.deleteActivity}>
            <p>Ben je zeker dat je deze activiteit wil verwijderen?</p>
          </ConfirmationDialog>
        </React.Fragment>
      );
  }
}

export default Activities;
