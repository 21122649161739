import {Service} from "./Service";
import {FormikHelpers} from "formik";

const formikHandler = <T, S>(formikHelpers: FormikHelpers<T>) => (result: Service<S>) => {
  formikHelpers.setSubmitting(false);
  switch (result.status) {
    case "error":
      formikHelpers.setFieldError("submit", result.error.toString());
      return undefined;
    case "loaded":
      formikHelpers.setFieldTouched("submit", true);
      return result.payload;
    default:
      return undefined;
  }
};

export default formikHandler;