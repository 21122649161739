import { Link } from "react-router-dom";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import React from "react";
import {Trash} from "react-feather";
import {Education} from "../../../libs/api/time/api-model";

type EntityActionHandler<T> = (entity: T) => void;

type ActionProps<T> = {
  value: T
  handleDelete: EntityActionHandler<T>
}

const EducationLink: React.FC<{value: Education}> = ({ value }) =>
  <Link to={`/pages/time/education/${ value.id }`}>
    { value.title }
  </Link>

const DeleteEducation: React.FC<ActionProps<Education>> = ({ value, handleDelete }) =>
  <Button variant="link" size="sm" className="text-danger" onClick={_ => handleDelete(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const EducationActions: React.FC<ActionProps<Education>> = ({ value, handleDelete }) =>
  <ButtonToolbar>
    <ButtonGroup size={"sm"} vertical={false}>
      <DeleteEducation value={value} handleDelete={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const educationColumns = (handleDelete: EntityActionHandler<Education>) =>[
  {
    Header: "#",
    accessor: "id",
    width: "20%",
  },
  {
    Header: "Titel",
    id: "title",
    accessor: (data: any) => data,
    Cell: EducationLink
  },
  {
    Header: "Docent",
    accessor: "docent",
    width: "15%",
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    accessor: 'id',
    width: "10%",
    Cell: (data: any) => <EducationActions value={data.row.original} handleDelete={handleDelete} />
  }
];

export { educationColumns };
