import * as React from "react";
import {ProgressBar} from "react-bootstrap";
import {TankFillStats} from "../../../libs/api/time/api-model";
import classNames from "classnames";
import util from "../../utils/util";

export interface TankStatProps {
  label: string;
  value: string;
  className?: string;
}
export interface TankStatsProps {
  stats: TankFillStats
}

const fillStatusVariant = (percentage: number) =>
  (percentage <= 25) ? "danger" : (percentage <= 50) ? "warning" : (percentage <= 65) ? "primary" : "success"

const TankStat: React.FC<TankStatProps> = ({label, value, className}) =>
  <>
    <div className={classNames("float-end", className)}>{value}</div>
    <h5 className="mb-4">{label}</h5>
  </>

const TankStats: React.FC<TankStatsProps> = ({ stats }) => {

  return (
    <>
      <TankStat label="Laatste Vulling" value={new Date(stats.lastFill).toLocaleDateString("nl-BE")} />
      <TankStat label="Begin Niveau" value={util.formatInt(stats.startLevel)} />
      <TankStat label="Verbruik" value={util.formatInt(stats.consumptionSinceLastFill)} />
      <TankStat label="Huidige Stand"
                value={util.formatInt(stats.currentLevel)}
                className={`text-${fillStatusVariant(stats.currentLevelPct)}`} />
      <ProgressBar className="mb-3"
                   variant={fillStatusVariant(stats.currentLevelPct)}
                   now={stats.currentLevelPct}
                   label={`${stats.currentLevelPct} %`} />
    </>
  );
}

export default TankStats;
