import React from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import {
  Card,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import FormikDateField from "../components/FormikDateField";
import FormSubmitButton from "../components/form/FormSubmitButton";
import HorizontalColumnField from "../components/form/HorizontalColumnField";
import {numberOrNull} from "../../utils/util";

const nummer = () => Yup.number().typeError('geef een geldig cijfer').nullable(true);

const schema = Yup.object().shape({
  licensePlate: Yup.string().required(),
  kmCost: nummer(),
  co2: nummer(),
  co2Tax: nummer(),
  fiscPk: nummer(),
  fiscPkTax: nummer(),
  insuranceCost: nummer(),
});

const normalizeValues = (values) => (
  {...values, ...{
    kmCost: numberOrNull(values.kmCost),
    co2: numberOrNull(values.co2),
    co2Tax: numberOrNull(values.co2Tax),
    fiscPk: numberOrNull(values.fiscPk),
    fiscPkTax: numberOrNull(values.fiscPkTax),
    insuranceCost: numberOrNull(values.insuranceCost),
  }}
)

const VehicleAccountingInfo = ({vehicle, onSave, labelColWidth, valueColWidth}) => {

  return (
    <Card className="mb-0">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Boekhouding
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          onSubmit={(values, formikHelpers) => onSave(normalizeValues(values), formikHelpers)}
          initialValues={vehicle}
        >
          {({
              handleSubmit,
              values,
              touched,
              isSubmitting,
              errors,
            }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <HorizontalColumnField label='Km kost' name='kmCost' type="text" />
                </Col>
                <Col md={6}>
                  <HorizontalColumnField label='CO2 Uitstoot' name='co2' type="text" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <HorizontalColumnField label='Verzekering Polis' name='polis' type="text" />
                </Col>
                <Col md={6}>
                  <HorizontalColumnField label='CO2 Belasting' name='co2Tax' type="text" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="insuranceDate">Verzekering Datum</Form.Label>
                    <Col sm={valueColWidth}>
                      <FormikDateField name="insuranceDate" />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <HorizontalColumnField label='Fiscale PK' name='fiscPk' type="text" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <HorizontalColumnField label='Verzekering Kost' name='insuranceCost' type="text" />
                </Col>
                <Col md={6}>
                  <HorizontalColumnField label='Fiscale PK Belasting' name='fiscPkTax' type="text" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="taxDate">Belasting Datum</Form.Label>
                    <Col sm={valueColWidth}>
                      <FormikDateField name="taxDate" />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <HorizontalColumnField label='Tankkaart' name='tankcard' />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <HorizontalColumnField label='aovkbel' name='aovkbel' />
                </Col>
                <Col md={6}>
                </Col>
              </Row>
              <FormSubmitButton />
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default VehicleAccountingInfo;