import util from "../../../utils/util";
import classNames from "classnames";
import moment from "moment";
import React from "react";

type DurationCellProps = {
  value?: string // finite duration in string formate
  className: string
}

const DurationCell: React.FC<DurationCellProps> = ({ value, className }) => {

  const minutes = moment.duration(value).asMinutes();
  const diffClass = minutes > 0 ? 'text-success' : minutes < 0 ? 'text-danger' : '';

  return (
    <td className={classNames(className, diffClass)}>
      { util.duration(value, false) }
    </td>
  );
};

export default DurationCell;
