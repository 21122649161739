import {ErrorMessage, useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import React from "react";
import {FormikValues} from "formik/dist/types";
import Select from "react-select";
import classNames from "classnames";
import {EmployeeType} from "../../../../libs/api/time/api-model";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import util from "../../../utils/util";

export interface SelectEmployeeTypeProps {
  name: string
  required?: boolean
}

type EmployeeTypeOption = {
  label: string,
  value: string
}

const toEmployeeTypeOption = (employeeType: EmployeeType) => ({value: employeeType, label: employeeType} as EmployeeTypeOption);

export const SelectEmployeeType: React.FC<SelectEmployeeTypeProps> = ({name, required, ...props}) => {

  const employeeTypeOptions: EmployeeTypeOption[] =
    pipe(["Internal", "External", "Interim" ],
      array.map(toEmployeeTypeOption)
    )

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const isInvalid= !!touched[name] && !!errors[name];

  const findOption = pipe(employeeTypeOptions,
    array.findFirst(o => {
      const originalValue = pipe(option.fromNullable(util.byString(values, name)),
        option.fold(
          () => '',
          (v) => v.toString()
        )
      );
      return o.value === originalValue;
    }),
    option.getOrElse(() => ({value: util.byString(values, name), label: util.byString(values, name)} as EmployeeTypeOption))
  )

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={employeeTypeOptions}
        id={`input_${name}`}
        onChange={option => setFieldValue(name, option?.value)}
        onBlur={handleBlur}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      <ErrorMessage name={name} component={Form.Control.Feedback} type='invalid' />
    </>
  );
};

export default SelectEmployeeType;