import axios, {AxiosResponse} from "axios";
import {
  AnalyticFleetCostReport,
  CostParameter,
  DailyPostCalculation,
  GeoDynamicsUser,
  MobilityPremium,
  Employee,
  EmployeeSummary,
  Activity,
  Company,
  CreateEmployeeRequest,
  SiteFleetCostReport,
  Refuel,
  VehicleCost,
  PagedList,
  AbsenceType,
  DailyPlanningItem,
  Role,
  PrestatieStatsYearly,
  PrestatieStatsMonthly,
  MonthlyPrestatie,
  PrestatiePatch,
  CalendarName,
  CalendarEntry,
  Registratie,
  DailyPlanning,
  DailyPlanningItemUpdate,
  Education,
  EducationAttendance,
  InterCompanyTimeReport,
  DailyPlanningVehicle,
  ProjectGroupRef,
  TimesheetEntry,
  TankFill,
  TankFillStats,
  GasType,
  MobilityPremiumReport,
  WorkUnitTimeReport
} from "../../libs/api/time/api-model";
import {Service} from "./Service";
import {Moment} from "moment";
import {DateRange, toUriQuery} from "../model/Common";

const http = axios.create(
  /*{
  baseURL: dalConfig.apiBaseUrl,
  auth: {
    username: dalConfig.username as string,
    password: dalConfig.password as string
  }
}*/);

function handleSuccess<T>(response: AxiosResponse<T>, onSuccess: (t: T) => void) {
  console.debug(`[${response.status}] ${response.statusText}`, response.data);
  onSuccess(response.data)
}
function handleError(error: any, onFailure: (reason: any) => void) {
  if (axios.isAxiosError(error)) {
    console.error(`[${error.response?.status}] ${error.response?.statusText}`, error.response);
    console.error(error.response);
  } else {
    console.error('Not an axios error !!');
    console.error(error);
  }
  onFailure(error);
}

const getAsService = <T>(url: string, params?: any) => (serviceStateHandler: (_: Service<T>) => void) => {
  serviceStateHandler({ status: 'loading' });
  http.get<T>(url, {params: params})
    .then((response) => serviceStateHandler({status: 'loaded', payload: response.data}))
    .catch((error) => serviceStateHandler({ status: 'error', error }));
};

const postAsService = <T, R>(url: string, value: T) => (serviceStateHandler: (_: Service<R>) => void) => {
  serviceStateHandler({ status: 'loading' });
  http.post<T, AxiosResponse<R>>(url, value)
    .then((response) => serviceStateHandler({status: 'loaded', payload: response.data}))
    .catch((error) => serviceStateHandler({ status: 'error', error }));
};

const putAsService = <T, R>(url: string, value: T) => (serviceStateHandler: (_: Service<R>) => void) => {
  serviceStateHandler({ status: 'loading' });
  http.put<T, AxiosResponse<R>>(url, value)
    .then((response) => serviceStateHandler({status: 'loaded', payload: response.data}))
    .catch((error) => serviceStateHandler({ status: 'error', error }));
};

const patchAsService = <T, R>(url: string, value: T) => (serviceStateHandler: (_: Service<R>) => void) => {
  serviceStateHandler({ status: 'loading' });
  http.patch<T, AxiosResponse<R>>(url, value)
    .then((response) => serviceStateHandler({status: 'loaded', payload: response.data}))
    .catch((error) => serviceStateHandler({ status: 'error', error }));
};

const deleteAsService = <T>(url: string) => (serviceStateHandler: (_: Service<T>) => void) => {
  serviceStateHandler({ status: 'loading' });
  http.delete<T>(url)
    .then((response) => serviceStateHandler({status: 'loaded', payload: response.data}))
    .catch((error) => serviceStateHandler({ status: 'error', error }));
};

const saveCostParameters = (value: CostParameter, onSuccess: (value: CostParameter) => void, onFailure: (reason: any) => void) =>
  http.post<CostParameter>(`/api/time/cost-parameters`, value)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onFailure));

const saveMobilityPremium = (value: MobilityPremium, onSuccess: (value: MobilityPremium) => void, onFailure: (reason: any) => void) =>
  http.post<MobilityPremium>(`/api/time/mobility-premiums`, value)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onFailure));

export const CostsParameters = {
  get: getAsService<CostParameter>(`/api/time/cost-parameters`),
  save: saveCostParameters,
};

export const MobilityPremiums = {
  get: getAsService<MobilityPremium[]>(`/api/time/mobility-premiums`),
  save: saveMobilityPremium,
};

export const PostCalculation = {
  dailyReport: (date: Moment) => getAsService<DailyPostCalculation>(`/api/time/postcalculation/${date.format('yyyy-MM-DD')}`),
};

export const Prestaties = {
  monthlyPrestaties: (employeeNr: number, year: number, month: number) => getAsService<MonthlyPrestatie[]>(`/api/time/prestaties/employee/${employeeNr}/period/${year}/${month}`),
  yearlyStats: (employeeNr: number, year: number) => getAsService<PrestatieStatsYearly>(`/api/time/prestaties/employee/${employeeNr}/period/${year}/stats/yearly`),
  monthlyStats: (employeeNr: number, year: number) => getAsService<PrestatieStatsMonthly[]>(`/api/time/prestaties/employee/${employeeNr}/period/${year}/stats/monthly`),
  update: (employeeNr: number, date: Moment) => (patch: PrestatiePatch) => putAsService<PrestatiePatch, PrestatiePatch>(`/api/time/prestaties/employee/${employeeNr}/period/${date.format('yyyy-MM-DD')}`, patch),
};

export const Timesheets = {
  daily: (employeeNr: number, date: Moment) => getAsService<TimesheetEntry[]>(`/api/time/timesheets/employee/${employeeNr}/period/${date.format('yyyy-MM-DD')}`),
  delete: (id: number) => deleteAsService<string>(`/api/time/timesheets/${id}`),
  update: (id: number, entry: TimesheetEntry) => putAsService<TimesheetEntry, string>(`/api/time/timesheets/${id}`, entry),
  create: (entry: TimesheetEntry) => postAsService<TimesheetEntry, string>(`/api/time/timesheets`, entry),
  syncGeoDynamics: (employeeNr: number, date: Moment) => postAsService<{}, string>(`/api/time/timesheets/employee/${employeeNr}/period/${date.format('yyyy-MM-DD')}/sync`, {}),
};

export const GeoDynamics = {
  users: (search: string) => getAsService<GeoDynamicsUser[]>(`/api/geo/users?search=${search}`),
};

export const Employees = {
  summaries: (activeOnly: boolean | undefined) => getAsService<EmployeeSummary[]>(`/api/time/employees/summary` + (activeOnly ? '?activeOnly=true' : '')),
  summary: (id: number) => getAsService<EmployeeSummary>(`/api/time/employees/summary/${id}`),
  list: getAsService<Employee[]>(`/api/time/employees`),
  get: (id: string) => getAsService<Employee>(`/api/time/employees/${id}`),
  create: (employee: CreateEmployeeRequest) => postAsService<CreateEmployeeRequest, string>(`/api/time/employees`, employee),
  update: (id: string, employee: Employee) => putAsService<Employee, Employee>(`/api/time/employees/${id}`, employee),
  delete: (id: string) => deleteAsService<Employee>(`/api/time/employees/${id}`),
  roles: getAsService<Role[]>(`/api/time/employees/roles`),
};

export const Activities = {
  list: getAsService<Activity[]>(`/api/time/activities`),
};

export const Companies = {
  list: getAsService<Company[]>(`/api/time/companies`),
};

export const Registraties = {
  list: getAsService<Registratie[]>(`/api/time/registraties`),
};

export const Reports = {
  fleetSite: (period: DateRange) => getAsService<SiteFleetCostReport>(`/api/reports/fleet/site/${toUriQuery(period)}`),
  fleetAnalytic: (period: DateRange) => getAsService<AnalyticFleetCostReport>(`/api/reports/fleet/analytic/${toUriQuery(period)}`),
  interCompanyTime: (period: DateRange) => getAsService<InterCompanyTimeReport[]>(`/api/reports/time/intercompany/${toUriQuery(period)}`),
  mobilityPremiums: (period: DateRange) => getAsService<MobilityPremiumReport>(`/api/reports/mobility/${toUriQuery(period)}`),
  workUnitTime: (period: DateRange) => getAsService<WorkUnitTimeReport>(`/api/reports/transport/${toUriQuery(period)}`),
};

export const Refuels = {
  list: (vehicleId: string) => getAsService<Refuel[]>(`/api/time/vehicles/${vehicleId}/refuels`),
  paged: (pageSize: number, pageIndex: number) => (vehicleId: string) =>
    getAsService<PagedList<Refuel>>(`/api/time/vehicles/${vehicleId}/refuels`, { pageIndex: pageIndex, pageSize: pageSize }),
  update: (id: number, refuel: Refuel) => putAsService<Refuel, Refuel>(`/api/time/refuel/${id}`, refuel),
  delete: (id: number) => deleteAsService<Refuel>(`/api/time/refuel/${id}`)
};

export const Vehicles = {
  delete: (vehicleId: string) => deleteAsService<string>(`/api/time/vehicles/${vehicleId}`),
  activate: (vehicleId: string) => patchAsService<Object, string>(`/api/time/vehicles/${vehicleId}/activate`, { }),
};

export const VehicleCosts = {
  list: (vehicleId: string) => getAsService<PagedList<VehicleCost>>(`/api/time/vehicles/${vehicleId}/costs`),
};

export const AbsenceTypes = {
  list: getAsService<AbsenceType[]>(`/api/time/absence-types`),
  update: (id: number, entity: AbsenceType) => putAsService<AbsenceType, AbsenceType>(`/api/time/absence-types/${id}`, entity),
  create: (entity: AbsenceType) => postAsService<AbsenceType, string>(`/api/time/absence-types`, entity),
  delete: (id: number) => deleteAsService<string>(`/api/time/absence-types/${id}`),
};

export const Planning = {
  daily: (date: Moment) => getAsService<DailyPlanning>(`/api/time/planning/daily/${date.format('yyyy-MM-DD')}`),
  employeeDaily: (date: Moment, employee: number) => getAsService<DailyPlanningItem[]>(`/api/time/planning/daily/${date.format('yyyy-MM-DD')}/employee/${employee.toString()}`),
  delete: (id: number) => deleteAsService<string>(`/api/time/planning/${id}`),
  update: (id: number, item: DailyPlanningItemUpdate) => putAsService<DailyPlanningItemUpdate, string>(`/api/time/planning/${id}`, item),
  create: (item: DailyPlanningItem) => postAsService<DailyPlanningItem, string>(`/api/time/planning/item`, item),
  vehicles: getAsService<DailyPlanningVehicle[]>(`/api/time/planning/vehicles`),
};

export const Calendars = {
  list: getAsService<CalendarName[]>(`/api/time/calendars`),
  entries: (calendarId: number, year: number) => getAsService<CalendarEntry[]>(`/api/time/calendars/${calendarId}/period/${year}`),
  upsert: (calendarId: number, date: Moment) => (registratie: Registratie) => putAsService<Registratie, string>(`/api/time/calendars/${calendarId}/period/${date.format('yyyy-MM-DD')}`, registratie),
  generateYear: (calendarId: number, year: number) => postAsService<string, string>(`/api/time/calendars/${calendarId}/period/${year}/generate`, ""),
};

export const Educations = {
  list: getAsService<Education[]>(`/api/time/educations`),
  get: (id: string) => getAsService<Education>(`/api/time/educations/${id}`),
  upsert: (education: Education) => putAsService<Education, string>(`/api/time/educations`, education),
  delete: (id: string) => deleteAsService<string>(`/api/time/educations/${id}`),
  attendancesByEducation: (educationId: string) => getAsService<EducationAttendance[]>(`/api/time/educations/${educationId}/attendances`),
  attendancesByEmployee: (employeeId: number) => getAsService<EducationAttendance[]>(`/api/time/employees/${employeeId}/educations`),
  upsertAttendance: (date: Moment, educationId: string, employeeId: number) => putAsService<string, string>(`/api/time/educations/${educationId}/attendances/date/${date.format('yyyy-MM-DD')}/employee/${employeeId.toString()}`, ""),
  deleteAttendance: (date: Moment, educationId: string, employeeId: number) => deleteAsService<string>(`/api/time/educations/${educationId}/attendances/date/${date.format('yyyy-MM-DD')}/employee/${employeeId.toString()}`),
};

export const Projects = {
  search: (prefix: String) => getAsService<ProjectGroupRef[]>(`/api/time/projects/search`, { prefix: prefix }),
};

export const TankFills = {
  paged: (pageSize: number, pageIndex: number) => (gasTypeId: number) =>
    getAsService<PagedList<TankFill>>(`/api/time/tankfills/${gasTypeId}`, { pageIndex: pageIndex, pageSize: pageSize }),
  stats: (gasTypeId: number) => getAsService<TankFillStats>(`/api/time/tankfills/${gasTypeId}/stats`),
  upsert: (tankFill: TankFill) => putAsService<TankFill, string>(`/api/time/tankfills`, tankFill),
  delete: (id: number) => deleteAsService<string>(`/api/time/tankfills/${id}`),
};

export const GasTypes = {
  list: getAsService<GasType[]>(`/api/time/gastypes`),
};
