import React from "react";
import util from "../../utils/util";
import moment from "moment/moment";
import { Page, Text, View, Document } from '@react-pdf/renderer';
import TableRows from "../components/TableRows";
//import Logo from "../../../../public/images/logo-ve-small.jpg"
import styles from "../components/ReportStyles";
import TableHeader from "../components/TableHeader";
import {DateRange, toLongFormat} from "../../model/Common";
import {ColumnDefinition} from "../components/PdfTypes";
import {
  EmployeeSummary,
  WorkUnitEmployeeEntry,
  WorkUnitTimeEntry,
  WorkUnitTimeReport
} from "../../../libs/api/time/api-model";
import {Style} from "@react-pdf/types";
import {EmployeeFunctions} from "../../libs/api/time/api-helpers";

type TransportTimeReportDocumentProps = {
  columns: ColumnDefinition<WorkUnitTimeEntry>[]
  data: WorkUnitTimeReport
  period: DateRange
}

const TransportTimeReportDocument: React.FC<TransportTimeReportDocumentProps> = (
  { columns, data, period }
) => (
  <Document>
    <Page size="A4" style={styles.page} orientation={"landscape"} wrap>
      {/*<Image style={styles.image} src={Logo} fixed />*/}
      {/*<Image style={styles.image} src='/logo-ve-small.jpg' fixed />*/}
      <ReportHeader period={period} />
      {util.isDefined(data) && util.isDefined(data.entries) && Array.isArray(data.entries) &&
        data.entries.map((entry, i) =>
          <EmployeeTablePart index={i} key={entry.employee.id} columns={columns} entry={entry} />
        )
      }
      <Text style={styles.date} fixed>{moment().format('YYYY-MM-DD hh:mm')}</Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

const ReportHeader: React.FC<{ period: DateRange }> = ({ period }) =>
  <View style={styles.header} fixed>
    <Text style={styles.title}>Prestaties Transport</Text>
    <Text style={styles.subtitle}>{ toLongFormat(period) }</Text>
  </View>

type EmployeeTablePartProps = {
  index: number
  columns: ColumnDefinition<WorkUnitTimeEntry>[]
  entry: WorkUnitEmployeeEntry
}

const EmployeeTablePart: React.FC<EmployeeTablePartProps> = (
  {
    index,
    columns,
    entry: { entries, employee, totalDuration, totalPause },
  }
) =>
  <View style={styles.section} break={index !== 0}>
    <Text style={styles.tabletitle} fixed>{ EmployeeFunctions.fullname(employee) }</Text>
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} style={styles.columnHeader} />
      <TableRows columns={columns} data={entries} style={styles.tableRow} />
      <TableFooter columns={columns}
                   employee={employee}
                   totalPause={util.duration(totalPause)}
                   totalDuration={util.duration(totalDuration)} />
    </View>
  </View>

type TableFooterProps = {
  "columns": ColumnDefinition<WorkUnitTimeEntry>[]
  "employee": EmployeeSummary,
  "totalPause": string,
  "totalDuration": string,
}

const TableFooter: React.FC<TableFooterProps> = (
  { columns, employee, totalPause, totalDuration }
) => {
  const colStyle = (i: number): Style[] => {
    const colStyleAtIndex = columns[columns.length - i].styles;
    return (colStyleAtIndex ? [colStyleAtIndex] : []);
  };
  return (
    <View style={styles.tableFooter}>
      <Text style={[{ width: '86%', textAlign: 'left' }, styles.columnFooter]}>Totaal voor { EmployeeFunctions.fullname(employee) }</Text>
      <Text style={ colStyle(2).concat([styles.columnFooter])}>{totalPause}</Text>
      <Text style={ colStyle(1).concat([styles.columnFooter])}>{totalDuration}</Text>
    </View>
  );
}

export default TransportTimeReportDocument;
