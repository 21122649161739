import React, {Props} from "react";
import * as Yup from "yup";
import {
  Formik,
  FormikHelpers,
  Form as FormikForm,
} from "formik";
import {
    Button,
    Col,
    Form,
    Modal,
    Row,
} from "react-bootstrap";
import HorizontalColumnField from "../../components/form/HorizontalColumnField";
import BooleanFormikField from "../../components/form/BooleanFormikField";
import FormSubmitButton from "../../components/form/FormSubmitButton";
import SelectActivityTime from "../../components/form/SelectActivityTime";
import InputMask from "react-input-mask";
import SelectProjectFormik from "../../components/form/SelectProjectFormik";
import SelectVehicleFormik from "../../components/form/SelectVehicleFormik";
import {Activity} from "../../../../libs/api/time/api-model";

const labelColWidth = 3;
const valueColWidth = 9;

export interface DailyPlanningItemControlNewProps {
  onSave: (values: CreatePlanningItemForm, formikHelpers: FormikHelpers<CreatePlanningItemForm>) => void | Promise<any>;
  modalOpen: boolean,
  onModalHide: () => void
  formItem?: CreatePlanningItemForm
}

const schema = Yup.object().shape({
  activity: Yup.object().required(),
  start: Yup.string().required(),
  projectGroupId: Yup.string().required(),
});

export interface CreatePlanningItemForm {
  activity?: Activity
  start?: string
  projectGroupId?: string
  description?: string
  licensePlate?: string
  isDriver?: boolean
}

export const newFormItem = (): CreatePlanningItemForm => ({ isDriver: false })

const DailyPlanningItemControlNew: React.FC<DailyPlanningItemControlNewProps> = ({
    onSave, modalOpen, onModalHide, formItem = newFormItem()
}) => (
  <Modal
    scrollable={true}
    size="lg"
    show={modalOpen}
    onHide={onModalHide}>
      <Formik
        validationSchema={ schema }
        initialValues={ formItem }
        onSubmit={ onSave }
      >
        {({
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            errors
          }) => (
          <FormikForm noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>Planning item aanmaken</Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="input_activity">
                  <strong>Activiteit *</strong>
                </Form.Label>
                <Col sm={valueColWidth}>
                  <SelectActivityTime name="activity" useOptionValue={false} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputStart">
                  <strong>Start *</strong>
                </Form.Label>
                <Col sm={valueColWidth}>
                  <InputMask mask="99:99"
                             className="mh-38"
                             value={values["start"]}
                             onChange={handleChange}
                             onBlur={handleBlur}
                             name="start"
                             id={`inputStart`}
                             isInvalid={!!errors["start"]}>
                    { (inputProps: Props) => (<Form.Control {...inputProps} isInvalid={!!errors["start"]} type="text"/>) }
                  </InputMask>
                  <Form.Control.Feedback type="invalid">{errors["start"]}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputProject">
                  <strong>Project *</strong>
                </Form.Label>
                <Col sm={valueColWidth}>
                  <SelectProjectFormik
                    name="projectGroupId"
                    required={true}
                    useOptionValue={true}
                  />
                  {/*
                    overrideSetter={(name, value) => {
                      console.log(`got project from select`, value);
                      setFieldValue(name, value)
                    }}
                    */}
                  <small className="text-muted">geef min 3 karakters om te zoeken</small>
                </Col>
              </Form.Group>
              <HorizontalColumnField label="Omschrijving"
                                     name='description'
                                     labelColWidth={labelColWidth}
                                     valueColWidth={valueColWidth} />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="input_licensePlate">Voertuig</Form.Label>
                <Col sm={valueColWidth}>
                  <SelectVehicleFormik name="licensePlate" useOptionValue={true} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                  <Col sm={labelColWidth}></Col>
                  <Col sm={valueColWidth}>
                    <BooleanFormikField label='Bestuurder' name='isDriver' />
                  </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant={"secondary"} onClick={onModalHide}>Annuleren</Button>
              {' '}
              <FormSubmitButton />
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
  </Modal>
)

export default DailyPlanningItemControlNew;
