import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet-async";
import {Card, Container, Col, Row, Table} from "react-bootstrap";
import moment from "moment";
import util from "../../utils/util";
import {DateRange, equalDates, parseRange, toUriQuery} from "../../model/Common";
import DateRangePicker from "../components/DateRangePicker";
import EmptyResult from "../components/EmptyResult";
import {useNavigate, useParams} from "react-router-dom";
import {Service} from "../../services/Service";
import {InterCompanyTimeReport} from "../../../libs/api/time/api-model";
import {Reports} from "../../services/TimeApi";
import ErrorHandlingTS from "../../pages/components/ErrorHandlingTS";

const InterCompanyReport: React.FC = () => {

  const navigate = useNavigate();
  const {from, to} = useParams();
  const [period, setPeriod] = useState<DateRange>(parseRange(from, to));
  const [interCompanyTimes, setInterCompanyTimes] = useState<Service<InterCompanyTimeReport[]>>({ status: 'init' });

  const loadReport = () => Reports.interCompanyTime(period)(setInterCompanyTimes);

  useEffect(() => navigate(`/reports/intercompany/${toUriQuery(period)}`), [ navigate, period ]);
  useEffect(loadReport, [ period ]);

  const onDateSet: (_: DateRange) => void = (range: DateRange) => {
    if (!equalDates(period, range)) {
      setPeriod(range)
    }
  }

  return (
    <>
      <Helmet title="Intercompany Time Report" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Inter company prestaties</h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <DateRangePicker onSuccess={onDateSet} initialRange={period} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ErrorHandlingTS service={interCompanyTimes} onLoaded={(data) =>
          <>
          {util.isDefined(data) && data.length === 0 && <EmptyResult />}
          {util.isDefined(data) && data.map(({employeeSite, entries}) =>
            <Card key={ employeeSite.id }>
              <Card.Header>
                <Card.Title className="mb-0">
                  <h5>{ employeeSite.name }</h5>
                </Card.Title>
              </Card.Header>

              {entries.map(({ projectSite, totalPause, totalDuration, totalKm, entries}) =>
                <React.Fragment key={`${employeeSite.id}-${projectSite.id}`}>
                  <Card.Body className='mt-5'>
                    <Card.Subtitle><h5>{ employeeSite.name }{' > '}{ projectSite.name }</h5></Card.Subtitle>
                  </Card.Body>
                  <Table hover bordered size="sm" className="my-0">
                    <thead>
                    <tr>
                      <th>Datum</th>
                      <th colSpan={3}>Project</th>
                      <th className="text-center">Begin</th>
                      <th className="text-center">Eind</th>
                      <th className="text-center">Pauze</th>
                      <th className="text-end">Km</th>
                      <th className="text-center">Prestatie</th>
                      <th className="text-end">Mobiliteit</th>
                    </tr>
                    </thead>
                    <tbody>
                      {entries.map(({employeeName, entries}) =>
                        <React.Fragment key={`employee-${employeeName.replaceAll(" ", "_").toLowerCase().trim()}`}>
                        <tr className='table-active'>
                          <td colSpan={10} className='fw-bolder'>{ employeeName }</td>
                        </tr>
                        {entries.map(({
                          date,
                          begin,
                          end,
                          pause,
                          duration,
                          km,
                          driver,
                          mobilityPremium,
                          projectGroup,
                        }, i) =>
                          <tr key={`employee-${employeeName.replaceAll(" ", "_").toLowerCase().trim()}-${date}-${projectGroup?.groupId}-${begin}-${i}`}>
                            <td>{date}</td>
                            <td>{projectGroup.groupId}</td>
                            <td>
                              {util.isDefined(projectGroup.company) ? projectGroup.company.split(' ')[0] : ''}&nbsp;
                              {util.isDefined(projectGroup.location) ? projectGroup.location.split(' ')[0] : ''}
                            </td>
                            <td>{projectGroup.description}</td>
                            <td className="text-center">{ moment(begin, 'HH:mm').format('HH:mm') }</td>
                            <td className="text-center">{ moment(end, 'HH:mm').format('HH:mm') }</td>
                            <td className="text-center">{ util.duration(pause) }</td>
                            <td className="text-end">{ util.formatInt(km) }</td>
                            <td className="text-center">{ util.duration(duration) }</td>
                            <td className="text-end">{ mobilityPremium }</td>
                          </tr>
                          )}
                        </React.Fragment>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={6}>Totaal site-vreemde prestaties van <strong>{employeeSite.name}</strong> voor <strong>{projectSite.name}</strong></td>
                        <td className="text-center fw-bolder">{ util.duration(totalPause) }</td>
                        <td className="text-end fw-bolder">{ util.formatInt(totalKm) }</td>
                        <td className="text-center fw-bolder">{ util.duration(totalDuration) }</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </Table>
                </React.Fragment>
              )}
            </Card>
          )}
          </>
        } />
      </Container>
    </>
  );
}

export default InterCompanyReport;
