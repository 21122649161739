import React from "react";
import { useTable, useSortBy } from "react-table";

import {
  Table,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSort,
    faSortUp,
    faSortDown,
} from "@fortawesome/free-solid-svg-icons";

const defaultPropGetter = () => ({})

const SortableTable = ({columns, data, getRowProps = defaultPropGetter, ...props}) => {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <Table className="my-0" {...props} {...getTableProps()}>
      <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            // Add the sorting props to control sorting. For this example
            // we can add them into the header props
            <th {...column.getHeaderProps(column.getSortByToggleProps([{
              className: column.className,
              style: { minWidth: column.minWidth, width: column.width },
            }]))}>
              {column.render("Header")}
              {/* Add a sort direction indicator */}
              <span>
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <FontAwesomeIcon icon={faSortUp} className="ms-2"/>
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} className="ms-2"/>
                  )
                ) : (
                  <FontAwesomeIcon icon={faSort} className="ms-2"/>
                )}
              </span>
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps(getRowProps(row))}>
            {row.cells.map((cell) => {
              return (
                <td {...cell.getCellProps([{
                    className: cell.column.className,
                    style: { minWidth: cell.column.minWidth, width: cell.column.width },
                  }])}>{cell.render("Cell")}</td>
              );
            })}
          </tr>
        );
      })}
      {rows && rows.length === 0 &&
        <tr role='row'>
          <td role='cell' colSpan={headerGroups[0].headers.length}>
            <p className='fst-italic my-1'>Geen resultaten gevonden</p>
          </td>
        </tr>
      }
      </tbody>
    </Table>
  );
};

export default SortableTable;
