import moment, {Moment} from "moment/moment";

export type FormState = "create" | "update"
export type AccessRight = "reports" | "infra" | "time"

export interface TokenUser {
  user: string
  roles: string[]
  accessRights: string[]
}

export type DateRange = {
  from: Moment
  to: Moment
}

export const toShortFormat = (range: DateRange) =>
  `${range.from.format('yyyy-MM-DD')}-${range.to.format('yyyy-MM-DD')}`;

export const toMediumFormat = (range: DateRange) =>
  `${range.from.format('yyyy-MM-DD')} > ${range.to.format('yyyy-MM-DD')}`;

export const toLongFormat = (range: DateRange) =>
  `van ${range.from.format('yyyy-MM-DD')} tot ${range.to.format('yyyy-MM-DD')}`;

export const toUriQuery = (range: DateRange) =>
  `from/${range.from.format('yyyy-MM-DD')}/to/${range.to.format('yyyy-MM-DD')}`;

export const currentMonthRange = () => (
  { from: moment().startOf('month'), to: moment().endOf('month') } as DateRange
);

export const fromStringDates = (from: string, to: string) => (
  { from: moment(from), to: moment(to) } as DateRange
);

export const equalDates: (o: DateRange, t: DateRange) => boolean = (one: DateRange, two: DateRange) => (
  one.from.format('yyyy-MM-DD') === two.from.format('yyyy-MM-DD') &&
    one.to.format('yyyy-MM-DD') === two.to.format('yyyy-MM-DD')
);

export const parseRange = (from: string | undefined, to: string | undefined) => (
  from !== undefined && to !== undefined && moment.isMoment(moment(from)) && moment.isMoment(moment(to)) ?
    fromStringDates(from, to) : currentMonthRange()
);
