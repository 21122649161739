import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, ButtonToolbar, Card, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import util from "../../../../utils/util";
import 'moment/locale/nl-be';
import {Edit} from "react-feather";
import DurationCell from "../../../components/tables/DurationCell";
import moment from "moment";
import {Service} from "../../../../services/Service";
import ErrorHandlingTableRow from "../../../components/ErrorHandlingTableRow";
import {MonthlyPrestatie} from "../../../../../libs/api/time/api-model";
import {Prestaties} from "../../../../services/TimeApi";
import ActivityBadgeNew from "../../../components/ActivityBadgeNew";

export type PresenceTableProps = {
  employeeNr: number
  year: number
  month: number
  onEdit: (prestatie: MonthlyPrestatie) => void
}

const titleCaseWord = (word: string | undefined) => (
  word && word.length >= 1 ? word[0].toUpperCase() + word.substring(1) : word
);

const PresenceTable: React.FC<PresenceTableProps> = (
  { employeeNr, year, month, onEdit }
) => {

  const [entries, setEntries] = useState<Service<MonthlyPrestatie[]>>({ status: 'init' });

  const loadEntries = () => Prestaties.monthlyPrestaties(employeeNr, year, month + 1)(setEntries);

  useEffect(loadEntries, [ employeeNr, year, month ]);

  return (
    <Card className="flex-fill w-100">
      <Card.Header className="pb-0">
        <Card.Title className="mb-0">
          <h4>{ titleCaseWord(moment().month(month).format('MMMM')) }</h4>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table hover size="sm" className="my-0">
          <thead>
          <tr>
            <th>Datum</th>
            <th className="d-none d-md-table-cell text-center">Activiteit</th>
            <th className="text-center">
              <OverlayTrigger overlay={<Tooltip>Te Presteren</Tooltip>}>
                <span>TP</span>
              </OverlayTrigger>
            </th>
            <th className="text-center">
              <OverlayTrigger overlay={<Tooltip>Gepresteerd</Tooltip>}>
                <span>GP</span>
              </OverlayTrigger>
            </th>
            <th className="d-none d-xl-table-cell text-center">
              <OverlayTrigger overlay={<Tooltip>Afboeken</Tooltip>}>
                <span>AB</span>
              </OverlayTrigger>
            </th>
            <th className="d-none d-xl-table-cell text-center">
              <OverlayTrigger overlay={<Tooltip>Verschil</Tooltip>}>
                <span>Diff</span>
              </OverlayTrigger>
            </th>
            <th className="d-none d-xl-table-cell text-end">Premie</th>
            <th className="d-none d-xl-table-cell">Memo</th>
            <th className="d-none d-xl-table-cell text-end">Acties</th>
          </tr>
          </thead>
          <tbody>
          { /* todo kolom breedtes */ }
          <ErrorHandlingTableRow colspan={7} service={entries} onLoaded={(data: MonthlyPrestatie[]) => (
            util.isDefined(data) && data.map((item) => {
              const activityClass = util.isDefined(item.value) && item.value?.code === 'W' ? 'table-light' : '';
              return (
                <tr key={item.date} className={activityClass}>
                  <td>{item.date}</td>
                  <td className="d-none d-md-table-cell text-center">
                    {item.value && <ActivityBadgeNew as="span" activity={item.value}/>}
                  </td>
                  <td className="text-center">{util.duration(item.tp, false)}</td>
                  <td className="text-center">{util.duration(item.gp, false)}</td>
                  <td className="text-center">{util.duration(item.ab, false)}</td>
                  <DurationCell className='d-none d-xl-table-cell text-center' value={item.diff} />
                  <td className="d-none d-xl-table-cell text-end">{item.premie}</td>
                  <td className="d-none d-xl-table-cell">{item.memo}</td>
                  <td className="d-none d-xl-table-cell text-end">
                    <ButtonToolbar className="justify-content-end">
                      <ButtonGroup size={"sm"} vertical={false}>
                        <Button variant="link" size={"sm"} className="p-0" onClick={_ => onEdit(item)}>
                          <Edit className="me-1" size={18}/>
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </td>
                </tr>
              );
              }
            ))}
          />
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default PresenceTable;
