import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Edit, Trash} from "react-feather";
import React from "react";

const EditButton = ({value, handleEdit}) =>
  <Button variant="link" size={"sm"} className="p-0" onClick={_ => handleEdit(value)}>
    <Edit className="me-1" size={18} />
  </Button>

const DeleteButton = ({value, handleDelete}) =>
  <Button variant="link" size="sm" className="text-danger p-0" onClick={_ => handleDelete(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const Actions = ({value, handleEdit, handleDelete}) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <EditButton value={value} handleEdit={handleEdit} />
      <DeleteButton value={value} handleDelete={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const mobilityPremiumColumns = (handleEdit, handleDelete) => [
  {
    Header: "#",
    accessor: "id",
    className: "text-end",
  },
  {
    Header: "Aantal Km",
    className: "text-end",
    accessor: "kmUnit",
  },
  {
    Header: "Bestuurder",
    className: "text-end",
    accessor: "driverFee",
  },
  {
    Header: "Passagier",
    className: "text-end",
    accessor: "passengerFee",
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    accessor: 'id',
    width: "20%",
    Cell: ({value}) => <Actions value={value} handleEdit={handleEdit} handleDelete={handleDelete} />
  }
];

export { mobilityPremiumColumns };
