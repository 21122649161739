import React, {Props} from "react";
import * as Yup from "yup";
import {
  Formik,
  FormikHelpers,
  Form as FormikForm,
} from "formik";
import {
    Button,
    Col,
    Form,
    Modal,
    Row,
} from "react-bootstrap";
import HorizontalColumnField from "../../components/form/HorizontalColumnField";
import BooleanFormikField from "../../components/form/BooleanFormikField";
import FormSubmitButton from "../../components/form/FormSubmitButton";
import {DailyPlanningItemUpdate} from "../../../../libs/api/time/api-model";
import SelectActivityTime from "../../components/form/SelectActivityTime";
import InputMask from "react-input-mask";
import util, {numberOrNull} from "../../../utils/util";
import SelectProjectFormik from "../../components/form/SelectProjectFormik";
import SelectVehicleFormik from "../../components/form/SelectVehicleFormik";

const labelColWidth = 3;
const valueColWidth = 9;

export interface DailyPlanningItemControlProps {
  item: DailyPlanningItemUpdate,
  onSave: (values: DailyPlanningItemUpdate, formikHelpers: FormikHelpers<DailyPlanningItemUpdate>) => void | Promise<any>;
  modalOpen: boolean,
  onModalHide: () => void
}

const schema = Yup.object().shape({
  activityRef: Yup.object().required(),
  projectRef: Yup.object().required(),
  start: Yup.string().required(),
  description: Yup.string().required(),
});

const normalizeValues = (values: DailyPlanningItemUpdate) => {
  return {
    ...values,
    start: util.localTimeToHours({ value: values.start })
  };
};

const deNormalizeValues = (values: DailyPlanningItemUpdate) => {
  return { ...values,
    projectGroupId: values.projectRef?.groupId,
    activity: numberOrNull(values.activityRef?.id),
  };
};

const DailyPlanningItemControl: React.FC<DailyPlanningItemControlProps> = ({
    item, onSave, modalOpen, onModalHide
}) => (
  <Modal
    scrollable={true}
    size="lg"
    show={modalOpen}
    onHide={onModalHide}>
      <Formik
        validationSchema={schema}
        initialValues={normalizeValues(item)}
        onSubmit={(values, formikHelpers) => onSave(deNormalizeValues(values), formikHelpers)}
      >
        {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors
          }) => (
          <FormikForm noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>Planning item aanpassen</Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputActivity">
                  <strong>Activiteit *</strong>
                </Form.Label>
                <Col sm={valueColWidth}>
                  <SelectActivityTime name="activityRef" useOptionValue={false} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputStart">
                  <strong>Start *</strong>
                </Form.Label>
                <Col sm={valueColWidth}>
                  <InputMask mask="99:99"
                             className="mh-38"
                             value={ values["start"] }
                             onChange={handleChange}
                             onBlur={handleBlur}
                             name="start"
                             id={`inputStart`}
                             isInvalid={!!errors["start"]}>
                    { (inputProps: Props) => ( <Form.Control {...inputProps} isInvalid={!!errors["start"]} type="text"/> ) }
                  </InputMask>
                  <Form.Control.Feedback type="invalid">{errors["start"]}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputProject">
                  <strong>Project *</strong>
                </Form.Label>
                <Col sm={valueColWidth}>
                  <SelectProjectFormik
                    name="projectRef"
                    required={true}
                    useOptionValue={false}
                  />
                  <small className="text-muted">geef min 3 karakters om te zoeken</small>
                </Col>
              </Form.Group>
              <HorizontalColumnField label="Omschrijving" name='description' labelColWidth={labelColWidth} valueColWidth={valueColWidth} required />
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputLicensePlate">Voertuig</Form.Label>
                <Col sm={valueColWidth}>
                  <SelectVehicleFormik name="licensePlate" useOptionValue={true} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                  <Col sm={labelColWidth}></Col>
                  <Col sm={valueColWidth}>
                    <BooleanFormikField label='Bestuurder' name='driver' />
                  </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant={"secondary"} onClick={onModalHide}>Annuleren</Button>
              {' '}
              <FormSubmitButton />
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
  </Modal>
)

export default DailyPlanningItemControl;
