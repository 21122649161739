import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Select, {MultiValue} from "react-select";
import classNames from "classnames";
import {Service, toOption} from "../../../services/Service";
import {Role} from "../../../../libs/api/time/api-model";
import {Employees} from "../../../services/TimeApi";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import {useFormikContext} from "formik";
import {FormikValues} from "formik/dist/types";
import util from "../../../utils/util";

export interface SelectRolesFormikProps {
  name: string
}

type RoleOption = {
  value: number
  label: string,
}

const toRoleOption = (role: Role) => (
  { value: role.id, label: role.name } as RoleOption
);

export const SelectRoles: React.FC<SelectRolesFormikProps> = ({
    name, ...props
}) => {

  const [roles, setRoles] = useState<Service<Role[]>>({ status: 'init' });
  const loadEntries = () => Employees.roles(setRoles);
  useEffect(loadEntries, []);

  const roleOptions: RoleOption[] =
    pipe(roles,
      s => toOption(s),
      option.map(array.map(toRoleOption)),
      option.getOrElse(() => [] as RoleOption[]),
    );

  const toNumArray: (a: any) => number[] = (x: any) => x as number[];

  const findOptions = () => {
    const value: number[] = pipe(util.byString(values, name),
      option.fromNullable,
      option.fold(
        () => [],
        (v) => toNumArray(v)
      )
    );
    return pipe(roleOptions,
      array.filter(o =>
        pipe(value, array.exists(v => o.value === v))
      )
    );
  }

  const findRoleIds = (selected: MultiValue<RoleOption>) => selected.map(o => o.value);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const isInvalid= !!touched[name] && !!errors[name];
  const hasDataError = roles.status === "error";

  return (
    <>
      <Select
        isMulti
        className={classNames("react-select-container", isInvalid ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={roleOptions}
        isLoading={roles.status === "loading"}
        id='input_roles_select'
        onChange={option => setFieldValue(name, findRoleIds(option))}
        onBlur={handleBlur}
        getOptionValue={option => option.value.toString()}
        getOptionLabel={option => option.label}
        value={findOptions()}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      { hasDataError &&
          <Form.Control.Feedback type="invalid">
            {`Fout bij het ophalen van de rollen: ${roles.error ? roles.error.message : ' (fout onbekend)'}`}
          </Form.Control.Feedback>
      }
      { !!util.byString(errors, name) &&
          <Form.Control.Feedback type="invalid">
            {util.byString(errors, name)}
          </Form.Control.Feedback>
      }
    </>
  );
};

export default SelectRoles;
