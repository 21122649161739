import React from "react";

import SidebarNavSection from "./SidebarNavSection";
import useAuth from "../../hooks/useAuth";
import util from "../../utils/util";

const SidebarNav = ({ items }) => {
  const { isAuthorized } = useAuth();
  return (
    <ul className="sidebar-nav">
      {items &&
        items.filter((i) => (!util.isDefined(i.subject) || isAuthorized(i.subject))).map((item) => (
          <SidebarNavSection
            key={item.title}
            pages={item.pages}
            title={item.title}
          />
        ))}
    </ul>
  );
};

export default SidebarNav;
