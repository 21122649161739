import React, {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {Card, Col, Row} from "react-bootstrap";
import DatePickerWithNavigation from "../../../components/DatePickerWithNavigation";

type SelectorProps = {
  value: Moment,
  onChange: (value: Moment | string) => void;
}

const Selectors: React.FC<SelectorProps> = ({value, onChange}) => {
  const [date, setDate] = useState<Moment>(value);
  useEffect(() => setDate(value), [ value ]);

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <Row>
            <Col md={3}>
              <DatePickerWithNavigation
                input={true}
                // initialValue={moment()}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect={true}
                locale="nl-be"
                inputProps={{
                  className: "form-control mh-38 text-center",
                }}
                value={date}
                onChange={(selected) => {
                  if (moment.isMoment(selected)) {
                    setDate(selected);
                  }
                  onChange(selected);
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );

}

export default Selectors;
