import React from 'react';
import Select from 'react-select';
import DalService from "../../../services/DalService";
import classNames from "classnames";
import util from "../../../utils/util";

class SelectActivity extends React.Component {

  handleChange = value => {
    this.props.onChange(this.props.name, value);
  };

  getLabel = ({id, name}) => (`${id} - ${name}`);
  getValue = (option) => (option.id);

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: true,
    };
  }

  async componentDidMount() {
    DalService.getActivities(
      (entities) => {
        this.setState({
          options: entities,
          loading: false,
        });
      },
      (error) => {
        this.setState({
          options: [],
          loading: false,
        });
      }
    );
  }

  render() {
    const { id, label, error, isInvalid, value, onChange, onBlur, optionFilter, ...props } = this.props;
    const activity = (typeof value === 'string') ? this.state.options.find(a => a.id === value) : value;

    return (
      <Select
        className={classNames("react-select-container", !!isInvalid ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={util.isDefined(optionFilter) ? this.state.options.filter(optionFilter) : this.state.options}
        isLoading={this.state.loading}
        id={id}
        onChange={this.handleChange}
        getOptionValue={this.getValue}
        getOptionLabel={this.getLabel}
        value={activity}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
    );
  }
}

export default SelectActivity;
