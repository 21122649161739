import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FormikValues} from "formik/dist/types";
import Select from "react-select";
import classNames from "classnames";
import {Service, toOption} from "../../../services/Service";
import {CalendarName} from "../../../../libs/api/time/api-model";
import {Calendars} from "../../../services/TimeApi";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import util, {numberOrNull} from "../../../utils/util";

export interface SelectCalendarFormikProps {
  name: string
  required?: boolean
  disabled?: boolean
  useOptionValue?: boolean // geef de value van de option door bij selectie, of de hele option
}

type CalendarOption = {
  label: string,
  value: string
}

const getId = (calendar: CalendarName) => calendar.id.toString();

const toCalendarOption = (calendar: CalendarName) => (
  { value: getId(calendar), label: calendar.name } as CalendarOption
);
const emptyOption = () => ({value: "", label: "Maak een keuze"} as CalendarOption);

export const SelectCalendarFormik: React.FC<SelectCalendarFormikProps> = ({
    name, required, useOptionValue = false, disabled = false, ...props
}) => {

  const [calendars, setCalendars] = useState<Service<CalendarName[]>>({ status: 'init' });
  const loadEntries = () => Calendars.list(setCalendars);
  useEffect(loadEntries, []);

  const calendarOptions: CalendarOption[] =
    pipe(calendars,
      s => toOption(s),
      option.map(array.map(toCalendarOption)),
      option.getOrElse(() => [] as CalendarOption[]),
    );

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const isInvalid= !!util.byString(errors, name);
  const hasDataError = calendars.status === "error";

  const findOption = pipe(calendarOptions,
    array.findFirst(o => {
      const originalValue = pipe(option.fromNullable(util.byString(values, name)),
        option.fold(
          () => '',
          (v) => v.toString()
        )
      );
      return o.value === originalValue;
    }),
    option.getOrElse(() => emptyOption())
  )

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid || hasDataError ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={calendarOptions}
        isLoading={calendars.status === "loading"}
        id={`input_${name}`}
        onChange={option => setFieldValue(name, useOptionValue ? numberOrNull(option?.value) : option)}
        onBlur={handleBlur}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        isDisabled={disabled}
        {...props}
      />
      {
        hasDataError && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van de firma's: ${calendars.error ? calendars.error.message : '?'}`}</Form.Control.Feedback>
      }
      {!!util.byString(errors, name) && <Form.Control.Feedback type="invalid">{util.byString(errors, name)}</Form.Control.Feedback>}
    </>
  );
};

export default SelectCalendarFormik;
