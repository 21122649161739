import {ErrorMessage, useFormikContext} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import React from "react";
import {FormikValues} from "formik/dist/types";

export interface HorizontalColumnFieldProps {
  label: string
  name: string
  required?: boolean
  labelColWidth?: number
  valueColWidth?: number
}

export const HorizontalColumnField: React.FC<HorizontalColumnFieldProps> =
  ({
     label, name, required, labelColWidth = 4, valueColWidth = 8, ...props
  }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();
  return (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor={`input${name}`}>
        {required ? <strong>{label} *</strong> : label }
      </Form.Label>
      <Col sm={valueColWidth}>
        <Form.Control
          {...props}
          name={name}
          id={`input${name}`}
          value={values[name]}
          //onChange={handleChange}
          onChange={(e) => {
            const parsed = e.currentTarget.value.replaceAll(',', '.');
            setFieldValue(name, parsed, true);
          }}
          onBlur={handleBlur}
          isInvalid={!!touched[name] && !!errors[name]}
          className='mh-38'
        />
        <ErrorMessage name={name} component={Form.Control.Feedback} type='invalid' />
      </Col>
    </Form.Group>
  );
}

export default HorizontalColumnField;