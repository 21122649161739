import React from 'react';
import Select from 'react-select';
import DalService from "../../../services/DalService";
import classNames from "classnames";

class SelectMaintenanceType extends React.Component {

  handleChange = value => {
    this.props.onChange('maintenanceType', value);
  };

  handleBlur = () => {
    this.props.onBlur('maintenanceType', true);
  };

  getLabel = ({id, name}) => (name);
  getValue = (option) => (option.id);

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: true,
    };
  }

  async componentDidMount() {
    DalService.getMaintenanceTypes(
      (entities) => {
        this.setState({
          options: entities,
          loading: false,
        });
      },
      (error) => {
        this.setState({
          options: [],
          loading: false,
        });
      }
    );
  }

  render() {
    const { id, label, error, isInvalid, value, onChange, onBlur, ...props } = this.props;
    return (
      <Select
        className={classNames("react-select-container", !!isInvalid ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={this.state.options}
        isLoading={this.state.loading}
        id={id}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        getOptionValue={this.getValue}
        getOptionLabel={this.getLabel}
        value={value}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
    );
  }
}

export default SelectMaintenanceType;
