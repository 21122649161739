export const dalConfig = {
  baseUrl: process.env.REACT_APP_DAL_BASE_URL,
  apiBaseUrl: `${process.env.REACT_APP_DAL_BASE_URL}/api`,
  username: process.env.REACT_APP_DAL_USERNAME,
  password: process.env.REACT_APP_DAL_PASSWORD,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};
