import React from "react";
import {
  Button,
  Card,
} from "react-bootstrap";
import DalService from "../../../services/DalService";
import {PlusCircle} from "react-feather";
import { costColumns } from "./costColumns.js";
import VehicleCostControl from "./VehicleCostControl";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import ErrorHandling from "../../components/ErrorHandling";
import PageableTable from "../../components/PageableTable";
import moment from "moment";

class VehicleCosts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      costs: {
        data: [],
        error: null,
        loaded: false,
      },
      showNewCost: false,
      showEditCost: false,
      editCostId: null,
      showDeleteCost: false,
      deleteCostId: null,
      deletingCost: false,
      deleteCostError: null
    };
    this.toggleNewCostModal = this.toggleNewCostModal.bind(this);
    this.toggleEditCostModal = this.toggleEditCostModal.bind(this);
    this.toggleDeleteCostModal = this.toggleDeleteCostModal.bind(this);
    this.saveNewCost = this.saveNewCost.bind(this);
    this.saveCost = this.saveCost.bind(this);
    this.deleteCost = this.deleteCost.bind(this);
  }

  async componentDidMount() {
    this.loadCosts();
  }

  loadCosts() {
    DalService.getVehicleCosts(this.props.vehicleId,
      (entities) => {
        this.setState({
          costs: {
            data: entities,
            loaded: true,
            error: null,
          }
        })
      },
      (error) => {
        this.setState({
          costs: {
            data: [],
            loaded: true,
            error: error,
          }
        })
      }
    );
  }

  findVehicleCost(id) {
    return this.state.costs.data.find(c => c.id === id);
  }

  showEditVehicleDialog = (value) => {
    console.debug('show edit vehicle cost dialog', value);
    this.toggleEditCostModal(value);
  }

  showDeleteVehicleDialog = (value) => {
    console.debug('show delete vehicle cost dialog', value);
    this.toggleDeleteCostModal(value);
  }

  toggleNewCostModal() {
    this.setState((state, _) => ({
      showNewCost: !state.showNewCost
    }));
  }

  toggleEditCostModal(vehicleCostId) {
    this.setState((state, _) => ({
      showEditCost: !state.showEditCost,
      editCostId: vehicleCostId
    }));
  }

  toggleDeleteCostModal(vehicleCostId) {
    this.setState((state, _) => ({
      showDeleteCost: !state.showDeleteCost,
      deleteCostId: vehicleCostId
    }));
  }

  saveNewCost(cost, formikBag) {
    DalService.newVehicleCost(this.props.vehicleId, { ...cost, vehicleId: this.props.vehicleId, costDate: cost.costDate.format('yyyy-MM-DD')},
      (entities) => {
        formikBag.setFieldTouched("submit", true);
        this.loadCosts();
        this.toggleNewCostModal();
      },
      (error) => {
        formikBag.setFieldError("submit", error.toString());
      }
    );
  }

  saveCost(cost, formikBag) {
    DalService.updateVehicleCost(this.props.vehicleId, { ...cost, vehicleId: this.props.vehicleId, costDate: cost.costDate.format('yyyy-MM-DD')},
      (result) => {
        formikBag.setFieldTouched("submit", true);
        this.loadCosts();
        this.toggleEditCostModal(null);
      },
      (error) => {
        formikBag.setFieldError("submit", error.toString());
      }
    );
  }

  deleteCost() {
    console.debug('deleting cost', this.state.deleteCostId);
    this.setState((state, _) => ({
      deletingCost: true,
    }), () => {
      DalService.deleteVehicleCost(this.props.vehicleId, this.state.deleteCostId,
        (_) => {
          this.setState({
            deleteCostError: null,
            deletingCost: false
          });
          this.loadCosts();
          this.toggleDeleteCostModal(null);
        },
        (error) => {
        console.debug(`deleting cost ${this.state.deleteCostId} failed`, error);
          this.setState({
            deleteCostError: error,
            deletingCost: false
          });
        }
      );
    });
  }

  render() {
    const {error, loaded, data} = this.state.costs;
    return (
      <React.Fragment>
        <Card className="mb-0">
          <Card.Header>
            <div className="card-actions float-end">
              <Button variant="success" onClick={this.toggleNewCostModal}>
                <PlusCircle className="me-1" />Toevoegen
              </Button>
            </div>
            <Card.Title tag="h5" className="mb-0">Kosten</Card.Title>
          </Card.Header>
          <Card.Body>
            <ErrorHandling error={error} loading={!loaded}>
              <PageableTable data={data} columns={costColumns(this.showEditVehicleDialog, this.showDeleteVehicleDialog)} />
            </ErrorHandling>
          </Card.Body>
        </Card>
        <VehicleCostControl title={`Kost toevoegen voor voertuig ${this.props.vehicleId}`}
                            open={this.state.showNewCost}
                            onToggle={this.toggleNewCostModal}
                            onSave={this.saveNewCost}
                            initialFormValues={{
                   costDate: moment(),
                   reference: '',
                   description: '',
                   amount: '',
                 }}
        />
        <VehicleCostControl title={`Kost aanpassen voor voertuig ${this.props.vehicleId}`}
                            open={this.state.showEditCost}
                            onToggle={this.toggleEditCostModal}
                            onSave={this.saveCost}
                            initialFormValues={this.findVehicleCost(this.state.editCostId)}
        />
        <ConfirmationDialog title={`Kost verwijderen voor voertuig ${this.props.vehicleId}`}
                            open={this.state.showDeleteCost}
                            loading={this.state.deletingCost}
                            error={this.state.deleteCostError}
                            onCancel={_ => this.toggleDeleteCostModal(null)}
                            onConfirm={this.deleteCost}>
          <p>Ben je zeker dat je deze kost wil verwijderen?</p>
        </ConfirmationDialog>
      </React.Fragment>
    );
  }
}

export default VehicleCosts;