import React, {ReactElement, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Card, Col, Container, Row, ListGroup, Spinner} from "react-bootstrap";

import util from "../../utils/util";
import moment, { Moment } from "moment";
import CalendarMonth from "./components/CalendarMonth";
import {getMonthsInYear} from "./calendarUtil";
import DailyActivityControl from "./components/DailyActivityControl";
import {useNavigate, useParams} from "react-router-dom";
import * as O from "fp-ts/Option";
import {CalendarEntry, CalendarName} from "../../../libs/api/time/api-model";
import {pipe} from "fp-ts/function";
import {getOrElse, Service} from "../../services/Service";
import {Calendars as CalendarApi} from "../../services/TimeApi";
import SelectCalendar from "../components/form/SelectCalendar";
import DatePickerWithNavigation from "../components/DatePickerWithNavigation";
import {Frown} from "react-feather";
import YearGenerator from "./components/YearGenerator";
import {FormikHelpers} from "formik";
import * as A from "fp-ts/Apply";
import formikHandler from "../../services/FormikApiHandler";
import {array} from "fp-ts";

const mapAsReact: <T>(onV: (nr: T) => ReactElement) => (o: O.Option<T>) => ReactElement = (generateElement) => (o) =>
  pipe(o,
    O.map(optionValue => generateElement(optionValue)),
    O.getOrElse(() => <></>)
  );

const parseYearMonth = (yyyyMM: string | undefined) =>
  pipe(moment(yyyyMM), (result) => moment.isMoment(result) ? result : moment());

const parseCalendarNameId = (strValue: string | undefined) =>
  pipe(strValue, O.fromNullable, O.map(parseInt));

type NavigationProps = {
  months: Moment[]
  activeMonth: number
  onSelectMonth: (selected: number) => void
}

const MonthSelector: React.FC<NavigationProps> = (
  { months, onSelectMonth, activeMonth }
) => (
  <Card className='h-100'>
    <Card.Header>
      <Card.Title className="mb-0">
        <h5>Maanden</h5>
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      {util.isDefined(months) && months.map((month) =>
        <ListGroup.Item action
                        key={`month_selection_${month.format('MM')}`}
                        active={ month.month() === activeMonth }
                        onClick={() => onSelectMonth(month.month())}>
          { month.format('MMMM') }
        </ListGroup.Item>
      )}
    </ListGroup>
  </Card>
);

const Calendars: React.FC = () => {

  const { yearMonth: yearUrlParam, calendar: calendarIdUrlParam } = useParams();
  const navigate = useNavigate();

  const [date, setDate] = useState<Moment>(parseYearMonth(yearUrlParam));
  const [calendarId, setCalendarId] = useState<O.Option<number>>(parseCalendarNameId(calendarIdUrlParam));

  const [calendars, setCalendars] = useState<Service<CalendarEntry[]>>({ status: "init" });

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editInstance, setEditInstance] = useState<O.Option<CalendarEntry>>(O.none);

  const generatedUrl =
    pipe(calendarId,
      O.map(id => `/pages/time/settings/calendars/${date.format('yyyy-MM')}/calendar/${id}`),
      O.getOrElse(() => `/pages/time/settings/calendars/${date.format('yyyy-MM')}`)
    );

  const redirectPage = () => navigate(generatedUrl);

  useEffect(redirectPage, [ generatedUrl, calendarId, date, navigate ]);

  const loadEntries = () => pipe(calendarId,
    O.fold(
      () => setCalendars({ status: "init" }),
      id => CalendarApi.entries(id, date.year())(setCalendars)
    )
  );

  useEffect(loadEntries, [ calendarId, date ]);

  const containsNonEmptyDays = (minimum: number) =>
    pipe(
      calendars,
      getOrElse([] as CalendarEntry[]),
      array.filterMap(c => O.fromNullable(c.registratie)),
    ).length > minimum;


  const monthSelected = (month: number) => {
    if (month !== date.month()) {
      setDate(date.clone().month(month));
    }
  };

  const calendarSelected = (cal: number | CalendarName | undefined) => {
    pipe(cal,
      O.fromNullable,
      O.map(c => typeof c === "number" ? c : c.id),
      setCalendarId
    );
  };

  const editEntity = (date: Moment) => {
    setShowEditModal(true);
    setEditInstance(findCalendarEntry(date));
  };

  const hideEditModal = () => {
    setShowEditModal(false);
    setEditInstance(O.none);
  };

  const saveEntity = (entry: CalendarEntry, formikHelpers: FormikHelpers<CalendarEntry>) => {
    pipe(
      A.sequenceS(O.Apply)({ id: calendarId, entry: O.some(entry), registratie: O.fromNullable(entry.registratie) }),
      O.map(({ id, entry, registratie }) =>
        CalendarApi.upsert(id, moment(entry.date))(registratie)(response => {
          formikHandler(formikHelpers)(response);
          if (response.status === 'loaded') {
            hideEditModal();
            loadEntries();
          }
        })
      )
    );
  }

  const findCalendarEntry = (date: Moment) =>
    pipe(
      calendars,
      getOrElse([] as CalendarEntry[]),
      array.findFirst(c => date.isSame(moment(c.date), 'day'))
    );

  return (
    <>
      <Helmet title="Kalenders" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Kalenders</h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={3}>
                <SelectCalendar value={ pipe(calendarId, O.toUndefined) }
                                useOptionValue={true}
                                onChange={ calendarSelected } />
              </Col>
              <Col md={2}>
                <DatePickerWithNavigation
                  onScroll={(amount) => (value) => value.subtract(amount, "years")}
                  input={true}
                  value={date}
                  initialValue={ moment() }
                  dateFormat="YYYY"
                  timeFormat={false}
                  inputProps={{className: "form-control mh-38 text-center"}}
                  onChange={(value: Moment | string) => {
                    if (typeof value !== 'string') { setDate(value); }
                  }}
                  closeOnSelect={true}
                  locale="nl-be"
                />
              </Col>
              <Col className='d-flex align-items-center'>
                { calendars.status === "error" ?
                  <div className='text-danger'><Frown/> Fout bij het ophalen van de data{typeof calendars.error.message !== 'undefined' && calendars.error.message !== '' ? ': ' + calendars.error.message : ''}</div> :
                  calendars.status === "loading" ?
                    <Spinner animation={"grow"} color="dark"/> : <></>
                }
              </Col>
              <Col className='d-flex justify-content-end align-items-center'>
                {
                  pipe(calendarId,
                    mapAsReact((id) =>
                      <YearGenerator year={ date.year() }
                                     calendarId={ id }
                                     isEnabled={ !containsNonEmptyDays(5) }
                                     onGenerated={ loadEntries }
                      />
                    )
                  )
                }
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {
          pipe(calendarId,
            mapAsReact((_) =>
              <Row>
                <Col md="3" xl="2">
                  <MonthSelector months={ getMonthsInYear(date.year()) }
                                 activeMonth={ date.month() }
                                 onSelectMonth={ monthSelected } />
                </Col>
                <Col md="9" xl="10">
                  <CalendarMonth date={ date }
                                 data={ pipe(calendars, getOrElse([] as CalendarEntry[])) }
                                 onEdit={ editEntity } />
                </Col>
              </Row>
            )
          )
        }
      </Container>
      {
        pipe(editInstance,
          mapAsReact((instance) =>
            <DailyActivityControl isOpen={ showEditModal }
                                  onHide={ hideEditModal }
                                  onSave={ saveEntity }
                                  initialFormValues={ instance }
            />
          )
        )
      }
    </>
  );
}

export default Calendars;
