import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Edit, Trash} from "react-feather";
import React from "react";
import moment from "moment";
import util from "../../utils/util";
import {TankFill} from "../../../libs/api/time/api-model";

type EntityActionHandler<T> = (entity: T) => void;

type ActionButtonProps<T> = {
  value: T
  actionHandler: EntityActionHandler<T>
}

type ActionProps<T> = {
  value: T
  handleEdit: EntityActionHandler<T>
  handleDelete: EntityActionHandler<T>
}

const EditButton: React.FC<ActionButtonProps<TankFill>> = ({value, actionHandler}) =>
  <Button variant="link" size={"sm"} className="p-0" onClick={_ => actionHandler(value)}>
    <Edit className="me-1" size={18} />
  </Button>

const DeleteButton: React.FC<ActionButtonProps<TankFill>> = ({value, actionHandler}) =>
  <Button variant="link" size="sm" className="text-danger p-0" onClick={_ => actionHandler(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const Actions: React.FC<ActionProps<TankFill>> = ({value, handleEdit, handleDelete}) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <EditButton value={value} actionHandler={handleEdit} />
      <DeleteButton value={value} actionHandler={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const tankFillColumns = (
  handleEdit: EntityActionHandler<TankFill>,
  handleDelete: EntityActionHandler<TankFill>
) => [
  {
    Header: "Datum",
    accessor: "fillDate",
    className: "text-end",
    Cell: ({ value }) => moment(value).format('DD/MM/yyyy')
  },
  {
    Header: "Hoeveelheid",
    className: "text-end",
    accessor: "quantity",
    Cell: ({value}) => util.formatInt(value),
  },
  {
    Header: "Tank niveau",
    className: "text-end",
    accessor: "estimate",
    Cell: ({value}) => util.formatInt(value),
  },
  {
    Header: "Tarief",
    className: "text-end",
    accessor: "rate",
    Cell: ({value}) => (util.isDefined(value) && util.formatBigDecimal(value)),
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    accessor: 'id',
    width: "20%",
    Cell: (data: any) => <Actions value={data.row.original} handleEdit={handleEdit} handleDelete={handleDelete} />
  }
]; 

export { tankFillColumns };
