import {EmployeeSummary} from "../../../../libs/api/time/api-model";
import React, {useEffect, useState} from "react";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import Select, {SingleValue} from "react-select";
import classNames from "classnames";
import {Button, Form, InputGroup} from "react-bootstrap";
import {Service, toOption} from "../../../services/Service";
import {Employees} from "../../../services/TimeApi";
import {EmployeeFunctions} from "../../../libs/api/time/api-helpers";
import {ChevronLeft, ChevronRight} from "react-feather";
import {Predicate} from "fp-ts/Predicate";
import * as S from 'fp-ts/string'
import {contramap, Eq} from "fp-ts/Eq";

export type EmployeeIdType = "code" | "id"

export interface SelectEmployeeProps {
  idType: EmployeeIdType
  value: string | undefined
  required?: boolean,
  onChange: (e: EmployeeSummary | undefined) => void,
  usePaging?: boolean // assumptions: employee list is sorted and idType is 'code'
  useActiveToggle?: boolean
}

type EmployeeOption = {
  value: string,
  label: string,
  active: boolean
}

const getId = (idType: EmployeeIdType) => (employee: EmployeeSummary) => {
  switch (idType) {
    case "code":
      return employee.employeeNr.toString();
    case "id":
      return employee.id.toString();
  }
}

const toEmployeeOption = (idType: EmployeeIdType) => (employee: EmployeeSummary) =>
  ({
    value: getId(idType)(employee),
    label: `${getId('code')(employee)} - ${EmployeeFunctions.fullname(employee)}`,
    active: employee.active
  } as EmployeeOption);

const eqEmployeeOption: Eq<EmployeeOption> = pipe(S.Eq, contramap((o) => o.value));

const equalsEmployeeOption: (option: EmployeeOption) => Predicate<EmployeeOption> =
  (x: EmployeeOption) => (y: EmployeeOption) => eqEmployeeOption.equals(x, y);

export const SelectEmployee: React.FC<SelectEmployeeProps> = ({
    value,
    idType,
    required,
    onChange,
    usePaging = false,
    useActiveToggle = false,
    ...props
}) => {

  const [employees, setEmployees] = useState<Service<EmployeeSummary[]>>({ status: 'init' });
  const [onlyActive, setOnlyActive] = useState<boolean>(true);

  const loadEntries = () => Employees.summaries(onlyActive)(setEmployees);

  const employeeOptions: EmployeeOption[] =
    pipe(employees,
      s => toOption(s),
      option.map(us => pipe(us, array.map(toEmployeeOption(idType)))),
      option.getOrElse(() => [] as EmployeeOption[]),
    );

  const findOption = (value: string | undefined) => pipe(employeeOptions,
    array.findFirst(o => o.value === value)
  );

  const activeEmployeeOption = findOption(value);

  useEffect(loadEntries, [ onlyActive ]);

  const isInvalid = employees.status === "error";

  const equalsEmployee: (optionValue: SingleValue<EmployeeOption>) => Predicate<EmployeeSummary> =
    (selected: SingleValue<EmployeeOption>) => (employee: EmployeeSummary) => {
      const nonEmptyValue = pipe(selected,
        option.fromNullable,
        option.map(o => o.value),
        option.getOrElse(() => '')
      );
      return getId(idType)(employee) === nonEmptyValue;
    };

  const findEmployee = (selected: SingleValue<EmployeeOption>) =>
    pipe(employees,
      s => toOption(s),
      option.chain(list => array.findFirst(equalsEmployee(selected))(list)),
      option.toUndefined
    );

  const navigateEmployee = (navigator: (i: number) => number) =>
    pipe(activeEmployeeOption,
      option.fold(
      () => array.head(employeeOptions),
      (active: EmployeeOption) =>
        pipe(employeeOptions,
          array.findIndex(equalsEmployeeOption(active)),
          option.chain((idx: number) => array.lookup(navigator(idx))(employeeOptions))
        )
      ),
      option.chain(nextOption => option.fromNullable(findEmployee(nextOption))),
      option.fold(
        () => undefined,
        (nextOption) => onChange(nextOption)
      )
    );

  const prevEmployee = () => navigateEmployee(i => i - 1);
  const nextEmployee = () => navigateEmployee(i => i + 1);

  const PagingWrapper: React.FC<{ children: React.ReactNode }> = ({children}) => (
    usePaging ?
      <div className='d-flex flex-row align-items-center'>
        <div className='flex-grow-1'>
          { children }
        </div>
        <div className='ms-2'>
          <InputGroup className='mh-38'>
            <Button className='mh-38' variant="outline-secondary" onClick={() => prevEmployee()}>
              <ChevronLeft size={14} />
            </Button>
            <Button className='mh-38' variant="outline-secondary" onClick={() => nextEmployee()}>
              <ChevronRight size={14} />
            </Button>
          </InputGroup>
        </div>
        {
          useActiveToggle &&
            <div className='ms-2'>
                <Form.Check type="switch" label="Actief"
                            checked={onlyActive}
                            onChange={() => setOnlyActive(!onlyActive)} />
            </div>
        }
      </div> :
      <>{ children }</>
  );

  return (
    <PagingWrapper>
      <Select
        className={classNames("react-select-container", isInvalid ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={employeeOptions}
        isLoading={employees.status === "loading"}
        id='input_employee_select'
        onChange={option => onChange(findEmployee(option))}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        formatOptionLabel={(data, formatOptionLabelMeta) =>
          <span className={data.active ? '' : 'text-decoration-italic'}>{ data.label }{ data.active ? '' : ' (uit dienst)' }</span>
        }
        value={pipe(activeEmployeeOption,
          option.getOrElse(() => ({ value: value, label: 'Selecteer', active: true } as EmployeeOption))
        )}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
        {...props}
      />
      {
        isInvalid && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van werknemers: ${employees.error ? employees.error.message : '?'}`}</Form.Control.Feedback>
      }
    </PagingWrapper>
  );
};

export default SelectEmployee;
