import {FormikHelpers} from "formik";
import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, Row} from "react-bootstrap";
import CostParameters from "./CostParameters";
import SimpleTable from "../components/SimpleTable";
import {mobilityPremiumColumns} from "./columns";
import {CostsParameters, MobilityPremiums} from "../../services/TimeApi";
import {useEffect, useState} from "react";
import {Service} from "../../services/Service";
import {CostParameter, MobilityPremium} from "../../../libs/api/time/api-model";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import UnderConstructionAlert from "../components/UnderConstructionAlert";

const PostCalcParameters: React.FC = () => {
  const [mobilityPremiums, setMobilityPremiums] = useState<Service<MobilityPremium[]>>({ status: 'init' });
  const [costParameters, setCostParameters] = useState<Service<CostParameter>>({ status: 'init' });

  const saveMobilityParameters = (premium: MobilityPremium, helpers: FormikHelpers<MobilityPremium>) => {
    MobilityPremiums.save(premium,
      response => {
        helpers.setFieldTouched("submit", true);
        loadMobilityPremiums();
      },
      (error: any) => helpers.setFieldError("submit", String(error)),
    );
  }
  const saveCostParameters = (parameters: CostParameter, helpers: FormikHelpers<CostParameter>) => {
    helpers.setFieldError("submit", undefined);
    CostsParameters.save(parameters,
      response => {
        helpers.setFieldTouched("submit", true);
        helpers.setSubmitting(false);
      },
      (error: any) => {
        helpers.setFieldError("submit", String(error));
        helpers.setSubmitting(false);
      },
    );
  }

  const loadMobilityPremiums = () => MobilityPremiums.get(setMobilityPremiums);
  const loadCostParameters = () => CostsParameters.get(setCostParameters);

  useEffect(loadMobilityPremiums, []);
  useEffect(loadCostParameters, []);

  return (
    <>
      <Helmet title="Parameters" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Parameters</h1>
        <UnderConstructionAlert />
        <Row>
          <Col md="4" xl="4">
            <ErrorHandlingTS service={costParameters} onLoaded={ params =>
              <CostParameters costParameter={params} onSave={saveCostParameters} />
            } />
          </Col>
          <Col md="8" xl="8">
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">
                  <h5>Mobiliteit</h5>
                </Card.Title>
              </Card.Header>
              <ErrorHandlingTS service={mobilityPremiums} onLoaded={ premiums =>
                <SimpleTable bordered renderFooter={undefined}
                             columns={mobilityPremiumColumns(saveMobilityParameters, saveMobilityParameters)}
                             data={premiums} />
              } />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PostCalcParameters;
