import React from "react";

import {Spinner} from "react-bootstrap";
import {Frown} from "react-feather";

const ErrorHandling = ({error, loading, children}) => {
  return (
    error ?
      <div className="text-danger"><Frown/> Fout bij het ophalen van de data{typeof error.message !== 'undefined' && error.message !== '' ? ': ' + error.message : ''}</div> :
      loading ?
        <div><Spinner animation={"grow"} color="dark"/></div> :
        <>{children}</>
  );
}

export default ErrorHandling;
