import React from "react";
import Select from "react-select";

function SelectMultiColumnFilter({column: { filterValue, setFilter, preFilteredRows, id }}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (typeof row.values[id] !== 'undefined' && row.values[id]) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()].map((v) => ({ value: v, label: v }));
  }, [id, preFilteredRows]);

  return (
    <Select
      className="react-select-container mt-2 mh-38 fw-normal"
      classNamePrefix="react-select"
      options={options.sort()}
      isMulti
      onChange={(newValue, actionMeta) => {
        setFilter(newValue || []);
      }}
      value={filterValue}
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    />
  );
}

export default SelectMultiColumnFilter;
