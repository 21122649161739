import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Card, Col, Container, ListGroup, Row, Tab} from "react-bootstrap";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import {ChevronLeft} from "react-feather";
import {useEffect, useState} from "react";
import {Service, toOption} from "../../services/Service";
import {Employees as EmployeesApi} from "../../services/TimeApi";
import {Employee as EmployeeModel} from "../../../libs/api/time/api-model";
import {Link, useParams} from "react-router-dom";
import PublicInfo from "./PublicInfo";
import Educations from "./educations/Educations";
import {pipe} from "fp-ts/function";
import {option} from "fp-ts";
import ContactInfo from "./ContactInfo";
import CompanySettings from "./CompanySettings";
import {FormikHelpers} from "formik";
import formikHandler from "../../services/FormikApiHandler";

const labelColWidth = 3;
const valueColWidth = 9;

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">
        <h5>Eigenschappen</h5>
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item href="#general" action>
        Personalia
      </ListGroup.Item>
      <ListGroup.Item href="#contact" action>
        Contact
      </ListGroup.Item>
      <ListGroup.Item href="#settings" action>
        Instellingen
      </ListGroup.Item>
      <ListGroup.Item href="#educations" action>
        Opleidingen
      </ListGroup.Item>
    </ListGroup>
  </Card>
);

const Employee: React.FC = () => {
  const [ employee, setEmployee ] = useState<Service<EmployeeModel>>({ status: 'init' });
  const { employeeId } = useParams();

  const loadData = () => employeeId ?
    EmployeesApi.get(employeeId)(setEmployee) :
    setEmployee({status: 'error', error: new Error('no employee id found')});

  const saveEmployee = (data: EmployeeModel, formikHelpers: FormikHelpers<EmployeeModel>) => {
    EmployeesApi.update(data.id.toString(), data)(formikHandler(formikHelpers));
  }

  useEffect(loadData, [ employeeId ]);

  const name = () => pipe(toOption(employee),
    option.fold(
      () => "",
      (e) => e?.personalia ? `${e.employeeNr} - ${e.personalia.firstname} ${e.personalia.lastname}` : ''
    )
  )

  return (
    <>
      <Helmet title={name()} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">
          <Link to="/pages/time/employees" className="me-1 btn btn-link btn-lg" role='button'>
            <ChevronLeft className='align-text-bottom' size={24} />
          </Link>
          { name() }
        </h1>
        <Tab.Container id="employee-tabs" defaultActiveKey="#general">
          <Row>
            <Col sm={4} md={3} xl={3}>
              <Navigation />
            </Col>
            <Col sm={8} md={9} xl={9}>
              <ErrorHandlingTS service={employee} onLoaded={(data) =>
                <Tab.Content className="p-0">
                  <Tab.Pane eventKey="#general">
                    <PublicInfo employee={data} onSave={saveEmployee} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#settings">
                    <CompanySettings employee={data} onSave={saveEmployee} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#contact">
                    <ContactInfo employee={data} onSave={saveEmployee} labelColWidth={labelColWidth} valueColWidth={valueColWidth} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="#educations">
                    <Educations employee={data} />
                  </Tab.Pane>
                </Tab.Content>
              } />
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default Employee;
