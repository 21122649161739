import {EmployeeSummary as EmployeeSummaryModel} from "../../../../libs/api/time/api-model";
import * as Eq from "fp-ts/Eq";
import {pipe} from "fp-ts/function";
import * as N from "fp-ts/number";

export namespace EmployeeFunctions {

  export const fullname: (e: EmployeeSummaryModel) => string = (employee: EmployeeSummaryModel) =>
    `${employee.firstname} ${employee.lastname}`;

  export const equals: Eq.Eq<EmployeeSummaryModel> = pipe(N.Eq, Eq.contramap((employee) => employee.id))
}
