import React from "react";
import {useField, useFormikContext} from "formik";
import DateTime, {DatetimepickerProps} from "react-datetime";
import classNames from "classnames";
import moment from "moment";

type FormikDateFieldProps = DatetimepickerProps & {
  name: string
  setInitialToToday?: boolean
  input?: boolean
}

const FormikDateField: React.FC<FormikDateFieldProps> = ( { name, setInitialToToday = true, input = true}) => {
  const {
    setFieldValue,
    handleBlur
  } = useFormikContext();
  const [field, helpers] = useField(name);
  return (
    <DateTime
      onChange={val => {
        setFieldValue(field.name, val);
      }}
      input={input}
      value={field.value}
      initialValue={setInitialToToday ? moment() : undefined}
      dateFormat="L"
      timeFormat={false}
      closeOnSelect={true}
      className={helpers.error ? 'is-invalid' : ''}
      inputProps={{
        id: field.name,
        className: classNames('form-control', 'mh-38', helpers.error ? 'is-invalid' : ''),
        style: { zIndex: 9999 },
        onBlur: handleBlur
      }}
    />
  );

}

export default FormikDateField;
