import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FormikValues} from "formik/dist/types";
import Select from "react-select";
import classNames from "classnames";
import {Service, toOption} from "../../../services/Service";
import {Company} from "../../../../libs/api/time/api-model";
import {Companies} from "../../../services/TimeApi";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import util, {numberOrNull} from "../../../utils/util";

export type CompanyIdType = "code" | "id" | "old_id"

export interface SelectCompanyFormikProps {
  idType: CompanyIdType
  name: string
  required?: boolean
  useOptionValue?: boolean // geef de value van de option door bij selectie, of de hele option
}

type CompanyOption = {
  label: string,
  value: string
}

const getId = (idType: CompanyIdType) => (company: Company) => {
  switch (idType) {
    case "code":
      return company.code;
    case "old_id":
      return company.oldId.toString();
    case "id":
      return company.id.toString();
  }
}

const toCompanyOption = (idType: CompanyIdType) => (company: Company) => (
  { value: getId(idType)(company), label: company.name } as CompanyOption
);
const emptyOption = () => ({value: "", label: "Maak een keuze"} as CompanyOption);

export const SelectCompanyFormik: React.FC<SelectCompanyFormikProps> = ({
    name, idType, required, useOptionValue = false, ...props
}) => {

  const [companies, setCompanies] = useState<Service<Company[]>>({ status: 'init' });
  const loadEntries = () => Companies.list(setCompanies);
  useEffect(loadEntries, []);

  const companyOptions: CompanyOption[] =
    pipe(companies,
      s => toOption(s),
      option.map(array.filter(c => c.deleted === undefined)),
      option.map(array.map(toCompanyOption(idType))),
      option.getOrElse(() => [] as CompanyOption[]),
    );

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const isInvalid= !!util.byString(errors, name);
  const hasDataError = companies.status === "error";

  const findOption = pipe(companyOptions,
    array.findFirst(o => {
      const originalValue = pipe(option.fromNullable(util.byString(values, name)),
        option.fold(
          () => '',
          (v) => v.toString()
        )
      );
      return o.value === originalValue;
    }),
    option.getOrElse(() => emptyOption())
  )

  return (
    <>
      <Select
        className={classNames("react-select-container", isInvalid || hasDataError ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={companyOptions}
        isLoading={companies.status === "loading"}
        id={`input_${name}`}
        onChange={option => setFieldValue(name, useOptionValue ? numberOrNull(option?.value) : option)}
        onBlur={handleBlur}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={findOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      {
        hasDataError && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van de firma's: ${companies.error ? companies.error.message : '?'}`}</Form.Control.Feedback>
      }
      {!!util.byString(errors, name) && <Form.Control.Feedback type="invalid">{util.byString(errors, name)}</Form.Control.Feedback>}
    </>
  );
};

export default SelectCompanyFormik;
