import axios from "axios";
import { dalConfig } from "../config";

const dalAxiosInstance = axios.create({
  baseURL: dalConfig.apiBaseUrl,
  auth: {
    username: dalConfig.username,
    password: dalConfig.password
  }
});

const localAxiosInstance = axios.create({
});

localAxiosInstance.interceptors.response.use(
  (response) => {
    //console.debug(response);
    return response;
  },
  (error) => {
    console.error((error.response && error.response.data) || error);
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

dalAxiosInstance.interceptors.response.use(
  (response) => {
    //console.debug(response);
    return (response && response.data);
  },
  (error) => {
    console.error((error.response && error.response.data) || error);
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export { dalAxiosInstance as default, localAxiosInstance };
