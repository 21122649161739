import React from "react";
import {Form} from "react-bootstrap";
import util from "../../../../utils/util";

function BooleanColumnFilter( { column: { id, filterValue, setFilter /*, Header*/ } } ) {

  return (
    <>
      <Form.Check
        type="checkbox"
        id={"filterCheckbox" + id}
        defaultChecked={util.isDefined(filterValue)}
        // label={Header}
        value={util.isDefined(filterValue)}
        onChange={ (e) => setFilter(e.target.checked) }
      />
    </>
  );
}

export default BooleanColumnFilter;