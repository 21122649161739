import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Button, ButtonGroup, Card, Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import util from "../../../utils/util";
import {useNavigate, useParams} from "react-router-dom";
import {Service} from "../../../services/Service";
import {
    DailyPostCalculation,
    PostCalculationResponseByUser,
    PostCalculation as PostCalculationModel,
    PostCalculationEntry, PostCalculationMeta, Timesheet
} from "../../../../libs/api/time/api-model";
import {PostCalculation} from "../../../services/TimeApi";
import ErrorHandlingTS, {MapServiceElement} from "../../components/ErrorHandlingTS";
import {
    AlertCircle,
    CheckCircle,
    ChevronRight,
    Clock, Coffee,
    LogIn,
    LogOut,
    RefreshCw,
    Truck,
    UserCheck,
    Users
} from "react-feather";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import './TooltipStyle.scss';
import DatePickerWithNavigation from "../../components/DatePickerWithNavigation";
import classNames from "classnames";
import {dalConfig} from "../../../config";
import InfoBox from "../../components/InfoBox";

const dateFormat = 'yyyy-MM-DD';

const parseDate = (dateAsString: string | undefined) => {
  const parsed = util.isDefined(dateAsString) && moment(dateAsString);
  return moment.isMoment(parsed) ? parsed : moment();
};

const getEntryError = (entry: PostCalculationEntry) => pipe(entry.postCalculationMeta,
  option.fromNullable,
  option.chain(pc => option.fromNullable(pc.projectRefError)),
)

const getUserErrors = (userEntry: PostCalculationResponseByUser) =>
  pipe(userEntry.entries, array.filterMap(e => getEntryError(e)))

const getAllErrors = (entries: PostCalculationResponseByUser[]) =>
  pipe(entries, array.chain(ue => getUserErrors(ue)),)

// const countErrors = (entries: PostCalculationResponseByUser[]) => getAllErrors(entries).length

const PostCalculationEntryHeader: React.FC =  () =>
  <Row className='py-2 mx-0'>
    <Col>Project</Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Bestuurder</Tooltip>}>
        <UserCheck size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Passagier</Tooltip>}>
        <Users size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Start</Tooltip>}>
        <LogIn size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Eind</Tooltip>}>
        <LogOut size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end text-loadunload' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Laden & Lossen</Tooltip>}>
        <Truck size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end' sm={2} md={2} lg={2}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Totaal</Tooltip>}>
        <Clock size={18}/>
      </OverlayTrigger>
    </Col>
  </Row>

const PostCalculationEntryRow: React.FC<{ entry: PostCalculationModel, meta: PostCalculationMeta }> =  ({ entry, meta }) =>
  <Row className='py-2 mx-0'>
    <Col>
      {
        util.isDefined(meta.projectRefError) ?
          <>
            <AlertCircle size={18} className="feather align-middle text-danger me-1" />
            <span className='align-middle text-danger'>{meta.projectRefError}</span>
          </> :
          <>
            { /* <CheckCircle size={18} className="feather align-middle text-success me-1" />*/ }
            { meta.projectRef?.groupId }<small className="ms-2 text-muted text-truncate">{meta.projectRef?.location} - {meta.projectRef?.company}</small><br/>
            { meta.projectRef?.description }
          </>
      }
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      { entry.mobility?.kmDriver } km
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      { entry.mobility?.kmPassenger } km
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>{util.localDateTimeToHours({value: entry.timeFrom})}</Col>
    <Col className='text-end' sm={1} md={1} lg={1}>{util.localDateTimeToHours({value: entry.timeTo})}</Col>
    <Col className='text-end text-loadunload' sm={1} md={1} lg={1}>
        {util.formatDecimal(entry.loadUnload)}
    </Col>
    <Col className='text-end' sm={2} md={2} lg={2}>
        { util.formatDecimal(entry.indirectDuration) }
        <ChevronRight className='mx-1' size={14} />
        { util.formatDecimal(entry.indirectDurationRounded) }
    </Col>
  </Row>

const PostCalculationEmpty = () =>
  <Card>
    <Card.Header>
      <h4 className="m-0">Geen resultaten</h4>
    </Card.Header>
  </Card>

const TimesheetEntryHeader: React.FC =  () =>
  <Row className='py-2 mx-0'>
    <Col sm={5} md={5} lg={5}>Voertuig</Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Bestuurder</Tooltip>}>
        <UserCheck size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Passagier</Tooltip>}>
        <Users size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Pauze</Tooltip>}>
        <Coffee size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>&nbsp;</Col>
    <Col className='text-end text-loadunload' sm={1} md={1} lg={1}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Laden & Lossen</Tooltip>}>
        <Truck size={18}/>
      </OverlayTrigger>
    </Col>
    <Col className='text-end' sm={2} md={2} lg={2}>
      <OverlayTrigger placement='top' overlay={<Tooltip>Totaal</Tooltip>}>
        <Clock size={18}/>
      </OverlayTrigger>
    </Col>
  </Row>

const TimesheetEntryRow: React.FC<{ entry: Timesheet }> =  ({ entry }) =>
  <Row className='py-2 mx-0'>
    <Col sm={5} md={5} lg={5}>
      {entry.vehicleNameStart}
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      {entry.mobility?.kmDriver} km
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      {entry.mobility?.kmPassenger} km
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>
      {entry.pause}
    </Col>
    <Col className='text-end' sm={1} md={1} lg={1}>&nbsp;</Col>
    <Col className='text-end text-loadunload' sm={1} md={1} lg={1}>
      {util.formatDecimal(entry.loadUnload)}
    </Col>
    <Col className='text-end' sm={2} md={2} lg={2}>
      {util.formatDecimal(entry.workedHours)}
      <ChevronRight className='mx-1' size={14}/>
      {util.formatDecimal(entry.workedHoursRounded)}
    </Col>
  </Row>

const PostCalculationUserReport: React.FC<{ entry: PostCalculationResponseByUser }> = ({entry}) =>
  <Card className={classNames("border", array.isNonEmpty(getUserErrors(entry)) ? 'border-danger' : 'border-success')}>
    <Card.Header className="border-bottom">
      <h4 className="m-0">
          {util.isDefined(entry.userMeta.employeeRefError) && <AlertCircle size={18} className="feather align-middle text-danger me-1" />}
          {
              util.isDefined(entry.userMeta.employeeRef) ?
              <>
                  <img
                      src={`${dalConfig.baseUrl}/cdn/images/employees/${entry.userMeta.employeeRef?.employeeNr}/image`}
                      width="32"
                      height="32"
                      className="rounded-circle my-n1 me-2"
                      alt=''
                  />
                  { entry.userMeta.employeeRef?.firstname } { entry.userMeta.employeeRef?.lastname }
              </> :
              <>{util.isDefined(entry.user) ? entry.user?.name : 'Onbekende gebruiker'}</>
          }
      </h4>
    </Card.Header>
    <Card.Body className='pt-0'>
      <Row>
        <Col sm={2} md={2} lg={2}>
          <Row className='py-2 mx-0'><h5>Projecten</h5></Row>
        </Col>
        <Col sm={10} md={10} lg={10}>
          <PostCalculationEntryHeader/>
          {
            pipe(entry.entries,
              array.map(({ postCalculation, postCalculationMeta }) =>
                postCalculation && <PostCalculationEntryRow key={`pc_user_${entry.user?.id}_${postCalculation.timeFrom.replaceAll(':', '')}`} entry={postCalculation} meta={postCalculationMeta} />
              )
            )
          }
        </Col>
      </Row>
      <Row><Col><hr/></Col></Row>
      <Row>
        <Col sm={2} md={2} lg={2}>
          <Row className='py-2 mx-0'><h5>Prestaties</h5></Row>
        </Col>
        <Col sm={10} md={10} lg={10}>
          <TimesheetEntryHeader/>
          {
            pipe(entry.entries,
              array.map(({ timeSheet }) =>
                timeSheet && <TimesheetEntryRow key={`ts_user_${entry.user?.id}`} entry={timeSheet} />
              )
            )
          }
        </Col>
      </Row>
    </Card.Body>
  </Card>

export type Departement = /* "Studie" | */ "Atelier" | "Montage" | "Transport"

const PostCalculationByDay: React.FC = () => {
  const { date: dateUrlParam } = useParams();
  const navigate = useNavigate();

  const [date, setDate] = useState<Moment>(parseDate(dateUrlParam));
  const [departement, setDepartement] = useState<Departement>('Montage');
  const [dailyReport, setDailyReport] = useState<Service<DailyPostCalculation>>({ status: 'init' });

  const loadReport = () => PostCalculation.dailyReport(date)(setDailyReport);

  useEffect(loadReport, [ date ]);

  const dateSelected = (value: Moment | string) => {
    if (typeof value === 'string') {
      console.error(`received invalid date ${value}`)
      // setEntriesData({ status: 'error', error: new Error(`invalid date selection: ${value}`)});
    } else if (date.format(dateFormat) !== value.format(dateFormat)) {
      setDate(value);
      navigate(`/pages/clockings/daily/${value.format(dateFormat)}`);
    }
  };

  return (
    <>
      <Helmet title="Tikkingen" />
      <Container fluid className="p-0 postcalculation-daily">
        <InfoBox>
          <>
            <p>Momenteel is deze pagina operationeel voor <strong>montage</strong></p>
            <Row>
              <Col sm={2} md={2} lg={2}><strong>Atelier</strong></Col>
              <Col>Voor atelier is nog een ander mechanisme in werking om de tikkingen te synchroniseren. Deze zullen nog worden overgezet naar deze pagina.</Col>
            </Row>
            <Row>
              <Col sm={2} md={2} lg={2}><strong>Studie & Transport</strong></Col>
              <Col>Hier zijn geen tikkingen in gebruik</Col>
            </Row>
          </>
        </InfoBox>
        <Row className="mb-2 mb-xl-3 align-items-end mh-38">
          <Col xs="auto" className="d-none d-sm-block">
            <h3 className='p-0 m-0 align-bottom'>Tikking overzicht <span className='text-muted'>{date.format('DD/MM/YYYY')}</span></h3>
          </Col>
          <Col xs="auto" className="d-none ms-auto d-sm-block">
            <MapServiceElement service={dailyReport} onLoaded={(report) => {
              const errors = getAllErrors(report.entries);
              return array.isNonEmpty(errors) ?
                <OverlayTrigger placement='bottom' overlay={
                  <Tooltip className='pc-errors'><div className='m-2'><div className='mb-2'>{errors.length} validatie fouten:</div>{errors.map((e, i) => <div key={i}>{e}</div>)}</div></Tooltip>
                }>
                  <div className='nav-item align-bottom'>
                    <div className='nav-icon'>
                      <div className="position-relative">
                        <AlertCircle className="align-middle text-danger" size={18}/>
                        <span className="indicator bg-danger">{errors.length}</span>
                      </div>
                    </div>
                  </div>
                </OverlayTrigger> :
                <OverlayTrigger placement='bottom' overlay={
                  <Tooltip className='pc-errors'><div className='m-2'><div className='mb-2'>geen openstaande fouten</div></div></Tooltip>
                }>
                  <CheckCircle className="align-middle feather text-success" size={18}/>
                </OverlayTrigger>
            }} />
          </Col>
          <Col xs="auto" className="ms-auto text-end">
            <MapServiceElement service={dailyReport} onLoaded={(report) =>
              <small className='text-muted align-bottom'>laatst bijgewerkt op <strong>{ util.localDateTimeToFull(report.fetched) }</strong></small>
            } />
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <Row>
              <Col md={3}>
                <DatePickerWithNavigation
                  input={true}
                  value={date}
                  initialValue={moment()}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={dateSelected}
                  closeOnSelect={true}
                  locale="nl-be"
                  inputProps={{
                    className: "form-control text-center",
                    style: { zIndex: 9999 }
                  }}
                />
              </Col>
              <Col md={6} className='text-center'>
                <ButtonGroup size="lg">
                  { /*
                  <Button variant="outline-info">S</Button>
                  <Button disabled={true} active={departement === 'Transport'} onClick={() => setDepartement('Transport')} variant="outline-danger">T</Button>
                  */ }
                  <Button disabled={true} active={departement === 'Atelier'} onClick={() => setDepartement('Atelier')} variant="outline-primary">A</Button>
                  <Button active={departement === 'Montage'} onClick={() => setDepartement('Montage')} variant="outline-dark">M</Button>
                </ButtonGroup>
              </Col>
              <Col md={3} className='text-end'>
                <Button variant="primary" className="shadow-sm ms-1 mh-38" onClick={loadReport} title='Refresh'>
                  <RefreshCw className="feather" />
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ErrorHandlingTS service={dailyReport} onLoaded={(report) =>
          <>
            { report.entries.length === 0 && <PostCalculationEmpty />}
            { report.entries.map(entry => <PostCalculationUserReport key={`pc_user_${entry.user?.id}`} entry={entry} />)}
            { /*
              report.entries
              .filter(entry =>
                entry.userMeta.employeeRef && entry.userMeta.employeeRef.activityId
              )
              .map(entry =>
                <PostCalculationUserReport key={`pc_user_${entry.user?.id}`} entry={entry} />
              )
              */
            }
          </>
        } />
      </Container>
    </>
  );
}

export default PostCalculationByDay;
