import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";

const Print = ({ children }) => (
  <>
    <Wrapper>
      <Main>
        <Content>
          {children}
          <Outlet />
        </Content>
      </Main>
    </Wrapper>
  </>
);

export default Print;
