import * as React from "react";
import {Helmet} from "react-helmet-async";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import util, {parseNumber} from "../../../utils/util";
import {useNavigate, useParams} from "react-router-dom";
import {mapService, Service} from "../../../services/Service";
import {
  EmployeeSummary, DailyPlanningItem, DailyPlanningItemUpdate
} from "../../../../libs/api/time/api-model";
import {Employees, Planning} from "../../../services/TimeApi";
import ErrorHandlingTS from "../../components/ErrorHandlingTS";
import {
  PlusCircle,
} from "react-feather";
import {pipe} from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as N from "fp-ts/number";
import DatePickerWithNavigation from "../../components/DatePickerWithNavigation";
import SelectEmployee from "../../components/form/SelectEmployee";
import TableWithSubComponents from "../../components/TableWithSubComponents";
import {columns, renderDescription} from "./columns-employee";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {map, none, Option, some} from "fp-ts/Option";
import DailyPlanningItemControl from "./DailyPlanningItemControl";
import {FormikHelpers} from "formik/dist/types";
import formikHandler from "../../../services/FormikApiHandler";
import {contramap, Eq} from "fp-ts/Eq";
import DailyPlanningItemControlNew, {CreatePlanningItemForm} from "./DailyPlanningItemNew";
import useAuth from "../../../hooks/useAuth";

const dateFormat = 'yyyy-MM-DD';

const EqById: Eq<EmployeeSummary> = pipe(N.Eq, contramap((o) => o.id));

const parseDate = (dateAsString: string | undefined) => {
  const parsed = util.isDefined(dateAsString) && moment(dateAsString);
  return moment.isMoment(parsed) ? parsed : moment();
};

const DailyPlanningByEmployee: React.FC = () => {
  const { date: dateUrlParam, employee: employeeUrlParam} = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [date, setDate] = useState<Moment>(parseDate(dateUrlParam));
  const [employee, setEmployee] = useState<O.Option<EmployeeSummary>>(O.none);

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [editInstance, setEditInstance] = useState<Option<DailyPlanningItemUpdate>>(none);

  const [dailyPlanning, setDailyPlanning] = useState<Service<DailyPlanningItem[]>>({ status: 'init' });
  const [deleteState, setDeleteState] = useState<Service<string>>({ status: 'init' });

  const parseEmployeeUrlParameter = () => pipe(
    O.fromNullable(employeeUrlParam),
    O.map((p) => parseNumber(p)),
    //O.filter(id => pipe(employee, O.map(e => e.id), O.elem(N.Eq)(id))),
    O.map((p) => Employees.summary(p)((response) => {
      mapService(setEmployee)(mapService(O.some)(response));
    })),
    O.toUndefined
  );

  const loadReport = () => pipe(
    employee,
    O.fold(
      () => setDailyPlanning({ status: 'init' }),
      (e) => Planning.employeeDaily(date, e.id)(setDailyPlanning)
    )
  );

  useEffect(parseEmployeeUrlParameter, [ employeeUrlParam ]);
  useEffect(loadReport, [ date, employee ]);

  const formatEmployeeUrlParam = () => pipe(
    employee,
    O.map((e) => `/employee/${e.id}`),
    O.getOrElse(() => '')
  );

  const dateSelected = (value: Moment | string) => {
    if (typeof value === 'string') {
      console.error(`received invalid date ${value}`)
    } else if (date.format(dateFormat) !== value.format(dateFormat)) {
      setDate(value);
      navigate(`/pages/planning/daily/${value.format(dateFormat)}${formatEmployeeUrlParam()}`);
    }
  };

  const employeeSelected = (value: EmployeeSummary | undefined) => {
    pipe(
      value,
      O.fromNullable,
      O.fold(
        () => console.log(`employee set to undefined, doing nothing`),
        (newEmployee) => {
          if (!pipe(employee, O.elem(EqById)(newEmployee))) {
            navigate(`/pages/planning/daily/${date.format(dateFormat)}/employee/${newEmployee.id}`);
          } else {
            console.log(`new employee is same as old one`, newEmployee, employee);
          }
        },
      )
    );
  };

  const confirmDelete = (entity: DailyPlanningItem) => {
    setEditInstance(some(entity));
    setShowDeleteModal(true);
  };

  const confirmEdit = (entity: DailyPlanningItem) => {
    setEditInstance(some(entity as DailyPlanningItemUpdate));
    setShowEditModal(true);
  };

  const deleteEntity = () => pipe(editInstance,
    O.map(item =>
      Planning.delete(item.id)(response => {
        setDeleteState(response);
        if (response.status === 'loaded') {
          setShowDeleteModal(false);
          setEditInstance(none);
          loadReport();
        }
      })
    )
  );

  const updateEntity = (item: DailyPlanningItemUpdate, helpers: FormikHelpers<DailyPlanningItem>) =>
    Planning.update(item.id, item)(response => {
      formikHandler(helpers)(response);
      if (response.status === 'loaded') {
        setShowEditModal(false);
        setEditInstance(none);
        loadReport();
      }
    });

  const createEntity = (item: CreatePlanningItemForm, helpers: FormikHelpers<CreatePlanningItemForm>) => {
    const createItem: DailyPlanningItem = {
      "id": 0,
      "projectGroupId": item.projectGroupId,
      "description": item.description,
      "driver": item.isDriver,
      "licensePlate": item.licensePlate,
      "createdBy": user.user,
      "start": item.start,
      "activity": item.activity?.id,
      "activityRef": item.activity,
      "date": date.format('yyyy-MM-DD'),
      "employeeId": pipe(employee, map(e => e.id), O.toUndefined),
    };
    Planning.create(createItem)(response => {
      formikHandler(helpers)(response);
      if (response.status === 'loaded') {
        setShowCreateModal(false);
        loadReport();
      }
    })
  };

  return (
    <>
      <Helmet title="Dagplanning Werknemer" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Dagplanning <span
          className='text-muted'>{ date.format('DD/MM/YYYY') }</span>
        </h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <SelectEmployee
                  idType="id"
                  onChange={ employeeSelected }
                  value={ pipe(employee, O.map(e => e.id?.toString()), O.toUndefined) }
                  usePaging={true} />
              </Col>
              <Col md={3}>
                <DatePickerWithNavigation
                  input={true}
                  value={date}
                  initialValue={moment()}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={dateSelected}
                  closeOnSelect={true}
                  locale="nl-be"
                  inputProps={{
                    className: "form-control text-center",
                  }}
                />
              </Col>
              <Col md={5} className='ms-auto text-end'>
                <Button size={"lg"} variant="success" className="shadow-sm me-1"
                        onClick={() => setShowCreateModal(true)}
                        disabled={O.isNone(O.fromNullable(employee)) || O.isNone(O.fromNullable(date))}>
                  <PlusCircle className="feather me-1" />Toevoegen
                </Button>
                {/*}
                <OverlayTrigger overlay={<Tooltip>Nog niet beschikbaar</Tooltip>}>
                  <Button size={"lg"} variant="outline-secondary" className="shadow-sm me-1"
                          disabled={O.isNone(O.fromNullable(employee)) || O.isNone(O.fromNullable(date))}>
                    <Copy className="feather me-1" />Kopiëer naar prestaties
                  </Button>
                </OverlayTrigger>
                */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ErrorHandlingTS service={dailyPlanning} onLoaded={(report) =>
          <Card>
            <TableWithSubComponents bordered
                                    data={report}
                                    columns={columns(
                                      (item) => confirmEdit(item),
                                      (item) => confirmDelete(item)
                                    )}
                                    renderRowSubComponent={renderDescription}
                                    hiddenColumns={['description']}
                                    renderFooter={undefined}
            />
          </Card>
        }/>
      </Container>
      {
        pipe(editInstance, O.fold(
          () => <></>,
          (item) =>
            <DailyPlanningItemControl
                             modalOpen={showEditModal}
                             onModalHide={() => setShowEditModal(false)}
                             onSave={(item, helpers) => updateEntity(item, helpers)}
                             item={item}
            />
        ))
      }
      { /* todo standaard activiteit van employee gebruiken bij aanmaken nieuw item */ }
      <DailyPlanningItemControlNew modalOpen={showCreateModal}
                                   onModalHide={() => setShowCreateModal(false)}
                                   onSave={ createEntity }
                                   //formItem={ newFormItem() }
      />
      <ConfirmationDialog title={`Planning item verwijderen`}
                          open={showDeleteModal}
                          loading={deleteState.status === 'loading'}
                          error={deleteState.status === 'error'}
                          onCancel={() => setShowDeleteModal(false)}
                          onConfirm={deleteEntity}>
        <p>Ben je zeker dat je dit planning item wil verwijderen?</p>
      </ConfirmationDialog>
    </>
  );
}

export default DailyPlanningByEmployee;
