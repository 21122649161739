import React from "react";
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-regular-svg-icons";
import util from "../../utils/util";

export interface UnderConstructionAlertProps {
  msg?: string
}

const UnderConstructionAlert: React.FC<UnderConstructionAlertProps> = ({ msg }) =>
  <Alert
    variant='danger'
    className="alert-outline-coloured"
    dismissible
  >
    <div className="alert-icon">
      <FontAwesomeIcon icon={faBell} fixedWidth/>
    </div>
    <div className="alert-message">
      <strong>Under construction</strong> { util.isDefined(msg) ? msg : 'Deze pagina is nog niet klaar.' }
    </div>
  </Alert>


export default UnderConstructionAlert;