import React from "react";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import util from "../../utils/util";
import classNames from "classnames";

export const bgColor = (activity) => {
  switch(activity) {
    case 'W':
    case 'N':
      return 'light';
    case 'BF':
    case 'CD':
    case 'IRO':
    case 'V':
    case 'RD':
      return 'warning';
    case 'S':
      return 'info';
    case 'M':
    case 'T':
      return 'dark';
    case 'A':
      return 'primary';
    case 'AO':
    case 'Z':
      return 'danger';
    default:
      return 'light';
  }
}

export const textColor = (activity) => {
  switch(activity) {
    case 'W':
    case 'N':
    case 'BF':
    case 'CD':
    case 'IRO':
    case 'V':
    case 'RD':
      return 'dark';
    case 'S':
      return '';
    case 'M':
    case 'T':
      return '';
    case 'A':
      return '';
    case 'AO':
    case 'Z':
      return '';
    default:
      return 'dark';
  }
}

const ActivityBadge = ({activity, className, as}) => {
  return (util.isDefined(activity) &&
    <OverlayTrigger placement='top'
                    overlay={<Tooltip id={`tooltip-header-activity-${activity.id}`}>{activity.name}</Tooltip>}>
      <Badge as={util.isDefined(as) ? as : "sup"}
             bg={bgColor(activity.id)}
             text={textColor(activity.id)}
             className={classNames("p-1", className)}>
        {activity.id}
      </Badge>
    </OverlayTrigger>
  )
}

export default ActivityBadge;
