import ReactPDF, {BlobProvider} from "@react-pdf/renderer";
import {Button, InputGroup} from "react-bootstrap";
import {Download, ExternalLink, FileText} from "react-feather";
import React from "react";

type PdfDownloadProps = {
  label: string
  filename: string
  dataLoading: boolean
  document: React.ReactElement<ReactPDF.DocumentProps>
};

const PdfDownload: React.FC<PdfDownloadProps> = ({
  document, label, filename, dataLoading
}) =>
  <BlobProvider document={document}>
    {({blob, url, loading, error}) =>
      <InputGroup size='lg' style={{width: 'auto', display: 'inline-flex'}}>
        <InputGroup.Text className='outline-primary'>
          {dataLoading || loading ? 'Laden...' : <><FileText className="feather me-1"/>{label}</>}
        </InputGroup.Text>
        <Button variant="outline-primary" className="shadow-sm" href={url !== null ? url : ""}
                disabled={dataLoading || loading}
                title="Openen"
                target="_blank">
          <ExternalLink className="feather"/>
        </Button>
        <Button variant="outline-primary" className="shadow-sm" href={url !== null ? url : ""}
                disabled={dataLoading || loading}
                download={filename}
                title="Downloaden">
          <Download className="feather"/>
        </Button>
      </InputGroup>
    }
  </BlobProvider>

export default PdfDownload;