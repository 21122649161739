import React from "react";
import * as Yup from "yup";
import {
  Formik,
  FormikHelpers,
  Form as FormikForm,
  useField,
} from "formik";
import {
  Card,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import {Employee} from "../../../libs/api/time/api-model";
import FormSubmitButton from "../components/form/FormSubmitButton";
import SelectActivityTime from "../components/form/SelectActivityTime";
import SelectCompanyFormik from "../components/form/SelectCompanyFormik";
import SelectGeoDynamicsUser from "./components/SelectGeoDynamicsUser";
import {FieldHookConfig} from "formik/dist/Field";
import SelectEmployeeType from "../components/form/SelectEmployeeType";
import FormikDateField from "../components/FormikDateField";
import moment from "moment";
import util from "../../utils/util";
import {pipe} from "fp-ts/function";
import {option} from "fp-ts";
import SelectCalendarFormik from "../components/form/SelectCalendarFormik";

export interface CompanySettingsProps {
  employee: Employee,
  labelColWidth: number,
  valueColWidth: number,
  onSave: (values: Employee, formikHelpers: FormikHelpers<Employee>) => void | Promise<any>;
}

const isMomentOrEmpty = (value: any) => pipe(value,
  option.fromNullable,
  option.fold(
    () => true,
    (nonEmpty) =>
      typeof nonEmpty === 'string' ?
        moment(nonEmpty, 'yyyy-MM-DD', true).isValid() :
        moment.isMoment(nonEmpty)
  )
)

const schema = Yup.object().shape({
  dateServiceIn: Yup
    .mixed()
    .required()
    .test('dateServiceIn', 'Geen geldige datum', isMomentOrEmpty),
  dateServiceOut: Yup
    .mixed()
    .test('dateServiceOut', 'Geen geldige datum', isMomentOrEmpty),
  activity: Yup.object().optional(),
  company: Yup.object().shape({
    id: Yup.number()
      .required()
      .min(1, 'Firma is verplicht'),
  }),
  calendarId: Yup.number()
    .required()
    .min(1, 'Kalender is verplicht'),
});

const toMomentOption: (_: any) => option.Option<moment.Moment> = (value: any) =>
  option.fromPredicate(moment.isMoment)(value)

const stringToMomentOption: (_: any, f: string) => option.Option<moment.Moment> = (value: any, format: string) =>
  typeof value === 'string' && moment(value, format, true).isValid() ? option.some(moment(value, format, true)) : option.none;

const toMoment: (_: moment.Moment | string) => option.Option<moment.Moment> = (value: moment.Moment | string) =>
  pipe(value,
    toMomentOption,
    option.orElse(() => stringToMomentOption(value, 'yyyy-MM-DD')),
    option.orElse(() => stringToMomentOption(value, 'DD/MM/yyyy')),
  )

const deNormalizeLocalDate = (value: moment.Moment | string | undefined) =>
  pipe(value,
    option.fromNullable,
    option.chain(toMoment), // geldige dates worden als moment doorgestuurd, ongeldige als string
    option.map(v => v.format('yyyy-MM-DD')),
    option.toUndefined
  );

const deNormalize = (values: Employee) =>
  util.isDefined(values) ?
    { ...values,
      dateServiceIn: deNormalizeLocalDate(values.dateServiceIn),
      dateServiceOut: deNormalizeLocalDate(values.dateServiceOut),
    } :
    values;

export interface FormFieldProps<Val> {
  label: string
  props: string | FieldHookConfig<Val>
  required?: boolean
}

type UseFormFieldProps<P = any> = React.FC<FormFieldProps<P>>;

const CompanySettings: React.FC<CompanySettingsProps> = ({employee, onSave, labelColWidth, valueColWidth}) => {

  const FormField: UseFormFieldProps = ({ label, required,...props }) => {
    const [field, meta] = useField(props.props);
    return (
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor={`input${field.name}`}>
          {required ? <strong>{label} *</strong> : label}
        </Form.Label>
        <Col sm={valueColWidth}>
          <Form.Control {...field} {...props} isInvalid={!!(meta.error)} className="mh-38" />
          {meta.error ? (
            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
          ) : null}
        </Col>
      </Form.Group>
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">
          <h5>Instellingen</h5>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          //initialValues={normalize(employee)}
          initialValues={employee}
          onSubmit={(values, formikHelpers) => onSave(deNormalize(values), formikHelpers)}
        >
          {({
              handleSubmit,
              errors
            }) => (
            <FormikForm noValidate onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="dateServiceIn">
                      <strong>Datum in dienst *</strong>
                    </Form.Label>
                    <Col sm={valueColWidth}>
                      <FormikDateField name='dateServiceIn' setInitialToToday={false} />
                      <Form.Control.Feedback type="invalid">{errors.dateServiceIn}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="dateServiceOut">Datum uit dienst</Form.Label>
                    <Col sm={valueColWidth}>
                      <FormikDateField name='dateServiceOut' setInitialToToday={false} />
                      <Form.Control.Feedback type="invalid">{errors.dateServiceOut}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <FormField label="Kostenplaats" props="analyticCode" />
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputActivity">Standaard Activiteit</Form.Label>
                    <Col sm={valueColWidth}>
                      <SelectActivityTime name="activity" useOptionValue={false} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputSite"><strong>Firma *</strong></Form.Label>
                    <Col sm={valueColWidth}>
                      <SelectCompanyFormik idType='id' name="company.id" useOptionValue={true} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputType"><strong>Type *</strong></Form.Label>
                    <Col sm={valueColWidth}>
                      <SelectEmployeeType name="employeeType" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="inputCalendar"><strong>Kalender *</strong></Form.Label>
                    <Col sm={valueColWidth}>
                      <SelectCalendarFormik name="calendarId" useOptionValue={true} required={true} disabled={true}/>
                      <small className="text-muted">Kalender kan niet aangepast worden omdat de prestaties al gegenereerd werden</small>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="input_geoDynamicsId">GeoDynamics gebruiker</Form.Label>
                    <Col sm={valueColWidth}>
                      <SelectGeoDynamicsUser name="geodynamicsId" isClearable={true} />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <FormSubmitButton />
            </FormikForm>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default CompanySettings;
