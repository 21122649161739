import React from "react";
import { Outlet } from "react-router-dom";

const PasstroughLayout = ({ children }) => (
  <React.Fragment>
    {children}
    <Outlet />
  </React.Fragment>
);

export default PasstroughLayout;
