import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { AccessRight } from "../../model/Common";

export interface AuthorizedGuardProps {
  subject: AccessRight
  children: React.ReactNode
}

// For routes that can only be accessed by authorized users
const AuthorizedGuard: React.FC<AuthorizedGuardProps> = ({ children, subject }) => {
  const { isAuthorized, isInitialized } = useAuth();
  const isAuthorizedTS: (_: AccessRight) => boolean = isAuthorized; // cast to function type

  if (isInitialized && !isAuthorizedTS(subject)) {
    return <Navigate to="/auth/unauthorized" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthorizedGuard;
