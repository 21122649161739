import util from "../../utils/util";
import {Badge} from "react-bootstrap";
import React from "react";
import moment from "moment/moment";

type DurationBadgeProps = {
  value?: string // finite duration in string formate
  className: string
}

const DurationBadge: React.FC<DurationBadgeProps> = ({ value, className }) => {

  const minutes = moment.duration(value).asMinutes();
  const diffClass = minutes > 0 ? 'success' : minutes < 0 ? 'danger' : '';

  return (
    <Badge bg={diffClass} className={className}>
      { util.duration(value, false) }
    </Badge>
  );
};

export default DurationBadge; //
