import React from 'react';
import Select from 'react-select';
import DalService from "../../../services/DalService";

class SelectCostType extends React.Component {

  handleChange = value => {
    this.props.onChange('costType', value);
  };

  handleBlur = () => {
    this.props.onBlur('costType', true);
  };

  getLabel = ({id, name}) => (name);
  getValue = (option) => (option.id);

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: true,
    };
  }

  async componentDidMount() {
    DalService.getCostTypes(
      (entities) => {
        this.setState({
          options: entities,
          loading: false,
        });
      },
      (error) => {
        this.setState({
          options: [],
          loading: false,
        });
      }
    );
  }

  render() {
    const { id, label, error, isInvalid, value, onChange, onBlur, ...props } = this.props;
    const clazz = ['react-select-container', !!error ? '' : 'is-invalid'].join(' ');
    return (
      <Select
        className={clazz}
        classNamePrefix="react-select"
        options={this.state.options}
        isLoading={this.state.loading}
        id={id}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        getOptionValue={this.getValue}
        getOptionLabel={this.getLabel}
        //inputClassName={!!error ? '' : 'is-invalid'}
        value={value}
        {...props}
      />
    );
  }
}

export default SelectCostType;
