import React from "react";
import util from "../../utils/util";
import moment from "moment/moment";
import { Page, Text, View, Document } from '@react-pdf/renderer';
import TableRows from "../components/TableRows";
import styles from "../components/ReportStyles";
import TableHeader from "../components/TableHeader";
import {DateRange, toMediumFormat} from "../../model/Common";
import {ColumnDefinition} from "../components/PdfTypes";
import {AnalyticFleetCost, FleetCostEntry} from "../../../libs/api/time/api-model";
import {Style} from "@react-pdf/types";

type AnalyticFleetReportDocumentProps = {
  columns: ColumnDefinition<FleetCostEntry>[]
  data: AnalyticFleetCost[]
  period: DateRange
}

const AnalyticFleetReportDocument: React.FC<AnalyticFleetReportDocumentProps> = (
  { columns, data, period }
) => (
  <Document>
    <Page size="A4" style={styles.page} orientation={"landscape"} wrap>
      {/*<Image style={styles.image} src={Logo} fixed />*/}
      {/*<Image style={styles.image} src='/logo-ve-small.jpg' fixed />*/}
      <ReportHeader period={period} />
      {util.isDefined(data) && data.map((entry, i) =>
        <TableSection key={entry.analyticCode} index={i} columns={columns} fleetCost={entry} />
      )}
      <Text style={styles.date} fixed>{ moment().format('YYYY-MM-DD HH:mm') }</Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

const ReportHeader: React.FC<{ period: DateRange }> = ({ period }) =>
  <View style={styles.header} fixed>
    <Text style={styles.title}>Fleet Kost per groep</Text>
    <Text style={styles.subtitle}>{ toMediumFormat(period) }</Text>
  </View>

type AnalyticFleetCostProps = {
  columns: ColumnDefinition<FleetCostEntry>[]
  index: number
  fleetCost: AnalyticFleetCost
}

const TableSection: React.FC<AnalyticFleetCostProps> = (
  {
    index,
    columns,
    fleetCost: { entries, analyticCode, totalKmCost, totalFuelCost, totalPurchaseCost, totalPeriodCost },
  }
) =>
  // <View style={styles.section} break={index !== 0}>
  <View style={styles.section} break={index !== 0}>
    <Text style={styles.tabletitle} fixed>{analyticCode}</Text>
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} style={styles.columnHeader} />
      <TableRows columns={columns} data={entries} style={styles.tableRow} />
      <TableFooter columns={columns}
                   analyticCode={analyticCode}
                   totalKmCost={util.formatCurrency(totalKmCost)}
                   totalFuelCost={util.formatCurrency(totalFuelCost)}
                   totalPurchaseCost={util.formatCurrency(totalPurchaseCost)}
                   totalPeriodCost={util.formatCurrency(totalPeriodCost)}
      />
    </View>
  </View>

type AnalyticFleetCostFooterProps = {
  "columns": ColumnDefinition<FleetCostEntry>[]
  "analyticCode": string
  "totalFuelCost": string,
  "totalPeriodCost": string,
  "totalPurchaseCost": string,
  "totalKmCost": string,
}

const TableFooter: React.FC<AnalyticFleetCostFooterProps> = (
  { columns, analyticCode, totalKmCost, totalFuelCost, totalPurchaseCost, totalPeriodCost}
) => {
  const colStyle = (i: number): Style[] => {
    const colStyleAtIndex = columns[columns.length - i].styles;
    return (colStyleAtIndex ? [colStyleAtIndex] : []);
  };
  return (
    <View style={styles.tableFooter}>
      <Text style={ [{ width: '72%', textAlign: 'left', fontSize: 10, alignSelf: 'center'}, styles.columnFooter] }>Totaal voor { analyticCode }</Text>
      <Text style={ colStyle(4).concat([styles.columnFooter]) }>{ totalKmCost }</Text>
      <Text style={ colStyle(3).concat([styles.columnFooter]) }>{ totalFuelCost }</Text>
      <Text style={ colStyle(2).concat([styles.columnFooter]) }>{ totalPurchaseCost }</Text>
      <Text style={ colStyle(1).concat([styles.columnFooter, { borderRightWidth: 0 }]) }>{ totalPeriodCost }</Text>
    </View>
  );
}

export default AnalyticFleetReportDocument;
