import {none, Option, some} from "fp-ts/Option";

interface ServiceInit {
  status: 'init';
}
interface ServiceLoading {
  status: 'loading';
}
interface ServiceLoaded<T> {
  status: 'loaded';
  payload: T;
}
interface ServiceError {
  status: 'error';
  error: Error;
}
export type Service<T> =
  | ServiceInit
  | ServiceLoading
  | ServiceLoaded<T>
  | ServiceError;

export const mapService: <A, B>(m: (a: A) => B) => (s: Service<A>) => Service<B> =
  (mapper) => (service) => {
    switch (service.status) {
      case 'loaded':
        return { status: 'loaded', payload: mapper(service.payload) };
      default:
        return service;
  }};

export const foreach: <A>(m: (a: A) => void) => (s: Service<A>) => void =
  (fn) => (service) => {
    switch (service.status) {
      case 'loaded':
        fn(service.payload)
        return;
      default:
        return;
  }};

export const getOrElse: <A>(_: A) => (s: Service<A>) => A =
  (onNotLoaded) => (service) => {
    switch (service.status) {
      case 'loaded':
        return service.payload;
      default:
        return onNotLoaded;
  }};

export const toOption: <A>(s: Service<A>) => Option<A> = (service) => {
  switch (service.status) {
    case 'loaded':
      return some(service.payload);
    default:
      return none;
}};

export const toError: <A>(s: Service<A>) => Option<Error> = (service) => {
  switch (service.status) {
    case 'error':
      return some(service.error);
    default:
      return none;
}};

export const isLoading: <A>(s: Service<A>) => boolean = (service) =>
  service.status === 'loading';

