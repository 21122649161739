import React from "react";
import {
  Button,
  Modal,
  Form, Row, Col,
} from "react-bootstrap";
import {Formik, Form as FormikForm, useFormikContext} from 'formik';
import * as Yup from "yup";
import FormSubmitButton from "../components/form/FormSubmitButton";
import util from "../../utils/util";
import FormikDateField from "../components/FormikDateField";
import {FormikHelpers} from "formik/dist/types";
import {GasType, TankFill} from "../../../libs/api/time/api-model";
import {Moment} from "moment";
import moment from "moment/moment";

const labelColWidth = 4;
const valueColWidth = 8;

export interface TankFillForm {
  id?: string
  fillDate: Moment
  quantity: string
  rate: string
  gasType: GasType
  estimate?: string
}

export const toForm: (tankFill: TankFill) => TankFillForm = (t: TankFill) => ({
  id: t.id.toString(),
  fillDate: moment(t.fillDate),
  quantity: t.quantity.toString(),
  rate: t.rate?.toString(),
  gasType: t.gasType,
  estimate: t.estimate?.toString(),
});

export const toTankFill: (form: TankFillForm) => TankFill = (t: TankFillForm) => ({
  id: t.id ? parseInt(t.id) : 0,
  fillDate: t.fillDate.format("yyyy-MM-DDTHH:mm:ss.SSS"),
  quantity: parseFloat(t.quantity),
  rate: parseFloat(t.rate),
  gasType: t.gasType,
  estimate: t.estimate ? parseFloat(t.estimate) : undefined,
});

const nummer = () => Yup.number().typeError('geef een geldig cijfer').required();

const TankFillFormSchema = Yup.object().shape({
  fillDate: Yup.date().required(),
  quantity: nummer(),
  estimate: nummer(),
  rate: nummer(),
  gasType: Yup.object().required(),
});

const FormField = ({label, name, required, ...props}) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormikContext();
  return (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor={`input${name}`}>
        {required ? <strong>{label} *</strong> : label }
      </Form.Label>
      <Col sm={valueColWidth}>
        <Form.Control
          {...props}
          name={name}
          id={`input${name}`}
          value={values[name]}
          // onChange={handleChange}
          onChange={(e) => {
            const parsed = e.currentTarget.value.replaceAll(',', '.');
            setFieldValue(name, parsed, true);
          }}
          onBlur={handleBlur}
          isInvalid={!!touched[name] && !!errors[name]}
        />
        <Form.Control.Feedback type="invalid">{ util.byString(errors, name) }</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
};

type TankFillControlProps = {
  title: string
  isOpen: boolean
  onHide?: () => void
  onSave: (values: TankFillForm, formikHelpers: FormikHelpers<TankFillForm>) => void | Promise<any>
  initialFormValues: TankFillForm
}

const TankFillControl: React.FC<TankFillControlProps> = (
  { title, isOpen, onHide, onSave, initialFormValues }
) => (
  <Modal
    scrollable={ true }
    size="sm"
    show={ isOpen }
    onHide={ onHide }>
    <Formik
      initialValues={ initialFormValues }
      onSubmit={ onSave }
      validationSchema={ TankFillFormSchema }
    >
      {({
          errors,
      }) => (
        <FormikForm>
          <Modal.Header closeButton>{title}</Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={labelColWidth} className="text-sm-right" htmlFor="fillDate">
                <strong>Datum *</strong>
              </Form.Label>
              <Col sm={valueColWidth}>
                <FormikDateField name='fillDate' />
                <Form.Control.Feedback type="invalid">{ util.byString(errors, 'fillDate') }</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <FormField label="Hoeveelheid" required name="quantity" />
            <FormField label="Tank niveau" required name="estimate" />
            <FormField label="Prijs" required name="rate" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant={"secondary"} onClick={onHide}>Annuleren</Button>
            {' '}
            <FormSubmitButton />
          </Modal.Footer>
        </FormikForm>
        )}
    </Formik>
  </Modal>
);

export default TankFillControl;
