import React, {ReactElement} from "react";

import {Spinner} from "react-bootstrap";
import {Frown} from "react-feather";
import {Service} from "../../services/Service";

export interface ErrorHandlingProps<T> {
  service: Service<T>
  onLoaded: (t: T) => ReactElement<any, any> | null
}

function ErrorHandlingTS<T>(props: ErrorHandlingProps<T>) {
  switch (props.service.status) {
    case 'init':
      return <></>;
    case 'loading':
      return <div><Spinner animation={"grow"} color="dark"/></div>;
    case "error":
      const error = props.service.error;
      const feedback = typeof error.message !== 'undefined' && error.message !== '' ? ': ' + error.message : '';
      return (
        <div className="text-danger">
          <Frown/> Fout bij het ophalen van de data{feedback}
        </div>
      );
    //case "loaded": return <>{props.children}</>;
    case "loaded":
      return props.onLoaded(props.service.payload);
  }
}

export const MapServiceElement: <T>(_: ErrorHandlingProps<T>) => JSX.Element | null = props => {
  switch (props.service.status) {
    case "loaded":
      return props.onLoaded(props.service.payload);
    default:
      return <></>;
  }
}

export default ErrorHandlingTS;
