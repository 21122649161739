import React from "react";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Edit as EditIcon, Trash} from "react-feather";
import {AbsenceType} from "../../../libs/api/time/api-model";
import AbsenceBadge from "./AbsenceBadge";

type EntityActionHandler<T> = (entity: T) => void;

type ActionButtonProps<T> = {
  value: T
  actionHandler: EntityActionHandler<T>
}

type ActionProps<T> = {
  value: T
  handleEdit: EntityActionHandler<T>
  handleDelete: EntityActionHandler<T>
}

function isAbsenceType(at: any): at is AbsenceType {
  return (at as AbsenceType).code !== undefined;
}

const AbsenceCode = ({value}) =>
    value !== undefined && isAbsenceType(value) ? <AbsenceBadge absenceType={value} /> : <pre>{value}</pre>;

const Edit: React.FC<ActionButtonProps<AbsenceType>> = ({value, actionHandler}) =>
  <Button variant="link" size={"sm"} className="p-0" onClick={_ => actionHandler(value)}>
    <EditIcon className="me-1" size={18} />
  </Button>

const Delete: React.FC<ActionButtonProps<AbsenceType>> = ({value, actionHandler}) =>
  <Button variant="link" size="sm" className="text-danger p-0" onClick={_ => actionHandler(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const Actions: React.FC<ActionProps<AbsenceType>> = ({value, handleEdit, handleDelete}) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <Edit value={value} actionHandler={handleEdit} />
      <Delete value={value} actionHandler={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const columns = (handleEdit: EntityActionHandler<AbsenceType>, handleDelete: EntityActionHandler<AbsenceType>) => [
  {
    Header: "Code",
    accessor: "code",
    className: "text-center",
    Cell: (data: any) => <AbsenceCode value={data.row.original} />
  },
  {
    Header: "Naam",
    id: "name",
    accessor: "name",
  },
  {
    Header: "Betaald",
    id: "paid",
    accessor: "paid",
    Cell: ({value}) => (value !== undefined && value ? 'ja' : 'nee')
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    accessor: 'id',
    width: "10%",
    Cell: (data: any) => <Actions value={data.row.original} handleEdit={handleEdit} handleDelete={handleDelete} />
  }
];

export { columns };
