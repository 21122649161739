import * as React from "react";
import {Badge, Card} from "react-bootstrap";
import {TankFillStats} from "../../../libs/api/time/api-model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign} from "@fortawesome/free-solid-svg-icons";

export interface PriceStatsProps {
  stats: TankFillStats
}

const PriceStats: React.FC<PriceStatsProps> = ({ stats }) => {
  return (
    <Card className="flex-fill">
      <Card.Body className=" py-4">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            <h3 className="mb-2">€ {stats.dayRate}</h3>
            <p className="mb-2">Huidige Dagprijs</p>
            <div className="mb-0">
              {stats.increaseRate < 0 &&
                <Badge bg="" className="badge-soft-success me-2">
                  {stats.increaseRate}%
                </Badge>
              }
              {stats.increaseRate >= 0 &&
                <Badge bg="" className="badge-soft-danger me-2">
                  +{stats.increaseRate}%
                </Badge>
              }
              <span className="text-muted">Sinds vorige prijs</span>
            </div>
          </div>
          <div className="d-inline-block ms-3">
            <div className="stat">
              <FontAwesomeIcon fixedWidth icon={faEuroSign} className="align-middle text-success" />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default PriceStats;
