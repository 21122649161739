import { Link } from "react-router-dom";
import { dalConfig } from "../../config";
import SelectColumnFilter from "../components/tables/filters/SelectColumnFilter";
import SelectMultiColumnFilter from "../components/tables/filters/SelectMultiColumnFilter";
import BooleanColumnFilter from "../components/tables/filters/BooleanColumnFilter";
import {isInService} from "../../model/api-model-operators";
import {CheckCircle, XCircle} from "react-feather";
import * as React from "react";

const EmployeeImage = ({value}) =>
  <img
    src={`${dalConfig.baseUrl}/cdn/images/employees/${value}/image`}
    width="32"
    height="32"
    className="rounded-circle my-n1"
    alt={'Werknemer ' + value}
  />

const EmployeeLink = ({row}) =>
  <Link to={`/pages/time/employee/${row.original.id}`}>
    {row.values.name}
  </Link>

const EmployeeServiceState = ({value}) =>
  value ?
    <CheckCircle size={18} className="feather align-middle text-success" /> :
    <XCircle size={18} className="feather align-middle text-danger" />;

function multiSelectFilter(rows, columnIds, filterValue) {
  const selected = filterValue.map((option) => option.value);
  return selected.length === 0
    ? rows
    : rows.filter((row) => selected.includes(String(row.values[columnIds])));
}

const employeeColumns = [
  {
    Header: "",
    id: 'image',
    accessor: "employeeNr",
    Cell: EmployeeImage,
    width: "8%",
    Filter: false,
    disableSortBy: true,
    className: 'align-top text-center',
    style: { paddingLeft: '0.75rem' }
  },
  {
    Header: "Nummer",
    accessor: "employeeNr",
    width: "10%",
    disableSortBy: true,
    className: 'align-top'
  },
  {
    Header: "Naam",
    id: "name",
    filter: "personalia.firstname",
    //accessor: "personalia",
    accessor: (row) => `${row.personalia.firstname} ${row.personalia.lastname}`,
    Cell: EmployeeLink,
    disableSortBy: true,
    className: 'align-top'
  },
  {
    Header: "Site",
    id: 'company',
    accessor: (row) => row.company.name,
    width: "15%",
    Filter: SelectMultiColumnFilter,
    filter: multiSelectFilter,
    disableSortBy: true,
    className: 'align-top'
  },
  {
    Header: "Activiteit",
    id: 'activity',
    accessor: (row) => row.activity?.name,
    width: "15%",
    Filter: SelectColumnFilter,
    disableSortBy: true,
    className: 'align-top'
  },
  {
    Header: "In dienst",
    id: 'indienst',
    accessor: (row) => isInService(row),
    width: "5%",
    Cell: EmployeeServiceState,
    Filter: BooleanColumnFilter,
    disableSortBy: true,
    className: 'align-top text-center'
  },
];

export { employeeColumns };
