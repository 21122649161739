import React, {useEffect, useState} from "react";
import {pipe} from "fp-ts/function";
import {array, option} from "fp-ts";
import {useFormikContext} from "formik";
import {FormikValues} from "formik/dist/types";
import util from "../../../utils/util";
import Select, {SingleValue} from "react-select";
import classNames from "classnames";
import {Form} from "react-bootstrap";
import {Service, toOption} from "../../../services/Service";
import {Projects} from "../../../services/TimeApi";
import {ProjectGroupRef} from "../../../../libs/api/time/api-model";
import {contramap, Eq} from "fp-ts/Eq";
import * as S from "fp-ts/string";
import {InputActionMeta} from "react-select/src/types";
import {Refinement} from "fp-ts/Refinement";

export interface SelectProjectFormikProps {
  name: string
  required?: boolean
  useOptionValue?: boolean // geef de value van de option door bij selectie, of de hele option
  initialValue?: ProjectGroupRef | string
  overrideSetter?: (field: string, value: any, shouldValidate?: boolean) => void
}

type ProjectOption = {
  value: string,
  label: React.ReactElement
}

const isString: Refinement<unknown, string> = (x): x is string => typeof x === 'string';

const eqProject: Eq<ProjectGroupRef> = pipe(S.Eq, contramap((o) => o.groupId));

const getId = (project: ProjectGroupRef) => project.groupId;

const getLabel = ({groupId, location, description, company}: ProjectGroupRef) =>
  <div>
    <span className="fw-bolder me-1 mb-1">{groupId}</span>
    <span className="fw-lighter text-muted">{company !== undefined ? company.split(' ')[0] : ''} | {location}</span>
    <br/>
    <small>{description}</small>
  </div>

const toProjectOption: (_: ProjectGroupRef | string) => ProjectOption = (project: ProjectGroupRef | string) =>
  isString(project) ?
    ({ value: project, label: <div>{ project }</div> } as ProjectOption) :
    ({
      value: getId(project),
      label: getLabel(project)
    });

const emptyOption: ProjectOption =
  ({ value: "", label: <div>Maak een keuze</div> } as ProjectOption);


export const SelectProjectFormik: React.FC<SelectProjectFormikProps> = (
  {
    name,
    required,
    useOptionValue = false,
    initialValue,
    overrideSetter,
    ...props
  }
) => {

  const [projects, setProjects] = useState<Service<ProjectGroupRef[]>>({ status: 'init' });
  const [searchPrefix, setSearchPrefix] = useState<string>();
  const loadEntries = () =>
    searchPrefix !== undefined && searchPrefix.trim() !== '' && searchPrefix.length > 3 ?
      Projects.search(searchPrefix)(setProjects) :
      setProjects({ status: 'init' });

  useEffect(loadEntries, [ searchPrefix ]);

  const projectOptions: ProjectOption[] =
    pipe(projects,
      s => toOption(s),
      option.map(us => pipe(us, array.map(toProjectOption))),
      option.getOrElse(() => [] as ProjectOption[]),
    );

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
  } = useFormikContext<FormikValues>();

  const fieldSetter = overrideSetter !== undefined ? overrideSetter : setFieldValue;

  const formikValue: option.Option<ProjectGroupRef | string> = pipe(
    values[name],
    option.fromNullable,
    option.map(t => typeof t === 'string' ? t : t as ProjectGroupRef)
  );

  const findProject = (search: ProjectGroupRef | string): option.Option<ProjectGroupRef> =>
    isString(search) ?
      pipe(projects,
        s => toOption(s),
        option.chain(
          pipe(array.findFirst(p =>
            isString(search) ? S.Eq.equals(p.groupId, search) : eqProject.equals(search, p))
          )
        )
      ) : option.some(search);

  // bij editeren worden de project options niet opgehaald...
  const activeOption: ProjectOption = pipe(
    formikValue,
    option.map(projectOrCode =>
      isString(projectOrCode) ?
        pipe(findProject(projectOrCode), option.getOrElse<string | ProjectGroupRef>(() => projectOrCode)) :
        projectOrCode
    ),
    option.map(toProjectOption),
    option.getOrElse(() => emptyOption),
  );

  const findGroupId = (selected: SingleValue<ProjectOption>): option.Option<string> =>
    pipe(selected,
      option.fromNullable,
      option.map(o => o.value)
    );

  const findProjectGroupRef = (selected: SingleValue<ProjectOption>) =>
    pipe(
      findGroupId(selected),
      option.chain(findProject),
      option.toUndefined
    );

  const inputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (util.isDefined(newValue) && actionMeta.action === "input-change") {
      setSearchPrefix(newValue);
    }
  };

  // console.log(`active option`, activeOption);
  // console.log(`projects state`, projects.status);

  // bij het editeren willen we voor een string value de eerste keer het bijhorende project ophalen
  /*
  pipe(
    formikValue,
    option.filter(isString),
    option.filter(groupId => searchPrefix !== groupId),
    option.map(setSearchPrefix)
  );
   */

  return (
    <>
      <Select
        className={classNames("react-select-container", !!errors[name] ? 'is-invalid' : '')}
        classNamePrefix="react-select"
        options={projectOptions}
        isLoading={projects.status === "loading"}
        id={`input_${name}`}
        onInputChange={ inputChange }
        onChange={option => fieldSetter(name, useOptionValue ? option?.value : findProjectGroupRef(option))}
        onBlur={handleBlur}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={activeOption}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...props}
      />
      {
        projects.status === "error" && <Form.Control.Feedback type="invalid">{`Fout bij het ophalen van project data: ${projects.error ? projects.error.message : '?'}`}</Form.Control.Feedback>
      }
      {!!errors[name] && <Form.Control.Feedback type="invalid">{ util.byString(errors, name) }</Form.Control.Feedback> }
    </>
  );
};

export default SelectProjectFormik;
