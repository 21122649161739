import React, {useEffect, useState} from "react";
import {Card, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import util from "../../../../utils/util";
import DurationCell from "../../../components/tables/DurationCell";
import {Service} from "../../../../services/Service";
import {PrestatieStatsMonthly} from "../../../../../libs/api/time/api-model";
import {Prestaties} from "../../../../services/TimeApi";
import ErrorHandlingTableRow from "../../../components/ErrorHandlingTableRow";

type MonthlyStatsProps = {
  year: number,
  employeeNr: number
  activeMonth: number
  onSelectMonth: (year: number, month: number) => void
}

const MonthlyStats: React.FC<MonthlyStatsProps> = ({
  year, employeeNr, activeMonth, onSelectMonth
}) => {

  const [stats, setStats] = useState<Service<PrestatieStatsMonthly[]>>({ status: 'init' });
  const loadEntries = () => Prestaties.monthlyStats(employeeNr, year)(setStats);
  useEffect(loadEntries, [ employeeNr, year ]);

  return (
    <Card className="flex-fill">
      <Card.Header className="pb-0">
        <Card.Title className="mb-0">
          <h4>{ year }</h4>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table hover size="sm" className="my-0 table-pointer">
          <thead>
          <tr>
            <th>Maand</th>
            <th className="text-end">
              <OverlayTrigger overlay={<Tooltip>Te Presteren</Tooltip>}>
                <span>TP</span>
              </OverlayTrigger>
            </th>
            <th className="text-end">
              <OverlayTrigger overlay={<Tooltip>Gepresteerd</Tooltip>}>
                <span>GP</span>
              </OverlayTrigger>
            </th>
            <th className="d-none d-xl-table-cell text-end">
              <OverlayTrigger overlay={<Tooltip>Verschil</Tooltip>}>
                <span>Diff</span>
              </OverlayTrigger>
            </th>
            <th className="d-none d-xl-table-cell text-end">OVU</th>
            <th className="d-none d-xl-table-cell text-end">Premie</th>
          </tr>
          </thead>
          <tbody>
          <ErrorHandlingTableRow colspan={7} service={stats} onLoaded={(data: PrestatieStatsMonthly[]) => (
            <>
            { data.map((item, key) => (
                <tr key={`${item.year}-${item.month}`} onClick={() => onSelectMonth(item.year, item.month - 1)} // moment months are zero-based
                    className={item.month === activeMonth + 1 ? 'table-primary' : ''}>
                  <td>{item.year} - {item.month}</td>
                  <td className="text-end">{util.duration(item.tp)}</td>
                  <td className="text-end">{util.duration(item.gp)}</td>
                  <DurationCell value={item.diff} className='d-none d-xl-table-cell text-end' />
                  <td className="d-none d-xl-table-cell text-end">{item.ovu}</td>
                  <td className="d-none d-xl-table-cell text-end">{item.premie}</td>
                </tr>
            )) }
            </>
          )} />
          </tbody>
        </Table>
      </Card.Body>
    </Card>

  );
}

export default MonthlyStats;
